import { Component } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import { ProductUX, ProductContractItemUX, ProductItem, GPLContext, isProductItem } from '../../../../shared/types';

import ProductItemCard from './ProductItemCard';
import { AllowanceSummary } from './AllowancePopper';

import GridReactVirtualizedList from '../../../common/reactVirtualized/GridReactVirtualizedList';
import HorizontalReactVirtualizedList from '../../../common/reactVirtualized/HorizontalReactVirtualizedList';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, withStyles, WithStyles } from '@mui/styles';
import { debounce } from 'lodash';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';
import { GStyles } from '../../../../styles/GStyles';

export enum ReactVirtualizedLayout {
    horizontal = 'horizontal',
    vertical = 'vertical',
    grid = 'grid'
}

interface Props {
    layout: ReactVirtualizedLayout;
    canUserEditContract: boolean;
    productItems: ProductItem[];
    caseFirstName: string;
    isShowPrices: boolean;
    allowances: AllowanceSummary[];
    containerClass?: string;
    context: GPLContext;
    onProductItemClick: (productItem: ProductItem) => void;
    onAddContractItem?: (product: ProductUX, quantity: number, allowanceItemId: string | undefined) => void;
    onUpdateContractItemQuantity?: (contractItem: ProductContractItemUX, quantity: number) => void;
    onRemoveContractItem?: (contractItem: ProductContractItemUX) => void;
    onAddAdditionalItemClick?: () => void;
    onEditClick?: (productId: number) => void;
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    productCont: {
        width: '100%',
        '@media (min-width: 1356px)': {
            width: 'calc(100% - 336px)'
        }
    },
    productListContainer: {
        width: 'auto',
        display: 'block !important',
        textAlign: 'center',
        '@media (min-width: 691px)': {
            textAlign: 'left',
        },
        '& li': {
            display: 'inline-block !important',
            margin: '0px 0px 20px !important',
            textAlign: 'left',
            verticalAlign: 'top'
        }
    },
    dashedButton: {
        border: 'dashed',
        borderWidth: 'thin',
        margin: 18,
        height: 214,
        width: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
    },
    dashedButtonText: {
        fontSize: 20,
        textTransform: 'none',
        fontWeight: 400,
        display: 'block',
    },
    height252: {
        height: 252,
    },
    backOfficeProductCont: {
        '@media (min-width: 1670px)': {
            width: 'calc(100% - 336px)'
        }
    },
    fixedList: {
        margin: '0 auto !important',
        overflowY: 'unset !important' as 'unset',
    },
});

type StyledProps = Props & WithStyles<'root' | 'productCont' | 'productListContainer' | 'dashedButton'
    | 'dashedButtonText' | 'height252' | 'backOfficeProductCont' | 'fixedList'>;

type State = {
    windowWidth: number;
};

class ProductItemList extends Component<StyledProps, State> {
    state: State = {
        windowWidth: window.innerWidth > 1340 && 1022
            || window.innerWidth > 1000 && window.innerWidth < 1030 && 1000
            || window.innerWidth
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize, { passive: true });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    protected handleResize = debounce(
        () => {
            this.setState({
                windowWidth: window.innerWidth > 1340 && 1022
                    || window.innerWidth > 1000 && window.innerWidth < 1030 && 1000
                    || window.innerWidth
            });
        },
        100
    );

    render() {
        const {
            classes,
            productItems,
            caseFirstName,
            onProductItemClick,
            onAddContractItem,
            onUpdateContractItemQuantity,
            onRemoveContractItem,
            onAddAdditionalItemClick,
            isShowPrices,
            canUserEditContract,
            containerClass,
            context,
            onEditClick,
            allowances,
            layout,
        } = this.props;
        const { windowWidth } = this.state;

        const reactVirtualizedProps = {
            itemWidth: (windowWidth < 360 ? 320 : (300 + (18 * 2))),  // width of 1 item // 300(width) + 18px margin
            data: productItems,
            defaultCellHeight: 254,
            render: (productItem: unknown, measure?: () => void) => {
                if (isProductItem(productItem)) {
                    return (
                        <ProductItemCard
                            canUserEditContract={canUserEditContract}
                            key={productItem.key}
                            productItem={productItem}
                            caseFirstName={caseFirstName}
                            onClick={() => onProductItemClick(productItem)}
                            onAddContractItem={onAddContractItem}
                            onUpdateQuantity={onUpdateContractItemQuantity}
                            onRemoveContractItem={onRemoveContractItem}
                            isShowPrices={isShowPrices}
                            context={context}
                            onEditClick={onEditClick}
                            allowances={allowances}
                            measure={measure}
                        />
                    );
                }
                return <></>;
            },
            firstChild: canUserEditContract && context !== GPLContext.BackOffice &&
                <ul className={classNames(GStyles.list, classes.productListContainer)}>
                    <ImageListItem>
                        <DisabledPermissionTooltip permission={Permission.ADD_ITEMS}>
                            {disabled =>
                                <Button
                                    disabled={disabled}
                                    variant="outlined"
                                    color="primary"
                                    className={classNames(
                                        classes.dashedButton,
                                        isShowPrices && classes.height252,
                                    )}
                                    onClick={() => onAddAdditionalItemClick && onAddAdditionalItemClick()}
                                    size="large"
                                >
                                    <div>
                                        <PlaylistAddIcon className={GStyles.fontSize36} />
                                        <span className={classes.dashedButtonText}>Add a New Product</span>
                                    </div>
                                </Button>
                            }
                        </DisabledPermissionTooltip>
                    </ImageListItem>
                </ul> || undefined
        };

        return (
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classNames(
                    classes.productCont,
                    context === GPLContext.BackOffice && classes.backOfficeProductCont,
                    containerClass
                )}
            >
                {layout === ReactVirtualizedLayout.horizontal &&
                    <HorizontalReactVirtualizedList
                        {...reactVirtualizedProps}
                    />
                }
                {layout === ReactVirtualizedLayout.grid &&
                    <GridReactVirtualizedList
                        {...reactVirtualizedProps}
                        windowWidth={windowWidth}
                    />
                }
            </Grid>
        );
    }
}

export default withStyles(styles)(ProductItemList);
