import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import {
    ProductUX,
    ProductContractItemUX,
    ProductItem,
    GPLContext,
    ProductCategoryEnum,
    ProductView
} from '../../../../shared/types';
import VerticalReactVirtualizedList from '../../../common/reactVirtualized/VerticalReactVirtualizedList';
import ProductItemCardBlock from '../widgets/ProductItemCardBlock';
import ScrollToTop from '../products/ScrollToTop';
import DisabledPermissionTooltip from '../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../shared/types/permissions';
import { memo } from 'react';
import { isEqual } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { GStyles } from '../../../../styles/GStyles';
import ProductItemsDataGrid from '../widgets/ProductItemsDataGrid';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: '16px !important',
        flexDirection: 'column',
        width: '90%',
        maxWidth: 960,
        '@media (min-width: 768px)': {
            width: '80%',
        }
    },
    li: {
        width: '100%',
        display: 'block !important'
    },
    dashedButton: {
        textAlign: 'center',
        border: 'dashed',
        borderWidth: 'thin',
        width: '100%',
        display: 'block',
        maxWidth: 460,
        margin: 'auto auto 24px',
        borderRadius: 8,
    },
    hidden: {
        opacity: 0.5,
    },
}), { name: 'CareOfLovedOne' });

interface Props {
    canUserEditContract: boolean;
    caseFirstName: string;
    productItems: ProductItem[];
    isShowPrices: boolean;
    context: GPLContext;
    onProductItemClick: (productItem: ProductItem) => void;
    onEditClick?: (productId: number) => void;
    onAddContractItem?: (product: ProductUX, quantity: number) => void;
    onRemoveContractItem?: (contractItem: ProductContractItemUX) => void;
    onAddAdditionalItemClick?: (productCategory: ProductCategoryEnum) => void;
    productView?: ProductView;
}

const CareOfLovedOne = (props: Props) => {
    const {
        productItems,
        caseFirstName,
        onAddAdditionalItemClick,
        onProductItemClick,
        onAddContractItem,
        onRemoveContractItem,
        isShowPrices,
        canUserEditContract,
        context,
        onEditClick,
        productView
    } = props;

    const classes = useStyles();

    const isBackOffice = context === GPLContext.BackOffice;

    return (
        <>
            {productView === ProductView.grid_view ?
                <Grid container justifyContent="center" alignItems="center">
                    <ul className={classNames(GStyles.list, classes.root)}>
                        <VerticalReactVirtualizedList
                            data={productItems}
                            defaultCellHeight={58}
                            render={(productItem) => (
                                <ImageListItem
                                    className={classNames(
                                        classes.li,
                                        isBackOffice
                                        && productItem.product?.is_hidden
                                        && classes.hidden
                                    )}
                                    onClick={() => onProductItemClick(productItem)}
                                    key={productItem.key}
                                >
                                    <ProductItemCardBlock
                                        canUserEditContract={canUserEditContract}
                                        productItem={productItem}
                                        caseFirstName={caseFirstName}
                                        onAddContractItem={onAddContractItem
                                            ? (product) => onAddContractItem(product, 1)
                                            : undefined}
                                        onRemoveContractItem={onRemoveContractItem}
                                        isShowPrices={isShowPrices}
                                        context={context}
                                        onEditClick={onEditClick}
                                        width={'xs'}
                                    />
                                </ImageListItem>
                            )}
                            firstChild={canUserEditContract && !isBackOffice
                                ? <Grid item xs={12}>
                                    <DisabledPermissionTooltip permission={Permission.ADD_ITEMS}>
                                        {disabled =>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                className={classes.dashedButton}
                                                onClick={() =>
                                                    onAddAdditionalItemClick?.(ProductCategoryEnum.care_of_loved_one)
                                                }
                                                size="large"
                                                disabled={disabled}
                                            >
                                                + Add new item
                                            </Button>
                                        }
                                    </DisabledPermissionTooltip>
                                </Grid>
                                : undefined}
                        />
                    </ul>
                    <ScrollToTop />
                </Grid> :
                <ProductItemsDataGrid
                    productItems={productItems.map(p => p.product).filter((p): p is ProductUX => !!p)}
                    zIndex={1320}
                    onEditClick={onEditClick}
                    category={ProductCategoryEnum.care_of_loved_one}
                />
            }
        </>
    );
};

export default memo(CareOfLovedOne, isEqual);
