import { getFromAPI, patchAPI, postToAPI } from '.';
import { registerAppError } from './errors';
import {
    LoadFlowerSalesProductsResponse,
    FlowerSalesProductCategory,
    FlowerSalesOrderRequest,
    LoadFlowerSalesOrderPageResponse,
    FlowerSalesOrderDraftResponse,
    FlowerSalesOrderComplete,
    FlowerSalesUX, FlowerSalesOrderUpdateRequest, flowerSalesUXFromOrder,
} from '../shared/types';
import { AppDispatch } from '../store';
import { log } from '../logger';

export const FLOWER_SALES_PRODUCTS_LOADING = 'FLOWER_SALES_PRODUCTS_LOADING';

interface FlowerSalesProductsLoading {
    type: typeof FLOWER_SALES_PRODUCTS_LOADING;
    category: FlowerSalesProductCategory;
}

function flowerSalesProductsLoading(category: FlowerSalesProductCategory): FlowerSalesProductsLoading {
    return {
        type: FLOWER_SALES_PRODUCTS_LOADING,
        category,
    };
}

export const FLOWER_SALES_PRODUCTS_LOADED = 'FLOWER_SALES_PRODUCTS_LOADED';

interface FlowerSalesProductsLoaded {
    type: typeof FLOWER_SALES_PRODUCTS_LOADED;
    response: LoadFlowerSalesProductsResponse;
}

function flowerSalesProductsLoaded(response: LoadFlowerSalesProductsResponse): FlowerSalesProductsLoaded {
    return {
        type: FLOWER_SALES_PRODUCTS_LOADED,
        response,
    };
}

export const FLOWER_SALES_PRODUCTS_LOAD_FAILED = 'FLOWER_SALES_PRODUCTS_LOAD_FAILED';

interface FlowerSalesProductsLoadFailed {
    type: typeof FLOWER_SALES_PRODUCTS_LOAD_FAILED;
}

function flowerSalesProductsLoadFailed(): FlowerSalesProductsLoadFailed {
    return {
        type: FLOWER_SALES_PRODUCTS_LOAD_FAILED,
    };
}

export function loadFlowerSalesProducts(caseName: string, category: FlowerSalesProductCategory) {
    return async (dispatch: AppDispatch): Promise<LoadFlowerSalesProductsResponse | null> => {
        dispatch(flowerSalesProductsLoading(category));
        const route = `app/remember/${caseName}/flowersales/category/${category}`;
        const response = await getFromAPI<LoadFlowerSalesProductsResponse>(route, dispatch);
        if (!response) {
            dispatch(registerAppError('Unable to load flower products.'));
            dispatch(flowerSalesProductsLoadFailed());
            return null;
        } else {
            dispatch(flowerSalesProductsLoaded(response));
            return response;
        }
    };
}

export const FLOWER_SALES_DELIVERY_DATES_LOADING = 'FLOWER_SALES_DELIVERY_DATES_LOADING';

interface FlowerSalesDeliveryDatesLoading {
    type: typeof FLOWER_SALES_DELIVERY_DATES_LOADING;
}

function flowerSalesDeliveryDatesLoading(): FlowerSalesDeliveryDatesLoading {
    return {
        type: FLOWER_SALES_DELIVERY_DATES_LOADING,
    };
}

export const FLOWER_SALES_DELIVERY_DATES_LOADED = 'FLOWER_SALES_DELIVERY_DATES_LOADED';

interface FlowerSalesDeliveryDatesLoaded {
    type: typeof FLOWER_SALES_DELIVERY_DATES_LOADED;
    deliveryDates: string[];
}

function flowerSalesDeliveryDatesLoaded(deliveryDates: string[]): FlowerSalesDeliveryDatesLoaded {
    return {
        type: FLOWER_SALES_DELIVERY_DATES_LOADED,
        deliveryDates,
    };
}

export const FLOWER_SALES_DELIVERY_DATES_LOAD_FAILED = 'FLOWER_SALES_DELIVERY_DATES_LOAD_FAILED';

interface FlowerSalesDeliveryDatesLoadFailed {
    type: typeof FLOWER_SALES_DELIVERY_DATES_LOAD_FAILED;
}

function flowerSalesDeliveryDatesLoadFailed(): FlowerSalesDeliveryDatesLoadFailed {
    return {
        type: FLOWER_SALES_DELIVERY_DATES_LOAD_FAILED,
    };
}

export function loadFlowerSalesDeliveryDates(caseName: string, zipcode: string) {
    return async (dispatch: AppDispatch): Promise<string[] | null> => {
        dispatch(flowerSalesDeliveryDatesLoading());
        const route = `app/remember/${caseName}/flowersales/zipcode/${zipcode}/delivery/`;
        const response = await getFromAPI<string[]>(route, dispatch);
        if (!response) {
            dispatch(registerAppError('Unable to load available delivery dates.'));
            dispatch(flowerSalesDeliveryDatesLoadFailed());
            return null;
        } else {
            dispatch(flowerSalesDeliveryDatesLoaded(response));
            return response;
        }
    };
}

export const FLOWER_SALES_ORDER_PAGE_LOADING = 'FLOWER_SALES_ORDER_PAGE_LOADING';

interface FlowerSalesOrderPageLoading {
    type: typeof FLOWER_SALES_ORDER_PAGE_LOADING;
}

function flowerSalesOrderPageLoading(): FlowerSalesOrderPageLoading {
    return {
        type: FLOWER_SALES_ORDER_PAGE_LOADING,
    };
}

export const FLOWER_SALES_ORDER_PAGE_LOADED = 'FLOWER_SALES_ORDER_PAGE_LOADED';

interface FlowerSalesOrderPageLoaded {
    type: typeof FLOWER_SALES_ORDER_PAGE_LOADED;
    response: LoadFlowerSalesOrderPageResponse;
}

function flowerSalesOrderPageLoaded(response: LoadFlowerSalesOrderPageResponse): FlowerSalesOrderPageLoaded {
    return {
        type: FLOWER_SALES_ORDER_PAGE_LOADED,
        response,
    };
}

export const FLOWER_SALES_ORDER_PAGE_FAILED = 'FLOWER_SALES_ORDER_PAGE_FAILED';

interface FlowerSalesOrderPageFailed {
    type: typeof FLOWER_SALES_ORDER_PAGE_FAILED;
}

function flowerSalesOrderPageFailed(): FlowerSalesOrderPageFailed {
    return {
        type: FLOWER_SALES_ORDER_PAGE_FAILED,
    };
}

export function loadFlowerSalesOrderPage(caseName: string, productCode: string) {
    return async (dispatch: AppDispatch): Promise<LoadFlowerSalesOrderPageResponse | null> => {
        dispatch(flowerSalesOrderPageLoading());
        const route = `app/remember/${caseName}/flowersales/order/product/${productCode}`;
        const response = await getFromAPI<LoadFlowerSalesOrderPageResponse>(route, dispatch);
        if (!response) {
            dispatch(registerAppError('Unable to load order.'));
            dispatch(flowerSalesOrderPageFailed());
            return null;
        } else {
            dispatch(flowerSalesOrderPageLoaded(response));
            return response;
        }
    };
}

export const FLOWER_SALES_ORDER_DRAFT_LOADING = 'FLOWER_SALES_ORDER_DRAFT_LOADING';

interface FlowerSalesOrderDraftLoading {
    type: typeof FLOWER_SALES_ORDER_DRAFT_LOADING;
}

function flowerSalesOrderDraftLoading(): FlowerSalesOrderDraftLoading {
    return {
        type: FLOWER_SALES_ORDER_DRAFT_LOADING,
    };
}

export const FLOWER_SALES_ORDER_DRAFT_LOADED = 'FLOWER_SALES_ORDER_DRAFT_LOADED';

interface FlowerSalesOrderDraftLoaded {
    type: typeof FLOWER_SALES_ORDER_DRAFT_LOADED;
    response: FlowerSalesOrderDraftResponse;
}

function flowerSalesOrderDraftLoaded(response: FlowerSalesOrderDraftResponse): FlowerSalesOrderDraftLoaded {
    return {
        type: FLOWER_SALES_ORDER_DRAFT_LOADED,
        response,
    };
}

export const FLOWER_SALES_ORDER_DRAFT_FAILED = 'FLOWER_SALES_ORDER_DRAFT_FAILED';

interface FlowerSalesOrderDraftFailed {
    type: typeof FLOWER_SALES_ORDER_DRAFT_FAILED;
}

function flowerSalesOrderDraftFailed(): FlowerSalesOrderDraftFailed {
    return {
        type: FLOWER_SALES_ORDER_DRAFT_FAILED,
    };
}

export function loadFlowerSalesOrderDraft(
    caseName: string,
    productCode: string,
    productPrice: number,
    zipcode: string,
) {
    return async (dispatch: AppDispatch): Promise<FlowerSalesOrderDraftResponse | null> => {
        dispatch(flowerSalesOrderDraftLoading());
        const route = `app/remember/${caseName}/flowersales/order/product/`
            + `${productCode}/price/${productPrice}/zip/${zipcode}`;
        const response = await getFromAPI<FlowerSalesOrderDraftResponse>(route, dispatch);
        if (!response) {
            dispatch(registerAppError('Unable to load order draft.'));
            dispatch(flowerSalesOrderDraftFailed());
            return null;
        } else {
            dispatch(flowerSalesOrderDraftLoaded(response));
            return response;
        }
    };
}

export const FLOWER_SALES_PLACING_ORDER = 'FLOWER_SALES_PLACING_ORDER';

interface FlowerSalesPlacingOrder {
    type: typeof FLOWER_SALES_PLACING_ORDER;
}

function flowerSalesPlacingOrder(): FlowerSalesPlacingOrder {
    return {
        type: FLOWER_SALES_PLACING_ORDER,
    };
}

export const FLOWER_SALES_ORDER_PLACED = 'FLOWER_SALES_ORDER_PLACED';

interface FlowerSalesOrderPlaced {
    type: typeof FLOWER_SALES_ORDER_PLACED;
}

function flowerSalesOrderPlaced(): FlowerSalesOrderPlaced {
    return {
        type: FLOWER_SALES_ORDER_PLACED,
    };
}

export const FLOWER_SALES_PLACE_ORDER_FAILED = 'FLOWER_SALES_PLACE_ORDER_FAILED';

interface FlowerSalesPlaceOrderFailed {
    type: typeof FLOWER_SALES_PLACE_ORDER_FAILED;
}

function flowerSalesPlaceOrderFailed(): FlowerSalesPlaceOrderFailed {
    return {
        type: FLOWER_SALES_PLACE_ORDER_FAILED,
    };
}

export const FLOWER_SALES_LOADED = 'FLOWER_SALES_LOADED';

interface FlowerSalesLoaded {
    type: typeof FLOWER_SALES_LOADED;
    flowerSales: FlowerSalesUX[];
}

function flowerSalesLoaded(flowerSales: FlowerSalesUX[]): FlowerSalesLoaded {
    return {
        type: FLOWER_SALES_LOADED,
        flowerSales,
    };
}

export const FLOWER_SALES_UPDATED = 'FLOWER_SALES_UPDATED';

interface FlowerSalesUpdated {
    type: typeof FLOWER_SALES_UPDATED;
    flowerSale: FlowerSalesUX;
}

export function flowerSalesUpdated(flowerSale: FlowerSalesUX): FlowerSalesUpdated {
    return {
        type: FLOWER_SALES_UPDATED,
        flowerSale,
    };
}

export function placeFlowerSalesOrder(caseName: string, order: FlowerSalesOrderRequest) {
    return async (dispatch: AppDispatch): Promise<FlowerSalesOrderComplete | null> => {
        try {
            FlowerSalesOrderRequest.fromRequest(order);
        } catch (ex) {
            log.warn('Failed to validate FlowerSalesOrderRequest:', { order, ex });
            return null;
        }
        dispatch(flowerSalesPlacingOrder());
        const route = `app/remember/${caseName}/flowersales/order`;
        const response = await postToAPI<FlowerSalesOrderComplete>(route, { order }, dispatch);
        if (!response) {
            dispatch(registerAppError('Unable to place order.'));
            dispatch(flowerSalesPlaceOrderFailed());
            return null;
        } else {
            dispatch(flowerSalesOrderPlaced());
            dispatch(flowerSalesUpdated(flowerSalesUXFromOrder(response, order)));
            return response;
        }
    };
}

export function updateFlowerSalesOrder(updateOrder: FlowerSalesOrderUpdateRequest, orderId: number, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<void> => {
        try {
            FlowerSalesOrderUpdateRequest.fromRequest(updateOrder);
        } catch (ex) {
            log.warn('Failed to validate FlowerSalesOrderRequest:', { updateOrder, ex });
            return;
        }
        const route = `api/case/${caseUuid}/flowersales/sale/${orderId}`;
        const response = await patchAPI<FlowerSalesUX>(route, updateOrder, dispatch);
        if (!response) {
            return dispatch(registerAppError('Unable to update user for order.'));
        }
        // Need to reload the gift photos, currently the case gift
        // photos are not updated when the flower/tree messages are edited
        // we're going to address this bug in a separate ticket
        dispatch(flowerSalesUpdated(response));
    };
}

export function getFlowerSales(caseName: string) {
    return async (dispatch: AppDispatch): Promise<void> => {
        const route = `app/remember/${caseName}/flowersales/sale`;
        const response = await getFromAPI<FlowerSalesUX[]>(route, dispatch);
        if (!response) {
            return dispatch(registerAppError('Unable to fetch flower sales.'));
        }
        dispatch(flowerSalesLoaded(response));
    };
}

export type FlowerSalesAction =
    | FlowerSalesProductsLoading
    | FlowerSalesProductsLoaded
    | FlowerSalesProductsLoadFailed
    | FlowerSalesDeliveryDatesLoading
    | FlowerSalesDeliveryDatesLoaded
    | FlowerSalesDeliveryDatesLoadFailed
    | FlowerSalesPlacingOrder
    | FlowerSalesOrderPlaced
    | FlowerSalesPlaceOrderFailed
    | FlowerSalesOrderDraftLoading
    | FlowerSalesOrderDraftLoaded
    | FlowerSalesOrderDraftFailed
    | FlowerSalesOrderPageLoading
    | FlowerSalesOrderPageLoaded
    | FlowerSalesOrderPageFailed
    | FlowerSalesLoaded
    | FlowerSalesUpdated
    ;
