import * as cloudinary from 'cloudinary-core';
import { CloudinaryTransformationsType } from '../shared/types';
import { isIOSDevice } from '../services';
import { GatherPhoto, isGatherPhoto } from '../types';
import { RawImage } from '../components/profileImage/PhotoUploader';
const loadImage = require('blueimp-load-image');

export const cloudinaryCore = new cloudinary.Cloudinary({ cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME });

/**
 * Image Width and Height constants 
 */
export const DEFAULT_WIDTH: number = 160;
export const DEFAULT_HEIGHT: number = 160;
export const DEFAULT_INNER_ALBUM_MARGIN: number = 20;

export const SQUARE_ASPECT_RATIO: number = 1;
export const PORTRAIT_ASPECT_RATIO: number = 0.7727;
export const LANDSCAPE_ASPECT_RATIO: number = 1.618;
export const DESKTOP_COVER_ASPECT_RATIO: number = 2.2;

export type PhotoOrientationType = 'square' | 'landscape' | 'portrait' | 'desktop_cover';
export type PhotoSizeType = 'small' | 'large' | 'default';

export interface ImageSizeType {
    width: number;
    height: number;
}

interface CloudinaryCoordinatesType {
    imagePosition: CoordinatesType;
    imageSize: ImageSizeType;
    croppedSize: ImageSizeType;
    rotationAngle: number;
    exifRotationAngle?: number;
}

export interface CoordinatesType {
    x: number;
    y: number;
}

export const removeEXIFOrientation = async (file: File | Blob): Promise<File | Blob> => {

    const data = await loadImage(file, { meta: true, orientation: true, canvas: true });

    if (data && data.imageHead && data.exif) {

        const orientation = data.exif.get('Orientation');
        if (!orientation) {
            return file;
        }

        // clear out EXIF Orientation data
        loadImage.writeExifData(data.imageHead, data, 'Orientation', 0);
        const newBlob: Blob = await new Promise<Blob>((resolve) => {
            data.image.toBlob(
                async (blob: Blob) => {
                    loadImage.replaceHead(blob, data.imageHead, (updatedBlob: Blob) => {
                        return resolve(updatedBlob);
                    });
                },
                'image/jpeg',
            );
        });
        return newBlob;
    }
    return file;
};

export const getDataURIFromFile = async (file: File | Blob): Promise<string> => {

    const fileWithoutOrientation: File | Blob = await removeEXIFOrientation(file);
    return new Promise<string>((resolve) => {
        const reader = new FileReader();

        reader.addEventListener(
            'load',
            async function () {
                if (reader.result && !(reader.result instanceof ArrayBuffer)) {
                    resolve(reader.result);
                }
            },
            false
        );
        reader.readAsDataURL(fileWithoutOrientation);
    });
};

export const getDataUrifromUrl = (imageUrl: string): Promise<string> => {
    return new Promise((resolve) => {
        const request = new XMLHttpRequest();
        request.open('GET', imageUrl, true);
        request.responseType = 'blob';
        request.onload = async () => {
            const fileWithoutOrientation: File | Blob = await removeEXIFOrientation(request.response);
            const dataUriWithoutOrientation = await getDataURIFromFile(fileWithoutOrientation);
            resolve(dataUriWithoutOrientation);
        };
        request.send();
    });
};

const DESKTOP_MAC_USER_AGENT_REGEX = /Macintosh;.[\wA-Za-z]+.Mac OS X/;
const MOBILE_IPHONE_USER_AGENT_REGEX = /iPhone;.[\wA-Za-z]+.iPhone OS/;

export const isMacOrIOSDevice = () => {
    return DESKTOP_MAC_USER_AGENT_REGEX.test(navigator.userAgent) ||
        MOBILE_IPHONE_USER_AGENT_REGEX.test(navigator.userAgent);
};

const _getPhotoUrl = (
    publicId: string,
    transformations?: CloudinaryTransformationsType[],
): string => {
    if (transformations && transformations.length) {
        if (!transformations.find(t => Boolean(t.quality))) {
            transformations[0].quality = 'auto';
        }

        if (!transformations.find(t => Boolean(t.fetch_format))) {
            transformations[0].fetch_format = 'auto';
        }

        const urlArgs: cloudinary.Transformation | cloudinary.Transformation.Options = {
            secure: true,
            transformation: transformations,
            dpr: isMacOrIOSDevice() ? 2.0 : 1.0
        };

        return cloudinaryCore.url(publicId, urlArgs);
    } else if (transformations && transformations.length === 0) {
        // just silencing this so it doesn't clog up the console while debugging
        // log.warn('No photo transformations were provided');
    }

    return cloudinaryCore.url(publicId);
};

export const getPhotoUrl = (publicId: string, transformations: CloudinaryTransformationsType[]) => {
    return _getPhotoUrl(publicId, transformations);
};

export const getPhotoUrlWithoutTransformations = (publicId: string) => {
    return _getPhotoUrl(publicId);
};

export const getPhotoUrlForDownload = (publicId: string) => {
    return _getPhotoUrl(publicId);
};

export const getImageSizeFromDataURI = (imageURI: string): Promise<ImageSizeType> => {
    return new Promise((resolve) => {
        const i = new Image();
        i.onload = () => {
            resolve({
                width: i.width,
                height: i.height,
            });
        };
        i.src = imageURI;
    });
};

export type RotationType = {
    angle: number;
    horizontal: boolean;
    vertical: boolean;
};

/**
 * Converts the transformation object from the react-advanced-cropper to a string representation
 * of the `angle` property within the `cloudinary` object of the transformations we send to 
 * Cloudinary and store in the database.
 * 
 * @param rot (object) rotation object with angle, horizontal, and vertical properties
 * @returns string representation of the rotation object.
 *      i.e. "vflip.90"
 * Documentation: https://cloudinary.com/documentation/transformation_reference#a_angle
 */
export const rotationTypeToString = (rot: RotationType) => {
    let transformationString = [];
    if (rot.angle === 0) {
        transformationString.push('0');
    } else {
        transformationString.push(`${rot.angle % 360}`);
    }
    if (rot.vertical) {
        transformationString.unshift('vflip');
    }
    if (rot.horizontal) {
        transformationString.unshift('hflip');
    }
    return transformationString.join('.');
};

/**
 * This function takes in either a GatherPhoto or RawImage object and returns the Cloudinary transformations
 * needed to crop the image to a square. The transformations are based on the width and height of the image.
 * 
 * @param gatherPhoto: GatherPhoto | RawImage
 * @returns obj: CloudinaryTransformationsType
 * { 
    "width": w < h ? w : h,
    "height": w < h ? w : h,
    "x": w > h ? (w - h)/2 : 0,
    "y": w < h ? (h - w)/2 : 0,
    "angle": "0",
    "crop": "crop"
    }
 */
export const getProfilePhotoTransformations = async (
    gatherPhoto: GatherPhoto | RawImage): Promise<CloudinaryTransformationsType> => {
    let photoWidth;
    let photoHeight;
    let maxSize;
    if (isGatherPhoto(gatherPhoto)) {
        photoWidth = gatherPhoto.photo?.width || DEFAULT_WIDTH;
        photoHeight = gatherPhoto.photo?.height || DEFAULT_HEIGHT;
        maxSize = photoWidth < photoHeight ? photoWidth : photoHeight;
    } else {
        const imageSize = await getImageSizeFromDataURI(gatherPhoto.imageURI);
        photoWidth = imageSize.width;
        photoHeight = imageSize.height;
        maxSize = photoWidth < photoHeight ? photoWidth : photoHeight;
    }
    return {
        width: maxSize,
        height: maxSize,
        x: photoWidth > photoHeight ? Math.round((photoWidth - photoHeight) / 2) : 0,
        y: photoWidth < photoHeight ? Math.round((photoHeight - photoWidth) / 2) : 0,
        crop: 'crop',
        angle: '0',
    };
};

/**
 * Currently unused
 * Converts the transformation string from the react-advanced-cropper to an object so we can
 * access the `angle` property represented as an integer
 * 
 * @param transformationString (string) transformation string created from the react-advanced-cropper
 * @returns object representation of the transformation string.
 *      i.e. {"angle": 90, "horizontal": false, "vertical": true}
 * Documentation: https://cloudinary.com/documentation/transformation_reference#a_angle
 */
export const stringToRotationType = (transformationString: string): RotationType => {
    let rotationObject: RotationType = {
        angle: 0,
        horizontal: false,
        vertical: false,
    };
    if (transformationString.includes('vflip')) {
        rotationObject = {
            ...rotationObject,
            vertical: true,
        };
    }
    if (transformationString.includes('hflip')) {
        rotationObject = {
            ...rotationObject,
            horizontal: true,
        };
    }
    const rotationAngleMatch = transformationString.match(/-?\d+/);
    const rotationAngle = rotationAngleMatch ? rotationAngleMatch[0] : '0';
    if (rotationAngle) {
        rotationObject = {
            ...rotationObject,
            angle: parseInt(rotationAngle, 10),
        };
    }
    return rotationObject;
};

/**
 * This function calculates the x and y coordinates for the given `rotationAngle` to correctly map to Cloudinary's
 * coordinate system. The react-avatar-editor coordinates are placed at the center of the image, but Cloudinary's
 * coordinates are placed at the top-left of the image. This presents a problem when rotating images. The
 * coordinates for the react-avatar-editor do not change, but the Cloudinary coordinates do change based on the
 * rotation angle. The way to think about Cloudinary's system is the image origin (0,0) changes based on the angle:
 *    0 deg = top-left
 *    90 deg = bottom-left
 *    180 deg = bottom-right
 *    270 deg = top-right
 * The start point of the cropped image follows the origin location. For example, if the image is rotated 90 degrees
 * the origin is bottom-left of the image and the starting point of the image is also bottom-left.
 * To see an image and calculations view the "react-avatar-editor to Cloudinary Coordinate system" Confluence page
 *
 * @param imagePosition (object) image x and y position from avatar editor
 * @param imageSize (object) image width and height of the full original image
 * @param croppedSize (number) image width and height (same) of the cropped image from the avatar editor
 * @param rotationAngle (number) angle of rotation applied to the image (0, 90, 180, 270)
 * @param exifRotationAngle (number) angle of rotation applied to the image based on EXIF orientation (0, 90, 180, 270)
 */
export const getCloudinaryCoordinates = (data: CloudinaryCoordinatesType): CoordinatesType => {
    const {
        imagePosition,
        imageSize,
        croppedSize,
        rotationAngle,
        exifRotationAngle,
    } = data;

    let cloudinaryImagePosition: CoordinatesType = {
        ...imagePosition,
    };
    let cloudinaryImageSize: ImageSizeType = {
        ...imageSize,
    };

    // handle EXIF Orientation for determining cloudinary image position
    if (exifRotationAngle === 90 || exifRotationAngle === 270) {
        cloudinaryImageSize = {
            width: imageSize.height,
            height: imageSize.width,
        };

        if (exifRotationAngle === 90) {
            cloudinaryImagePosition = {
                x: 1 - imagePosition.y,
                y: imagePosition.x,
            };
        } else if (exifRotationAngle === 270) {
            cloudinaryImagePosition = {
                x: imagePosition.y,
                y: 1 - imagePosition.x,
            };
        }
    } else if (exifRotationAngle === 180) {
        cloudinaryImagePosition = {
            x: 1 - imagePosition.x,
            y: 1 - imagePosition.y,
        };
    }
    const cloudinaryCenter = {
        x: cloudinaryImagePosition.x * cloudinaryImageSize.width,
        y: cloudinaryImagePosition.y * cloudinaryImageSize.height,
    };
    const start = {
        x: Math.floor(cloudinaryCenter.x - (croppedSize.width * 0.5)),
        y: Math.floor(cloudinaryCenter.y - (croppedSize.height * 0.5)),
    };

    switch (rotationAngle) {
        case 90:
            return {
                x: cloudinaryImageSize.height - start.y - croppedSize.width,
                y: start.x,
            };
        case 180:
            return {
                x: cloudinaryImageSize.width - start.x - croppedSize.width,
                y: cloudinaryImageSize.height - start.y - croppedSize.height,
            };
        case 270:
            return {
                x: start.y,
                y: cloudinaryImageSize.width - start.x - croppedSize.height,
            };
        default:
            return {
                x: start.x,
                y: start.y,
            };
    }
};

export const downloadPhoto = (
    photoUrl: string | null,
    downloadName: string
) => {
    if (!photoUrl) {
        return;
    }
    let anchorTag: HTMLAnchorElement = document.createElement('a');
    anchorTag.target = '_blank';
    anchorTag.download = downloadName;
    document.body.appendChild(anchorTag);

    if (isIOSDevice()) {
        anchorTag.href = photoUrl;
        anchorTag.click();
        return;
    }

    const xhr = new XMLHttpRequest();
    xhr.open('GET', photoUrl, true);
    xhr.responseType = 'blob';

    xhr.onload = function () {
        // if response status is valid then donwload the photo from blob 
        // otherwise photo will open up in new tab 
        anchorTag.href = this.status === 200 ? URL.createObjectURL(this.response) : photoUrl;
        anchorTag.click();
    };
    xhr.send();
};

export const getSkimmedPublicId = (publicId: string | null): string | null => {
    if (!publicId) {
        return null;
    }

    return publicId.substring(publicId.lastIndexOf('/') + 1, publicId.length);
};

export const isFileTypePdf = (type: string) => type === 'application/pdf';

export const isFileTypeImage = (type: string) => type.includes('image');
