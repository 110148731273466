import { useMemo, useState } from "react";
import { LoginDialogStep, SelfLoginSrcAction } from "../SelfLoginDialog";
import { nameStepSchema } from "../types";
import {isRight} from "fp-ts/Either";

interface UseNameStepProps {
    firstName: string;
    lastName: string;
    srcAction: SelfLoginSrcAction;
    caseDisplayFname: string;
    nextStep: LoginDialogStep;
    goToStep: (step: LoginDialogStep) => void;
}

const useNameStep = ({
    firstName,
    lastName,
    srcAction,
    caseDisplayFname,
    nextStep,
    goToStep,
}: UseNameStepProps) => {
    const [hasNameError, setHasNameError] = useState(false);

    const greetingText = useMemo(() => {
        const actionMessages: Record<Exclude<SelfLoginSrcAction, 'postMemories' | 'other'>, string> = {
            [SelfLoginSrcAction.sharePhoto]: `Hello, thanks for sharing! To keep ${caseDisplayFname}'s profile secure, 
            each photo needs to come from a real person, not an anonymous post.`,
            [SelfLoginSrcAction.shareText]: `Hello, thanks for sharing! To keep ${caseDisplayFname}'s profile secure, 
            each memory needs to come from a real person, not an anonymous post.`,
            [SelfLoginSrcAction.signGuestbook]: `Hello, thanks for being here. Signing ${caseDisplayFname}'s Guestbook 
            is a simple way to show others how much you care.`,
            [SelfLoginSrcAction.flowerSale]: `Thanks for showing your support. Your order has been placed 
            successfully. Connect with ${caseDisplayFname}'s profile by answering a few questions.`,
            [SelfLoginSrcAction.treeSale]: `Thanks for showing your support. Your order has been placed successfully. 
            Connect with ${caseDisplayFname}'s profile by answering a few questions.`,
        };

        return actionMessages[srcAction] || "Hello";
    }, [srcAction, caseDisplayFname]);

    const nameStepInputValidation = useMemo(() => nameStepSchema.decode({firstName, lastName}), [firstName, lastName]);

    const handleContinue = () => {
        const isValid = isRight(nameStepInputValidation);
        setHasNameError(!isValid);

        if (isValid) {
            goToStep(nextStep);
        }
    };

    return {
        greetingText,
        hasNameError,
        handleContinue,
    };
};

export default useNameStep;