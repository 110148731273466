import { Dialog, DialogTitle, Grid, lighten, Theme, Typography } from "@mui/material";
import { PhotoOrientationType } from "../../../services";
import {
    CoverPhotoEnum,
    CropperStencilEnum,
    PhotoTransformationsType,
} from "../../../shared/types";
import { SlideTransition } from "../../common/Transitions";
import BasePhotoCropper, { BasePhotoCropperRef } from "../../profileImage/BasePhotoCropper";
import makeGStyles from "../../../styles/makeGStyles";

import CropSquareIcon from '@mui/icons-material/CropSquare';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import classNames from "classnames";
import { useRef, useState } from "react";
import GButton from "../../common/GButton";

const useStyles = makeGStyles((theme: Theme) => ({
    root: {
        maxWidth: '100%',
    },
    button: {
        margin: theme.spacing(),
    },
    cropperDialog: {
        overflowX: 'hidden',
        margin: 0,
        width: 320,
        minHeight: 320,
        maxHeight: '90vh',
    },
    orientationHeader: {
        background: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 56,
        '& $inner': {
            width: 70,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '0 8px',
            opacity: 0.5,
            cursor: 'pointer',
            '&$active, &:hover': {
                opacity: 1,
                '& svg': {
                    filter: 'drop-shadow(0 0px 2px gray)',
                },
                '& p': {
                    filter: 'drop-shadow(0 0px 2px gray)',
                },
            },
            '& svg': {
                fontSize: 28,
                color: theme.palette.common.white,
            },
            '& p': {
                fontSize: 12,
                color: theme.palette.common.white,
                textTransform: 'capitalize',
                lineHeight: '1em',
            },
        },
    },
    dialogFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        zIndex: 1,
        padding: 14,
        '@media (min-width: 350px)': {
            minWidth: 300,
        },
    },
    revertButton: {
        width: 'fit-content',
        background: theme.palette.primary.main,
        fontSize: 16,
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.2),
        },
    },
    saveButton: {
        width: 'fit-content',
        background: theme.palette.primary.main,
        fontSize: 16,
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.2),
        },
    },
    inner: {},
    active: {},
}), { name: 'ProductAndPackagePhotoCropper' });

export interface PhotoCropperProps {
    orientationTypes?: PhotoOrientationType[];
    isDialogOpen: boolean;
    imageURI: string;
    closeDialog: () => void;
    onSaveImage: (
        transformations: PhotoTransformationsType,
        newImageURI: string,
        coverPhotoType?: CoverPhotoEnum,
    ) => void;
    zIndex: number;
    stencilType?: CropperStencilEnum;
}

const ProductAndPackagePhotoCropper = (props: PhotoCropperProps) => {
    const {
        orientationTypes,
        isDialogOpen,
        imageURI,
        closeDialog,
        onSaveImage,
        zIndex,
        stencilType,
    } = props;

    const classes = useStyles();
    const [cropperOrientation, setCropperOrientation] =
        useState<PhotoOrientationType>(orientationTypes ? orientationTypes[0] : 'square');
    const cropperRef = useRef<BasePhotoCropperRef>(null);

    const handleCloseDialogEvent = () => {
        closeDialog();
    };

    const handleOrientationChange = (o: PhotoOrientationType) => {
        setCropperOrientation(o);
    };

    const handleSaveImageClick = () => {
        if (!cropperRef.current) {
            onSaveImage({}, imageURI);
            closeDialog();
        } else {
            const canvas = cropperRef.current.getCroppedCanvas();
            onSaveImage({}, canvas ? canvas.toDataURL() : imageURI);
            closeDialog();
        }
    };

    const handleRevertCrop = () => {
        cropperRef.current?.reset();
    };

    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialogEvent}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                root: classes.root,
                paper: classes.cropperDialog
            }}
            style={{ zIndex }}
        >
            <DialogTitle id="alert-dialog-title">
                {orientationTypes && orientationTypes.length > 1 &&
                    <div className={classes.orientationHeader}>
                        {orientationTypes.map(o =>
                            <div
                                key={o}
                                className={classNames(
                                    classes.inner,
                                    o === cropperOrientation && classes.active
                                )}
                                onClick={() => handleOrientationChange(o)}
                            >
                                {o === 'square' && <CropSquareIcon />}
                                {o === 'portrait' && <CropPortraitIcon />}
                                {o === 'landscape' && <CropLandscapeIcon />}
                                <Typography>{o}</Typography>
                            </div>
                        )}
                    </div>
                }
            </DialogTitle>
            <BasePhotoCropper
                imageURI={imageURI}
                orientation={cropperOrientation}
                stencilType={stencilType}
                ref={cropperRef}
            />
            <Grid item xs={12} className={classes.dialogFooter}>
                <GButton
                    className={classes.revertButton}
                    onClick={() => handleRevertCrop()}
                >
                    Revert
                </GButton>
                <GButton className={classes.revertButton} onClick={() => closeDialog()}>
                    Cancel
                </GButton>
                <GButton
                    className={classes.saveButton}
                    onClick={handleSaveImageClick}
                >
                    Save
                </GButton>
            </Grid>

        </Dialog>
    );
};

export default ProductAndPackagePhotoCropper;