import { Component } from 'react';
import { includes } from 'lodash';

import Grid from '@mui/material/Grid';
import Pages from '@mui/icons-material/Pages';

import HorizontalDivider from '../widgets/HorizontalDivider';
import Packages from './Packages';

import {
    ProductCategoryDisplayLookup,
    ProductCategoryEnum,
    ProductCategory,
    ProductUX,
    FuneralType,
    ProductPackageUX,
    ByProductCategory,
    ProductContractItemUX,
    ProductItem,
    GPLContext,
    ProductView,
} from '../../../../shared/types';
import GGroupButtons from '../../../common/GGroupButtons';
import ProductItemList, { ReactVirtualizedLayout } from '../widgets/ProductItemList';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import CareOfLovedOne from './CareOfLovedOne';

interface Props {
    canUserEditContract: boolean;
    productItemsByCategory: ByProductCategory<ProductItem>;
    caseFirstName: string;
    availablePackages: ProductPackageUX[];
    contractPackageIds: { [id: number]: number };
    isShowPrices: boolean;
    context: GPLContext;
    onAddAdditionalItem: (category: ProductCategory) => void;
    onProductItemClick: (productId?: number, contractItemId?: string) => void;
    onPackageClick: (packageId: number) => void;
    onAddContractItem: (product: ProductUX, quantity: number) => void;
    onUpdateContractItemQuantity: (contractItem: ProductContractItemUX, quantity: number) => void;
    onRemoveContractItem: (contractItem: ProductContractItemUX) => void;
    onAddPackage: (packageId: number) => void;
    onRemovePackage: (packageId: number) => void;
}

interface State {
    selectedFuneralType: FuneralType;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    header: {
        textAlign: 'center',
        margin: '24px auto',
        '@supports (-webkit-touch-callout: none)': {
            '& button:nth-of-type(2)': {
                marginTop: -1
            }
        }
    },
    listContainer: {
        width: '100% !important',
        '& ul': {
            display: 'flex !important',
            '& li': {
                margin: 0
            }
        }
    }
});

type StyledProps = Props & WithStyles<'root' | 'header' | 'listContainer'>;

class ProfessionalServices extends Component<StyledProps, State> {
    state: State = {
        selectedFuneralType: FuneralType.burial,
    };

    onProductItemClick = (productItem: ProductItem) => {
        const { onProductItemClick } = this.props;

        onProductItemClick(
            productItem.product && productItem.product.id || undefined,
            productItem.contractItem && productItem.contractItem.id || undefined,
        );
    };

    renderHeader = () => {
        const { classes } = this.props;
        const { selectedFuneralType } = this.state;

        return (
            <Grid item xs={12} className={classes.header}>
                <GGroupButtons
                    buttons={[
                        { label: 'Burial', value: FuneralType.burial },
                        { label: 'Cremation', value: FuneralType.cremation },
                    ]}
                    activeButton={selectedFuneralType}
                    onClick={this.onChangeSelectedFuneralType}
                />
            </Grid>
        );
    };

    render() {
        const {
            classes,
            productItemsByCategory,
            caseFirstName,
            availablePackages,
            contractPackageIds,
            onAddAdditionalItem,
            onAddContractItem,
            onUpdateContractItemQuantity,
            onRemoveContractItem,
            isShowPrices,
            onAddPackage,
            onRemovePackage,
            canUserEditContract,
            context,
            onPackageClick,
        } = this.props;

        const { selectedFuneralType } = this.state;

        const filteredPackages = availablePackages.filter((pkg) =>
            pkg.category === null && includes(pkg.funeral_type, selectedFuneralType));

        const careOfLovedOneProductItems = productItemsByCategory[ProductCategoryEnum.care_of_loved_one] || [];
        const addedCareOfLovedOneCount = careOfLovedOneProductItems
            .filter((item) => item.contractItem !== undefined).length;
        const transportationProductItems = productItemsByCategory[ProductCategoryEnum.transportation] || [];
        const addedTransportationCount = transportationProductItems
            .filter((item) => item.contractItem !== undefined).length;
        const equipmentProductItems = productItemsByCategory[ProductCategoryEnum.equipment_facilities_staff] || [];
        const addedEquipmentCount = equipmentProductItems.filter((item) => item.contractItem !== undefined).length;

        return (
            <Grid container justifyContent="center" alignItems="center">
                {this.renderHeader()}

                <HorizontalDivider
                    inset="x"
                    label={'select a package'}
                    color="primary"
                    icon={{ position: 'precedent', root: <Pages /> }}
                />

                <Packages
                    packages={filteredPackages}
                    contractPackageIds={contractPackageIds}
                    openPackageDialog={onPackageClick}
                    isShowPrices={isShowPrices}
                    onAddPackage={onAddPackage}
                    onRemovePackage={onRemovePackage}
                    canUserEditContract={canUserEditContract}
                    context={context}
                />

                {careOfLovedOneProductItems.length > 0 && <>
                    <HorizontalDivider
                        inset="x"
                        label={`${ProductCategoryDisplayLookup[ProductCategoryEnum.care_of_loved_one]} 
                            ${addedCareOfLovedOneCount ? `(${addedCareOfLovedOneCount})` : ''}`}
                        color="primary"
                    />

                    <CareOfLovedOne
                        productItems={careOfLovedOneProductItems}
                        caseFirstName={caseFirstName}
                        onAddAdditionalItemClick={() => onAddAdditionalItem(ProductCategoryEnum.care_of_loved_one)}
                        onProductItemClick={this.onProductItemClick}
                        onAddContractItem={onAddContractItem}
                        onRemoveContractItem={onRemoveContractItem}
                        isShowPrices={isShowPrices}
                        canUserEditContract={canUserEditContract}
                        context={GPLContext.Public}
                        productView={ProductView.grid_view}
                    />
                </>}

                {transportationProductItems.length > 0 && <>
                    <HorizontalDivider
                        inset="x"
                        label={`${ProductCategoryDisplayLookup[ProductCategoryEnum.transportation]} 
                            ${addedTransportationCount ? `(${addedTransportationCount})` : ''}`}
                        color="primary"
                    />

                    <ProductItemList
                        productItems={transportationProductItems}
                        caseFirstName={caseFirstName}
                        onAddAdditionalItemClick={() => onAddAdditionalItem(ProductCategoryEnum.transportation)}
                        onProductItemClick={this.onProductItemClick}
                        onAddContractItem={onAddContractItem}
                        onUpdateContractItemQuantity={onUpdateContractItemQuantity}
                        onRemoveContractItem={onRemoveContractItem}
                        isShowPrices={isShowPrices}
                        canUserEditContract={canUserEditContract}
                        containerClass={classes.listContainer}
                        allowances={[]} // no allowances on professional services
                        layout={ReactVirtualizedLayout.horizontal}
                        context={context}
                    />
                </>}

                {equipmentProductItems.length > 0 && <>
                    <HorizontalDivider
                        inset="x"
                        label={`${ProductCategoryDisplayLookup[ProductCategoryEnum.equipment_facilities_staff]} 
                            ${addedEquipmentCount ? `(${addedEquipmentCount})` : ''}`}
                        color="primary"
                    />

                    <ProductItemList
                        productItems={equipmentProductItems}
                        caseFirstName={caseFirstName}
                        onAddAdditionalItemClick={() =>
                            onAddAdditionalItem(ProductCategoryEnum.equipment_facilities_staff)}
                        onProductItemClick={this.onProductItemClick}
                        onAddContractItem={onAddContractItem}
                        onUpdateContractItemQuantity={onUpdateContractItemQuantity}
                        onRemoveContractItem={onRemoveContractItem}
                        isShowPrices={isShowPrices}
                        canUserEditContract={canUserEditContract}
                        containerClass={classes.listContainer}
                        allowances={[]} // no allowances on professional services
                        layout={ReactVirtualizedLayout.horizontal}
                        context={context}
                    />

                </>}
                <HorizontalDivider inset="x" color="primary" />
            </Grid>
        );
    }

    onChangeSelectedFuneralType = (selectedFuneralType: FuneralType) => {
        if (FuneralType[selectedFuneralType]) {
            this.setState({ selectedFuneralType: FuneralType[selectedFuneralType] });
        }
    };
}

export default withStyles(styles)(ProfessionalServices);
