import * as constants from '../constants';
import { FlowerSalesState } from '../types';
import {
    FLOWER_SALES_DELIVERY_DATES_LOAD_FAILED,
    FLOWER_SALES_DELIVERY_DATES_LOADED,
    FLOWER_SALES_DELIVERY_DATES_LOADING,
    FLOWER_SALES_LOADED,
    FLOWER_SALES_ORDER_DRAFT_FAILED,
    FLOWER_SALES_ORDER_DRAFT_LOADED,
    FLOWER_SALES_ORDER_DRAFT_LOADING,
    FLOWER_SALES_ORDER_PAGE_FAILED,
    FLOWER_SALES_ORDER_PAGE_LOADED,
    FLOWER_SALES_ORDER_PAGE_LOADING,
    FLOWER_SALES_ORDER_PLACED,
    FLOWER_SALES_PLACE_ORDER_FAILED,
    FLOWER_SALES_PLACING_ORDER,
    FLOWER_SALES_PRODUCTS_LOAD_FAILED,
    FLOWER_SALES_PRODUCTS_LOADED,
    FLOWER_SALES_PRODUCTS_LOADING, FLOWER_SALES_UPDATED,
    FlowerSalesAction,
} from '../actions/FlowerSales.action';
import { GatherAction } from '../actions';
import { LOADED_PUBLIC_CASE } from '../actions/GatherCase.action';

export const flowerSalesInitData: FlowerSalesState = {
    products: [],
    informant: null,
    events: [],
    deliveryDates: [],
    totalProducts: 0,
    authorizeNetInfo: null,
    orderDraft: null,
    productToOrder: null,
    isProductsLoading: false,
    isOrderPageLoading: false,
    isOrderDraftLoading: false,
    isDeliveryDatesLoading: false,
    isDeliveryZipValid: true,
    isPlacingOrder: false,
    ordersForCase: [],
};

export const flowerSalesState = (
    state: FlowerSalesState = flowerSalesInitData,
    action: FlowerSalesAction | GatherAction,

): FlowerSalesState => {
    switch (action.type) {
        case constants.USER_LOGGED_OUT:
            return flowerSalesInitData;
        case FLOWER_SALES_PRODUCTS_LOADING: {
            return {
                ...state,
                isProductsLoading: true,
            };
        }
        case FLOWER_SALES_PRODUCTS_LOADED: {
            return {
                ...state,
                isProductsLoading: false,
                products: action.response.products,
                totalProducts: action.response.total,
            };
        }
        case FLOWER_SALES_PRODUCTS_LOAD_FAILED: {
            return {
                ...state,
                isProductsLoading: false,
            };
        }
        case FLOWER_SALES_ORDER_DRAFT_LOADING: {
            return {
                ...state,
                isOrderDraftLoading: true,
            };
        }
        case FLOWER_SALES_ORDER_DRAFT_LOADED: {
            return {
                ...state,
                isOrderDraftLoading: false,
                authorizeNetInfo: action.response.authorizeNetInfo,
                orderDraft: action.response.orderDraft,
            };
        }
        case FLOWER_SALES_ORDER_DRAFT_FAILED: {
            return {
                ...state,
                isOrderDraftLoading: false,
            };
        }
        case FLOWER_SALES_ORDER_PAGE_LOADING: {
            return {
                ...state,
                isOrderPageLoading: true,
            };
        }
        case FLOWER_SALES_ORDER_PAGE_LOADED: {
            return {
                ...state,
                isOrderPageLoading: false,
                informant: action.response.informant,
                events: action.response.events,
                productToOrder: action.response.product,
            };
        }
        case FLOWER_SALES_ORDER_PAGE_FAILED: {
            return {
                ...state,
                isOrderPageLoading: false,
            };
        }
        case FLOWER_SALES_DELIVERY_DATES_LOADING: {
            return {
                ...state,
                isDeliveryDatesLoading: true,
                isDeliveryZipValid: true,
            };
        }
        case FLOWER_SALES_DELIVERY_DATES_LOADED: {
            return {
                ...state,
                isDeliveryDatesLoading: false,
                deliveryDates: action.deliveryDates,
                isDeliveryZipValid: action.deliveryDates.length > 0,
            };
        }
        case FLOWER_SALES_DELIVERY_DATES_LOAD_FAILED: {
            return {
                ...state,
                isDeliveryDatesLoading: false,
            };
        }
        case FLOWER_SALES_PLACING_ORDER: {
            return {
                ...state,
                isPlacingOrder: true,
            };
        }
        case FLOWER_SALES_ORDER_PLACED: {
            return {
                ...state,
                isPlacingOrder: false,
            };
        }
        case FLOWER_SALES_PLACE_ORDER_FAILED: {
            return {
                ...state,
                isPlacingOrder: false,
            };
        }
        case LOADED_PUBLIC_CASE:
        case FLOWER_SALES_LOADED: {
            return {
                ...state,
                ordersForCase: action.flowerSales,
            };
        }
        case FLOWER_SALES_UPDATED: {
            // Find the existing sale in the list
            const existingSale = state.ordersForCase.find(sale => sale.id === action.flowerSale.id);
            // If the new sale is not in the list, add it
            if (!existingSale && action.flowerSale.id !== undefined && action.flowerSale.id !== null) {
                return {
                    ...state,
                    ordersForCase: [action.flowerSale, ...state.ordersForCase],
                };
            }
            // Replace the existing sale with the new sale
            const updatedOrders = state.ordersForCase.map(sale => {
                return sale.id === action.flowerSale.id ? action.flowerSale : sale;
            });
            // Return the updated state
            return {
                ...state,
                ordersForCase: updatedOrders,
            };
        }
        default:
            return state;
    }
};
