import * as React from 'react';
import classNames from 'classnames';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';

import { ORANGE_COLOR } from '../../../../constants/colorVariables';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../styles/WithGStyles';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 8,
        '@media (min-width: 600px)': {
            flexDirection: 'row',
            margin: '8px 18px',
        }
    },
    card: {
        borderRadius: 8,
        width: '100%',
        display: 'block',
        boxShadow: 'none',
        '& $actionAreaRoot': {
            '&:hover': {
                '& $disableFocus': {
                    opacity: 0
                },
            }
        },
        '& $cardContent': {
            padding: '24px 8px',
            minHeight: 36,
            display: 'flex',
            alignItems: 'center',
            '@media (min-width: 600px)': {
                padding: 8,
            }
        },
        '& button': {
            height: '100%'
        },
        '&$inverted': {
            background: 'transparent',
            color: theme.palette.primary.main,
            border: '1px solid',
        },
        '&$primary': {
            boxShadow: theme.shadows[4],
            border: `1px solid ${theme.palette.primary.main}`,
            background: theme.palette.primary.main,
            color: theme.palette.common.white
        },
        '&$dashed': {
            background: 'transparent',
            color: theme.palette.primary.main,
            border: `1px dashed ${ORANGE_COLOR}`,
        },
    },
    badge: {
        position: 'relative',
        cursor: 'pointer',
        '&$top': {
            position: 'absolute',
            top: -18,
            right: -12
        },
        '&$bottom': {
            top: -18
        },
        '&$right': {
            position: 'absolute',
            right: -18
        },
        '&$left': {
            position: 'absolute',
            left: 0,
            transform: 'translateX(-50%)',
        },
    },
    actionAreaRoot: {},
    disableFocus: {},
    active: {},
    top: {},
    bottom: {},
    right: {},
    left: {},
    primary: {},
    inverted: {},
    cardContent: {},
    dashed: {},
});

type StyledProps = Props & GLOBAL_STYLED_PROPS &
    WithStyles<'root' | 'card' | 'active' | 'badge' | 'top' | 'bottom' | 'right' | 'left'
        | 'primary' | 'inverted' | 'cardContent' | 'disableFocus' | 'actionAreaRoot' | 'dashed'>;

export interface CardBadge {
    position: 'top' | 'bottom' | 'right' | 'left';
    badge: JSX.Element;
    tooltipTitle?: string;
    tooltipEnterDelay?: number;
    onClick?: () => void;
}

interface Props {
    color: 'primary' | 'inverted' | 'dashed';
    disableRipple?: boolean;
    badges?: CardBadge[];
    disableFocus?: boolean;
}

const ProductItemCardBlockBase = (props: React.PropsWithChildren<StyledProps>) => {
    const { classes, color, children, badges, disableFocus, disableRipple } = props;

    return (
        <div className={classes.root}>
            <Card
                className={classNames(
                    classes.card,
                    classes[color],
                )}
            >
                <CardActionArea
                    disableRipple={disableRipple}
                    classes={{
                        root: classes.actionAreaRoot,
                        focusHighlight: disableFocus && classes.disableFocus || undefined
                    }}
                >
                    <CardContent className={classes.cardContent}>
                        {children}
                    </CardContent>
                </CardActionArea>
            </Card>
            {(badges || []).map((b, i) =>
                <Tooltip key={i} title={<>{b.tooltipTitle}</>} placement="top">
                    <div
                        className={classNames(
                            classes.badge,
                            classes[b.position],
                            !b.onClick && classes.cursorDefault
                        )}
                        onClick={!b.onClick ? undefined : (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (b.onClick) {
                                b.onClick();
                            }
                        }}
                    >
                        {b.badge}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default withGStyles(styles)(ProductItemCardBlockBase);
