import { partition } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import QrCode2Icon from '@mui/icons-material/QrCode2';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import School from '@mui/icons-material/School';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { GATHER_ACADEMY_LARGE_LOGO, QR_MEDALLION_GOLD, ZEBRA_LOGO_SRC } from '../../../../constants';
import { APP_SECONDARY_COLOR, ORANGE_COLOR, SUCCESS_COLOR } from '../../../../constants/colorVariables';
import {
    getIntercomTargetProp,
    IntercomTrackEvent,
    isAndroidDevice,
    isIOSDevice,
    isSafariBrowser,
    resetZebraBrowserPrintHealthCheckCount,
    RouteBuilder,
    submitIntercomEvent,
} from '../../../../services';
import { FeatureKey, GatherCaseUX, KeeptrackCardType, UserProfile } from '../../../../shared/types';
import makeGStyles from '../../../../styles/makeGStyles';
import { useGSelector } from '../../../../types';
import { GatherAcademyButton } from '../../../appBars/GatherAcademyPopper';
import { isEnabled, WhenEnabled } from '../../../common/AppFeature';
import GLink from '../../../common/GLink';
import GLinkWithButton from '../../../common/GLinkWithButton';
import TabsInFamilyView from '../../../common/TabsInFamilyView';
import NotesDialog from '../../notes/NotesDialog';
import PrintQRStickersDialog from '../../printQRStickersDialog/PrintQRStickers.dialog';
import PrintQRStickersDialogZebra from '../../printQRStickersDialogZebra/PrintQRStickersZebra.dialog';
import { TrackingItem, TrackingItemStatus } from '../BodyTrackingContent';
import QRCompletedSteps from './QRCompletedSteps';
import QRLabel from './QRLabel';
import QRPendingSteps from './QRPendingSteps';
import QRStepperHeader from './QRStepperHeader';
import { Typography } from '@mui/material';
import { buildPDFLabelLink } from '../../utils';
import QRStepperPrintView from './QRStepperPrintView';
import { log } from '../../../../logger';

export const useQRStepperCommonStyles = makeStyles(
    (theme: Theme) => ({
        stepWrapper: {
            justifyContent: 'center',
            paddingLeft: 8,
            '@media (min-width: 600px)': {
                paddingLeft: 0,
            },
        },
        stepLeftContainer: {
            float: 'left',
            width: 52,
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& svg': {
                fontSize: 26,
                color: theme.palette.secondary.main,
            },
            '&$noteContainer': {
                width: 'unset',
                paddingLeft: 18,
                '& svg': {
                    color: `${SUCCESS_COLOR} !important`,
                },
            },
            '&$keepTrack': {
                width: 'unset',
                paddingLeft: 25,
            },
        },
        stepConnector: {
            height: '100%',
            width: '0px',
            margin: '-8px auto',
            borderLeft: `2px dashed ${theme.palette.secondary.main}`,
        },
        stepConnectorButton: {
            height: 34,
        },
        stepContainer: {
            float: 'right',
            width: 'calc(100% - 52px)',
            textAlign: 'left',
            margin: '0 0 16px',
        },
        lineHeight1: {
            lineHeight: 1,
        },
        marginRight2: {
            marginRight: 2,
        },
        moreVertIcon: {
            width: 20,
            height: 20,
            '& svg': {
                fontSize: 18,
            },
        },
        completedStepBorder: {
            borderLeft: `2px solid ${SUCCESS_COLOR}`,
        },
        completedStepContainer: {
            float: 'right',
            width: 'calc(100% - 52px)',
            textAlign: 'left',
            marginBottom: 28,
            marginLeft: 6,
            '&$lastStep': {
                minHeight: 160,
            },
        },
        marginTop4: {
            marginTop: 4,
        },
        borderRadius50: {
            borderRadius: '50%',
        },
        iconContainer: {
            minWidth: 52,
            maxWidth: 52,
            minHeight: 52,
            maxHeight: 52,
            zIndex: 1,
            background: theme.palette.common.white,
            border: `2px dashed ${theme.palette.secondary.main}`,
            boxSizing: 'border-box',
            '&$borderSuccess': {
                border: `2px solid ${SUCCESS_COLOR}`,
            },
            '& svg': {
                fontSize: 34,
            },
            '& img': {
                height: 'inherit',
                width: 'inherit',
            },
            '&$workflowUpdated': {
                background: '#fff !important',
                border: `2px solid ${ORANGE_COLOR} !important`,
                '& svg': {
                    color: `${ORANGE_COLOR} !important`,
                },
            },
        },
        stepImageBorderRadius: {
            borderRadius: 6,
            '& img': {
                borderRadius: 4,
                width: '100%',
                height: '100%',
            },
        },
        stepIconImage: {
            padding: 0,
            borderRadius: 6,
            minWidth: 48,
            width: '100%',
            height: '100%',
            '& $locationIcon': {
                fontSize: 42,
                color: SUCCESS_COLOR,
                opacity: 1,
            },
        },
        stepLabel: {
            alignItems: 'center',
            fontSize: 18,
            fontWeight: 500,
            '&$isSkipped': {
                color: theme.palette.secondary.main,
                textDecoration: 'line-through',
            },
            '&$workflowUpdated': {
                color: ORANGE_COLOR,
            },
            '& svg': {
                color: `${theme.palette.primary.main} !important`,
                fontSize: 20,
                marginTop: -2,
                marginLeft: 8,
            },
        },
        labelContent: {
            backgroundColor: SUCCESS_COLOR,
            padding: '4px 16px 4px 24px',
            borderRadius: 4,
            color: theme.palette.common.white,
            textAlign: 'center',
            transform: 'translateX(-32px)',
            '@media (min-width: 375px)': {
                padding: '4px 16px',
            },
            '&$isSkipped': {
                color: `${theme.palette.common.white} !important`,
                backgroundColor: `${theme.palette.secondary.main} !important`,
            },
        },
        labelContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            '&$keepTrack': {
                marginBottom: 4,
            },
        },
        opacity30Important: {
            '& svg': {
                opacity: '30%',
            },
            '& p': {
                opacity: '30%',
            },
        },
        isSkipped: {},
        lastStep: {},
        locationIcon: {},
        borderSuccess: {},
        workflowUpdated: {},
        keepTrack: {},
        noteContainer: {},
    }),
    { name: 'QRStepperCommonStyles' },
);

// columns: 'xs: 1, sm: 1, md: 1, lg: 2, xl: 3',
const useStyles = makeGStyles(
    (theme) => ({
        footerBtnContainer: {
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media (min-width: 768px)': {
                flexDirection: 'row',
                alignItems: 'flex-start',
            },
            '& button': {
                boxShadow: 'none !important',
                padding: '4px 8px 4px 4px',
                textTransform: 'none',
                fontWeight: 400,
                maxWidth: 190,
                borderRadius: '50px',
                '&:disabled': {
                    color: 'rgba(0,0,0,0.5)',
                    background: 'rgba(0,0,0,0.5) !important',
                    pointerEvents: 'none',
                },
            },
        },
        qrCodeIcon: {
            borderRadius: '50%',
            minWidth: 32,
            height: 32,
        },
        qrCodeZebraIcon: {
            borderRadius: '50%',
            minWidth: 32,
            height: 32,
            backgroundImage: `url(${ZEBRA_LOGO_SRC})`,
        },
    }),
    { name: 'QRStepper' },
);

interface Props {
    trackingItems: TrackingItem[];
    selectedCase: GatherCaseUX;
    isFamilyView: boolean;
    userData: UserProfile;
    openWorkflowSelectorDialog: VoidFunction;
    toggleBulkEditMode: VoidFunction;
    openAllStepsDialog: VoidFunction;
    openSkipStepDialog: (stepId: number) => void;
    onDueDateClick: (stepId: number) => void;
}

const QRStepper = (props: Props) => {
    const {
        isFamilyView,
        selectedCase,
        trackingItems,
        userData,
        openWorkflowSelectorDialog,
        toggleBulkEditMode,
        openAllStepsDialog,
        openSkipStepDialog,
        onDueDateClick,
    } = props;

    const classes = useStyles();
    const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);
    const [printQRStickersDialogOpen, setPrintQRStickersDialogOpen] = useState(false);
    const [printZebraQRStickersDialogOpen, setPrintZebraQRStickersDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [printedTime, setPrintedTime] = useState<Date | null>(null);
    const printIframeRef = useRef<HTMLIFrameElement | null>(null);

    const isQRStickerZebraEnabled = useGSelector(({ funeralHomeState, casesState, userSession }) =>
        isEnabled(FeatureKey.QR_STICKER_ZEBRA, funeralHomeState, casesState, userSession),
    );

    const [completedItems, pendingItems] = useMemo(
        () =>
            partition(
                trackingItems,
                (step) => step.status === TrackingItemStatus.completed || step.status === TrackingItemStatus.skipped,
            ),
        [trackingItems],
    );

    useEffect(() => {
        if (!printZebraQRStickersDialogOpen) {
            resetZebraBrowserPrintHealthCheckCount();
        }
    }, [printZebraQRStickersDialogOpen]);

    if (isFamilyView) {
        return (
            <TabsInFamilyView
                text="Tracking Steps are hidden while in Family View"
                icon={<QrCode2Icon />}
                footerText={
                    <span className={classNames(classes.fontSize14, classes.marginTop16)}>
                        <GLink
                            to={RouteBuilder.FamilyPage({
                                caseName: selectedCase.name,
                                funeralHomeKey: selectedCase.funeral_home.key,
                            })}
                            linkClass={classNames(classes.colorPrimary, classes.textClickable)}
                        >
                            Click here
                        </GLink>{' '}
                        to see what the family sees
                    </span>
                }
            />
        );
    }

    const openNotesDialog = () => {
        setIsNotesDialogOpen(true);
    };

    const printLabelPDF = async () => {
        const labelLink = buildPDFLabelLink({ caseName: selectedCase.name });
        if (isIOSDevice() || isSafariBrowser()) {
            window.open(labelLink, '_blank');
        } else {
            setIsLoading(true);
            const response = await fetch(labelLink);
            const pdfBlob = await response.blob();
            const objectUrl = URL.createObjectURL(pdfBlob);
            if (isAndroidDevice()) {
                const printWindow = window.open(objectUrl, '_blank');
                if (printWindow) {
                    printWindow.onload = function () {
                        try {
                            printWindow.print();
                        } catch (ex) {
                            // unable to auto print
                        }
                    };
                }
            } else {
                if (printIframeRef.current) {
                    printIframeRef.current.setAttribute('src', objectUrl);
                    printIframeRef.current.addEventListener('load', () => {
                        if (printIframeRef.current?.contentWindow) {
                            try {
                                printIframeRef.current.contentWindow.print();
                                URL.revokeObjectURL(objectUrl);
                            } catch (ex) {
                                window.open(objectUrl, '_blank');
                            }
                        }
                    });
                } else {
                    window.open(objectUrl, '_blank');
                }
            }
            setIsLoading(false);
        }
    };

    const waitForImagesToLoad = (container: HTMLElement) => {
        const images = container.querySelectorAll("img");
        const loadPromises = Array.from(images).map(
            (img) =>
                new Promise<void>((resolve) => {
                    if (img.complete) {
                        resolve();
                    } else {
                        img.onload = () => resolve();
                        img.onerror = () => {
                            resolve();
                        };
                    }
                })
        );
        return Promise.all(loadPromises);
    };

    const handlePrint = async () => {
        setPrintedTime(new Date());
        printSteps();
    };

    const printSteps = async () => {
        const printContents = document.getElementById('qr-stepper-print-view');
        const printContainer = document.getElementById('print-container');

        if (!printContents || !printContainer) {
            return;
        }

        const materialIconStyles = `       
        <style>
            @ page {
                margin-top: .25;
                margin-bottom: .5;
                margin-left: .5;
                margin-right: .5;
            }
        
            @media print {
                hr {
                    display: block;
                    height: 1px;
                    background-color: black;
                    color: black;
                    box-shadow: none;
                    width: 100%;
                }
            }

        </style>`;

        printContainer.innerHTML = materialIconStyles + printContents.innerHTML;

        try {
            await waitForImagesToLoad(printContainer);

            window.scrollTo(0, 0);
            window.print();
        } catch (error) {
            log.warn('Failed to load images:', { error: error });
            return;
        }
    };

    return (
        <>
            <iframe ref={printIframeRef} style={{ display: 'none' }} />
            <QRStepperHeader
                completedItemsCount={completedItems.length}
                selectedCase={selectedCase}
                openNotesDialog={openNotesDialog}
                openWorkflowSelectorDialog={openWorkflowSelectorDialog}
                toggleBulkEditMode={toggleBulkEditMode}
                openAllStepsDialog={openAllStepsDialog}
                openPrintQRStickersDialog={() => setPrintQRStickersDialogOpen(true)}
                openPrintZebraQRStickersDialog={() => setPrintZebraQRStickersDialogOpen(true)}
            />

            <QRCompletedSteps
                caseFName={selectedCase.fname}
                keepTrackKey={selectedCase.keeptrack_key ?? 'No Tracker'}
                items={completedItems}
                openNotesDialog={openNotesDialog}
                caseUuid={selectedCase.uuid}
                onDownloadTracking={handlePrint}
            />

            <QRPendingSteps
                zIndex={1320}
                selectedCase={selectedCase}
                steps={pendingItems}
                toggleBulkEditMode={toggleBulkEditMode}
                openNotesDialog={openNotesDialog}
                openAllStepsDialog={openAllStepsDialog}
                openSkipStepDialog={openSkipStepDialog}
                openWorkflowSelectorDialog={openWorkflowSelectorDialog}
                onDueDateClick={onDueDateClick}
            />

            {selectedCase.keeptrack_assigned_time && (
                <Grid
                    mt={4}
                    sx={{
                        '@media (max-width: 600px)': {
                            paddingInline: '10px',
                        },
                    }}
                >
                    <QRLabel selectedCase={selectedCase} onPrintClick={printLabelPDF} isLoading={isLoading} />
                </Grid>
            )}

            {selectedCase.keeptrack_assigned_time && (
                <>
                    <Grid mt={3} textAlign="center">
                        <Typography color={APP_SECONDARY_COLOR} fontSize="15px">
                            Still supporting our legacy methods of printing:
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        columnSpacing={1}
                        rowSpacing={4}
                        mt={0}
                        className={classes.footerBtnContainer}
                    >
                        <Grid item paddingTop="7px !important">
                            <GLinkWithButton
                                to={RouteBuilder.KeepTrack({
                                    funeralHomeKey: selectedCase.funeral_home.key,
                                    caseName: selectedCase.name,
                                    cardType: KeeptrackCardType.additional,
                                })}
                                GButtonProps={{ variant: 'contained' }}
                                intercomTargetProp={`TrackingPage-TrackTab-AddFamilyMedallion`}
                            >
                                <img src={QR_MEDALLION_GOLD} width={32} height={32} />

                                <span>Add a Family Medallion</span>
                            </GLinkWithButton>
                        </Grid>

                        <WhenEnabled feature={FeatureKey.QR_STICKER}>
                            <Grid item paddingTop="7px !important">
                                <Button variant="contained" onClick={() => setPrintQRStickersDialogOpen(true)}>
                                    <span
                                        className={classNames(
                                            classes.backgroundWhite,
                                            classes.flexCentred,
                                            classes.qrCodeIcon,
                                        )}
                                        {...getIntercomTargetProp(`TrackingPage-TrackTab-PrintQRStickers`)}
                                    >
                                        <QrCodeScannerIcon color="primary" />
                                    </span>

                                    <span>Print QR Stickers on Avery Labels</span>
                                </Button>
                            </Grid>
                        </WhenEnabled>

                        <Grid item paddingTop="7px !important">
                            <Button
                                variant="contained"
                                onClick={() => setPrintZebraQRStickersDialogOpen(true)}
                                disabled={!isQRStickerZebraEnabled}
                                {...getIntercomTargetProp(
                                    isQRStickerZebraEnabled
                                        ? undefined
                                        : `TrackingPage-TrackTab-MobilePrinter-Disabled`,
                                )}
                            >
                                <span
                                    className={classNames(
                                        classes.backgroundWhite,
                                        classes.flexCentred,
                                        classes.qrCodeZebraIcon,
                                    )}
                                >
                                    <img src={ZEBRA_LOGO_SRC} className={classes.qrCodeZebraIcon} />
                                </span>

                                <span>Print QR Stickers from Zebra Printer</span>
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}

            {selectedCase.keeptrack_assigned_time && (
                <WhenEnabled feature={FeatureKey.GATHER_ACADEMY}>
                    <Box
                        boxSizing="border-box"
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        px={1.5}
                        m="24px auto 0"
                        pb={1}
                    >
                        <img width={140} src={GATHER_ACADEMY_LARGE_LOGO} />

                        <GatherAcademyButton
                            disableElevation
                            size="large"
                            startIcon={<School />}
                            text={<>KeepTrack&trade; Certification</>}
                            onClick={() => submitIntercomEvent(IntercomTrackEvent.GatherAcademy)}
                            sx={(theme) => ({
                                fontSize: 13,
                                [theme.breakpoints.down(400)]: {
                                    px: 2,
                                },
                                [theme.breakpoints.up(400)]: {
                                    fontSize: 14,
                                },
                                '& svg': {
                                    fontSize: '24px !important',
                                },
                            })}
                        />
                    </Box>
                </WhenEnabled>
            )}

            <WhenEnabled feature={FeatureKey.QR_STICKER}>
                <PrintQRStickersDialog
                    zIndex={1320}
                    open={printQRStickersDialogOpen}
                    selectedCase={selectedCase}
                    onClose={() => setPrintQRStickersDialogOpen(false)}
                />
            </WhenEnabled>

            <WhenEnabled feature={FeatureKey.QR_STICKER_ZEBRA}>
                {printZebraQRStickersDialogOpen && (
                    <PrintQRStickersDialogZebra
                        zIndex={1320}
                        selectedCase={selectedCase}
                        onClose={() => setPrintZebraQRStickersDialogOpen(false)}
                    />
                )}
            </WhenEnabled>

            <NotesDialog
                isDialogOpen={isNotesDialogOpen}
                closeDialog={() => setIsNotesDialogOpen(false)}
                zIndex={1320}
                caseUuid={selectedCase.uuid}
                gatherCaseFname={selectedCase.fname}
            />

            <QRStepperPrintView
                userData={userData}
                printElementId="qr-stepper-print-view"
                gatherCase={selectedCase}
                trackingItems={completedItems}
                printedTimeStamp={printedTime}
                isHidden
            />
        </>
    );
};
export default QRStepper;
