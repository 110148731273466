import { Typography, Tooltip, Button, Theme, Grid, Dialog, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ClearIcon } from '@mui/x-date-pickers-pro';
import classNames from 'classnames';
import { PaymentMethodIcon, PaymentFieldToolTip } from '.';
import { getFormattedPhoneNumber, getPaymentMethodLabel } from '../../../../services';
import { FuneralHomeUX, CaseTransactions, GatherCaseUX, PaymentMode, PaymentMethod } from '../../../../shared/types';
import FuneralHomeLogo from '../../../common/FuneralHomeLogo';
import { SlideTransition } from '../../../common/Transitions';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import LoadingSpinner from '../../../common/LoadingSpinner';
import { GStyles } from '../../../../styles/GStyles';
import useTransactionDetails from '../hooks/useTransactionDetails';

const PaymentHeader = ({
    caseNumber,
    onClose,
    isPrintMode,
}: {
    caseNumber: string;
    onClose: () => void;
    isPrintMode: boolean;
}) => {
    const classes = useStyles();

    if (isPrintMode) {
        return null;
    }

    return (
        <>
            <div className={classes.cardContainer}>
                <Typography className={classes.cardNumber} style={typographyStyle}>
                    <span style={{ fontWeight: 200 }}>{caseNumber}</span>
                </Typography>
            </div>
            <ClearIcon className={classNames(classes.clearIcon)} onClick={onClose} />
        </>
    );
};

const FuneralHomeDetails = ({
    funeralHome,
    activeCase,
    isPrintMode,
}: {
    funeralHome: FuneralHomeUX;
    activeCase: GatherCaseUX;
    isPrintMode: boolean;
}) => {
    if (!isPrintMode || !funeralHome) {
        return null;
    }

    return (
        <div style={{ marginBottom: 0 }}>
            <Typography
                style={{ ...smallTyphographyStyle, borderTop: '1px solid', paddingTop: 4, textAlign: 'center' }}
            >
                {funeralHome.address1}
            </Typography>
            <Typography style={{ ...smallTyphographyStyle, textAlign: 'center' }}>
                {funeralHome.city && <span>{funeralHome.city}, </span>}
                {funeralHome.state && <span>{funeralHome.state} </span>}
                {funeralHome.postal_code && <span>{funeralHome.postal_code}</span>}
            </Typography>
            <Typography style={{ ...smallTyphographyStyle, textAlign: 'center' }}>
                {getFormattedPhoneNumber(activeCase.funeral_home.phone)}
            </Typography>
            <Typography style={{ ...smallTyphographyStyle, textAlign: 'center' }}>
                {activeCase.assignee.email}
            </Typography>
            <div
                style={{
                    ...smallTyphographyStyle,
                    borderBottom: '1px solid',
                    paddingTop: 4,
                    textAlign: 'center',
                }}
            />
            <Typography
                style={{
                    ...smallTyphographyStyle,
                    textAlign: 'left',
                    fontSize: 14,
                    marginTop: 4,
                    width: 268,
                }}
            >
                {activeCase.case_number}
            </Typography>
        </div>
    );
};

interface PaymentReceiptDialogProps {
    isDialogOpen: boolean;
    funeralHome: FuneralHomeUX | null;
    transactions: CaseTransactions;
    activeCase: GatherCaseUX;
    paymentId: number | null;
    closeDialog: () => void;
    paymentMethodName: (method: PaymentMethod | null, mode: PaymentMode | null) => string;
    zIndex: number;
    sentNotification: boolean;
    isRestricted: boolean;
}

const PaymentReceiptDialog = ({
    isDialogOpen,
    funeralHome,
    transactions,
    activeCase,
    paymentId,
    isRestricted,
    sentNotification,
    zIndex,
    closeDialog,
}: PaymentReceiptDialogProps) => {
    const classes = useStyles();
    const [payment, printRef, handlers] = useTransactionDetails({
        paymentId,
        transactions,
        sentNotification,
        activeCase,
        transactionType: 'PAYMENT',
    });

    return (
        <Dialog
            open={isDialogOpen}
            onClose={() => closeDialog()}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            className={classes.cashDialog}
            classes={{
                paper: classes.dialogPaper,
                root: classes.dRoot,
            }}
            hideBackdrop={payment?.isPrintMode}
            style={{ zIndex }}
            maxWidth="xs"
        >
            <DialogContent className={classes.dialogContent}>
                {payment ? (
                    <Grid
                        xs={12}
                        item
                        id="print-receipt"
                        style={{
                            margin: 'auto',
                            textAlign: 'center',
                        }}
                        ref={printRef}
                    >
                        <PaymentHeader
                            caseNumber={activeCase.case_number ?? ''}
                            onClose={closeDialog}
                            isPrintMode={payment.isPrintMode}
                        />
                        <div
                            style={{
                                textAlign: 'center',
                                margin: (!payment.isPrintMode && '16px 0px 12px') || undefined,
                            }}
                        >
                            <FuneralHomeLogo logoSize={'large'} style={{ maxWidth: 280, maxHeight: 40 }} />
                        </div>
                        {funeralHome && (
                            <FuneralHomeDetails
                                funeralHome={funeralHome}
                                activeCase={activeCase}
                                isPrintMode={payment.isPrintMode}
                            />
                        )}
                        <div
                            className={classes.headerContent}
                            style={{
                                margin: 'auto 8px',
                                textAlign: 'center',
                            }}
                        >
                            <PaymentMethodIcon
                                paymentMethod={payment.method}
                                iconStyle={iconStyle}
                                classFillPrimary={GStyles.fillPrimary}
                            />

                            <Typography
                                style={{
                                    ...typographyStyle,
                                    fontSize: 24,
                                    lineHeight: '18px',
                                    textAlign: 'center',
                                    borderBottom: '1px solid',
                                    width: payment.isPrintMode ? 250 : undefined,
                                    paddingBottom: 10,
                                    margin: 'auto',
                                }}
                            >
                                Payment Recorded
                            </Typography>
                        </div>

                        <div
                            className={classes.cashDesc}
                            style={{
                                margin: '8px 0 16px',
                                textAlign: 'center',
                            }}
                        >
                            {payment.method === PaymentMethod.insurance && (
                                <>
                                    <Typography style={typographyStyle}>
                                        Insurance Company:&nbsp;
                                        <span style={{ fontWeight: 200 }}>{payment.payer_name || ''}</span>
                                    </Typography>
                                    <Typography style={typographyStyle}>
                                        Policy Number:&nbsp;
                                        <span style={{ fontWeight: 200 }}>{payment.external_id || ''}</span>
                                    </Typography>
                                </>
                            )}
                            <Typography style={typographyStyle}>
                                Payment Method:&nbsp;
                                <span style={{ fontWeight: 200, textTransform: 'capitalize' }}>
                                    {getPaymentMethodLabel(payment.method, payment.mode)}
                                </span>
                            </Typography>
                            <Tooltip
                                enterDelay={400}
                                placement="top"
                                title={!isRestricted && PaymentFieldToolTip.paymentSubtotal}
                            >
                                <Typography style={typographyStyle}>
                                    Payment Subtotal:&nbsp;
                                    <span style={{ fontWeight: 200 }}>{payment.amount}</span>
                                </Typography>
                            </Tooltip>
                            {payment.merchFee && (
                                <Tooltip
                                    enterDelay={400}
                                    placement="top"
                                    title={!isRestricted && PaymentFieldToolTip.paymentFee}
                                >
                                    <Typography style={typographyStyle}>
                                        Convenience Fee:&nbsp;
                                        <span style={{ fontWeight: 200 }}>{payment.merchFee}</span>
                                    </Typography>
                                </Tooltip>
                            )}
                            {payment.merchFee && (
                                <Tooltip
                                    enterDelay={400}
                                    placement="top"
                                    title={!isRestricted && PaymentFieldToolTip.paymentTotal}
                                >
                                    <Typography style={typographyStyle}>
                                        Payment Total:&nbsp;
                                        <span style={{ fontWeight: 200 }}>{payment.totalAmount}</span>
                                    </Typography>
                                </Tooltip>
                            )}
                            {payment.method === PaymentMethod.check && (
                                <Typography style={typographyStyle}>
                                    Check Number:&nbsp;
                                    <span style={{ fontWeight: 200 }}>{payment.external_id}</span>
                                </Typography>
                            )}
                            <Tooltip
                                enterDelay={400}
                                placement="top"
                                title={!isRestricted && PaymentFieldToolTip.paymentDate}
                            >
                                {payment.method === PaymentMethod.insurance ? (
                                    <Typography style={typographyStyle}>
                                        Recorded:&nbsp;
                                        <span style={{ fontWeight: 200 }}>{payment.paymentDate}</span>
                                    </Typography>
                                ) : (
                                    <Typography style={typographyStyle}>
                                        Payment Date:&nbsp;
                                        <span style={{ fontWeight: 200 }}>{payment.paymentDate}</span>
                                    </Typography>
                                )}
                            </Tooltip>
                            {payment.method !== PaymentMethod.insurance && (
                                <Tooltip
                                    enterDelay={400}
                                    placement="top"
                                    title={!isRestricted && PaymentFieldToolTip.paymentCreatedDate}
                                >
                                    <Typography style={typographyStyle}>
                                        Payment Created Date:&nbsp;
                                        <span style={{ fontWeight: 200 }}>{payment.createdDate}</span>
                                    </Typography>
                                </Tooltip>
                            )}
                        </div>

                        <div
                            className={classes.cashDesc}
                            style={{
                                margin: '8px 0 16px',
                                textAlign: 'center',
                            }}
                        >
                            <Typography style={typographyStyle}>
                                Payer:&nbsp;
                                <span style={{ fontWeight: 200 }}>{payment.payer_name}</span>
                            </Typography>
                            <Typography style={typographyStyle}>
                                Services Of:&nbsp;
                                <span style={{ fontWeight: 200 }}>
                                    {activeCase.fname} {activeCase.lname}
                                </span>
                            </Typography>
                            <Typography style={typographyStyle}>
                                {payment.fhUserTitle}:&nbsp;
                                <span style={{ fontWeight: 200 }}>
                                    {activeCase.assignee.fname} {activeCase.assignee.lname}
                                </span>
                            </Typography>
                        </div>

                        {payment.description && (
                            <Typography
                                color="primary"
                                style={{
                                    ...typographyStyle,
                                    fontWeight: 200,
                                    textAlign: 'center',
                                    width: 250,
                                    margin: 'auto',
                                    lineHeight: '18px',
                                    wordBreak: 'break-word',
                                }}
                            >
                                "{payment.description}"
                            </Typography>
                        )}

                        {!payment.isPrintMode && (
                            <Grid className={classes.bottomContent} item>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={handlers.printReceipt}
                                    className={GStyles.marginBottom5}
                                >
                                    <LocalPrintshopIcon />
                                    &nbsp;Print this Receipt
                                </Button>
                                {payment.showReceiptSentTo && !payment.isNonUser && (
                                    <>
                                        <Typography>
                                            This receipt was sent to {payment.payer_email || payment.payer_phone}
                                        </Typography>
                                        <Typography>on {payment.paymentDate}</Typography>
                                    </>
                                )}
                                {payment.isNonUser && (
                                    <>
                                        <Typography className={classes.noReceiptSent}>
                                            This receipt was not sent since the payer was not invited into Gather
                                        </Typography>
                                    </>
                                )}
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <LoadingSpinner size={20} containerClass={classes.spinnerBox} />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default PaymentReceiptDialog;

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    dRoot: {},
    clearIcon: {
        position: 'absolute',
        top: 40,
        right: 12,
        fontSize: 36,
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    cashDialog: {},
    bottomContent: {
        textAlign: 'center',
        marginTop: 30,
        '& p': {
            fontSize: 12,
            fontWeight: '200',
            color: theme.palette.primary.main,
            lineHeight: '14px',
            '&$noReceiptSent': {
                fontWeight: 300,
                fontSize: 10,
            },
        },
    },
    dialogPaper: {
        margin: 'auto',
        width: 300,
        boxShadow: `6px 0 4px -4px rgba(0,0,0,0.14), 
            -6px 0 4px -4px rgba(0,0,0,0.14)`,
        position: 'relative',
        padding: '20px 0px 16px 0px',
        background: 'transparent',
        maxHeight: 'calc(100% - 96px)',
        '&:before': {
            background: `linear-gradient(-45deg, #fff 16px, transparent 0), 
            linear-gradient(45deg, #fff 16px, transparent 0)`,
            backgroundPosition: 'left-bottom',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '20px 24px',
            content: '" "',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 20,
        },
        '&:after': {
            background: `linear-gradient(-45deg, transparent 16px, #fff 0), 
            linear-gradient(45deg, transparent 16px, #fff 0)`,
            backgroundPosition: 'left-bottom',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '20px 24px',
            content: '" "',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: 24,
        },

        '@media (min-width: 400px)': {
            width: 360,
        },
    },
    cashDesc: {
        '& p': {
            color: theme.palette.primary.main,
        },
    },
    dialogContent: {
        background: 'white',
        zIndex: 0,
        padding: '0 0px 12px !important',
        width: '100%',
        margin: ' auto',
        textAlign: 'center',
    },
    headerContent: {
        color: theme.palette.primary.main,
        '& p': {
            color: theme.palette.primary.main,
        },
        '& svg': {
            color: theme.palette.primary.main,
        },
    },
    cardContainer: {
        display: 'flex',
    },
    cardNumber: {
        margin: '4px 0px 0px 20px !important',
        color: theme.palette.primary.main,
        textAlign: 'left',
    },
    cardNumberPrint: {
        color: theme.palette.primary.main,
    },
    noReceiptSent: {},
    spinnerBox: {
        position: 'absolute',
        top: '50px',
        left: '20px',
        height: '100px',
    },
}));

export const typographyStyle = {
    fontFamily: 'system-ui, sans-serif',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '1.25em',
    margin: 0,
};

export const iconStyle = {
    fontSize: 88,
    width: '1em',
    height: '1em',
    display: 'inline-block',
    flexShrink: 0,
    fontFamily: 'Material Icons',
};

export const smallTyphographyStyle = {
    fontFamily: 'system-ui, sans-serif',
    fontSize: 10,
    width: '250px',
    margin: 'auto',
    fontWeight: 200,
};
