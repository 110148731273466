import { useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import classNames from 'classnames';
import { orderBy } from 'lodash';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import UndoIcon from '@mui/icons-material/Undo';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import RouteIcon from '@mui/icons-material/Route';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import WatchIcon from '@mui/icons-material/Watch';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { getDatesDiffDurations, getIntercomTargetProp, MomentDurations } from '../../../services';

import {
    AvatarUser,
    CaseTaskUX,
    GatherCaseUX,
    generateDeathCertificate,
    isChecklistQuestionsConfig,
    isMultipleChoiceConfig,
    isNoteConfig,
    isTextQuestionsConfig,
    isTrackingTab,
    TaskComponentType,
    TaskComponentUX,
    TaskTemplateSummary,
    TaskTemplateType,
    TaskType,
    TrackingStepType,
    TrackingTabsEnum,
    UserProfile,
} from '../../../shared/types';

import { ORANGE_COLOR } from '../../../constants/colorVariables';
import Id from './Id';
import Tracking from './Tracking';
import TaskBar from '../tasks/DetailedLayout/taskBar/TaskBar';
import { TaskBarListType } from '../tasks/DetailedLayout/taskBar/TaskOverviewBar';
import BodyTrackingTabs from './BodyTrackingTabs';
import CompleteStepDialog, { TrackingContentContext } from './dialogs/completeStepDialog/CompleteStep.dialog';
import OverviewForm from '../deathcertificate/forms/overviewForm/OverviewForm';
import { useGDispatch, useGSelector } from '../../../types';
import GCircularProgress from '../../common/GCircularProgress';
import {
    FamilyRoutePage,
    getDeathCertificateStatus,
    joinNameParts,
    RouteBuilder,
    tzWithFallback,
} from '../../../services';
import { getFullNameFromUser } from '../../../services';
import CaseBelongingList from './dialogs/completeStepDialog/CaseBelongingList';
import CaseFingerprints from './dialogs/completeStepDialog/components/caseFingerprints/CaseFingerprints';
import { populateDeathCertificateFromCase } from '../deathcertificate/DeathCertificate';

import FuneralHomeLogo from '../../common/FuneralHomeLogo';
import ChangeWorkflowAndCaseTypeDialog from '../tasks/DetailedLayout/ChangeWorkflowAndCaseType.dialog';
import { log } from '../../../logger';
import { TrackingStepIconLookup } from './dialogs/saveStepDialog/trackingStepIcon.list';
import SimpleImage from '../../common/SimpleImage';
import CaseFiles from '../../documentLibrary/CaseFiles';
import SelectActionStepDialog from './dialogs/allStepsDialog/SelectActionStep.dialog';
import ReorderTracking from './reorderSteps/ReorderTracking';
import makeGStyles from '../../../styles/makeGStyles';
import SkipStepDialog from './dialogs/SkipStepDialog';
import MoveLocationDialog from './dialogs/moveLocationDialog/MoveLocation.dialog';
import { createTaskFromTaskTemplate, updateTask } from '../../../actions/task/Task.action';
import useWindowEvent from '../../common/hooks/useWindowEvent';
import { setAppSnackbar } from '../../../actions/AppSnackbar.action';
import { AppRoute } from '../../../services';
import { KEEP_TRACK_TRADEMARK } from '../../../constants';
import GLink from '../../common/GLink';
import GatherDateTimePicker from '../../common/GDateTimePicker';

const useStyles = makeGStyles(
    (theme) => ({
        root: {
            padding: '16px 0',
            maxWidth: 680,
            margin: '0 auto',
            minWidth: 300,
            '@media (min-width: 600px)': {
                padding: 16,
            },
        },
        tabsContent: {
            margin: '12px auto',
            minHeight: 260,
        },
        addStepContainer: {
            margin: '0 auto',
            width: 'fit-content',
        },
        addTask: {
            textAlign: 'center',
            border: 'dashed',
            borderWidth: 'thin',
            fontSize: 13,
            borderColor: ORANGE_COLOR,
            color: ORANGE_COLOR,
            margin: '16px auto',
            minWidth: 275,
            '&:hover': {
                border: `1px solid ${ORANGE_COLOR}`,
            },
            '@media (min-width: 370px)': {
                fontSize: 15,
            },
        },
        margin0: {
            margin: '0px auto',
        },

        editModeTabsClass: {
            width: 'fit-content',
            margin: 0,
            '@media (min-width: 768px)': {
                marginLeft: 20,
            },
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
        },
        finishEditingBtn: {
            backgroundColor: ORANGE_COLOR,
            color: theme.palette.common.white,
            maxHeight: 36,
            '&:hover': {
                backgroundColor: ORANGE_COLOR,
            },
        },
        progressContainer: {
            position: 'relative',
            height: 26,
            width: 26,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        circularProgress: {
            width: '36px !important',
            height: '36px !important',
            color: 'inherit',
        },
        percentComplete: {
            position: 'absolute',
            fontSize: 12,
            top: '50%',
            transform: 'translateY(-50%)',
            '& svg': {
                width: 28,
                height: 28,
                display: 'flex',
            },
        },
        footerText: {
            fontSize: 12,
            display: 'flex',
            alignItems: 'center',
            '@media (min-width: 400px)': {
                fontSize: 'min(3.25vw, 16px)',
            },
        },
        actionBtn: {
            padding: 8,
            rowGap: '8px',
            columnGap: '20px',
            alignItems: 'center',
        },
        taskOverview: {
            '@media (min-width: 768px)': {
                width: '100%',
            },
        },
        EditSize: {
            fontSize: 14,
            marginRight: 2,
            marginBottom: 1,
            '@media (min-width: 480px)': {
                fontSize: 16,
            },
        },
        footerTextPointer: {
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        minHeight600: {
            minHeight: 600,
        },
        overviewTab: {
            padding: '0 12px',
            [theme.breakpoints.up(600)]: {
                padding: 0,
            },
        },
    }),
    { name: 'BodyTrackingContent' },
);

export interface TrackingTabs {
    value: TrackingTabsEnum;
    icon?: JSX.Element;
    count?: number;
    footerLabel?: string;
}

export enum TrackingItemStatus {
    completed = 'Completed',
    pending = 'Pending',
    skipped = 'Skipped',
}

export enum PendingSteps {
    addNote = 'Add a Note',
}

export enum TrackingItemType {
    normal_step = 'normal_step',
    move_step = 'move_step',
    initialize_step = 'initialize_step',
    finalize_step = 'finalize_step',
    note = 'note',
    workflow_update = 'workflow_update',
    add_action = 'add_action',
    add_medallion = 'add_medallion',
}

export interface TrackingItem {
    stepId?: number;
    key: string;
    icon?: JSX.Element;
    iconImage?: JSX.Element;
    label: JSX.Element | string;
    secondaryText: string[] | null;
    answers?: TaskComponentUX[];
    finishedBy: AvatarUser | null;
    finishedTime: moment.Moment | null;
    performedBy: AvatarUser | null;
    durationsInLocation: MomentDurations | null;
    signatureUrl: string | null;
    inLocation?: boolean;
    status: TrackingItemStatus;
    itemType: TrackingItemType;
    completedByTime?: Date | null;
    link?: AppRoute;
    onClick?: () => void;
    isVisibleToFamily: boolean;
}

interface Props {
    userData: UserProfile;
    selectedCase: GatherCaseUX;
    zIndex: number;
    isFamilyView: boolean;
    urlTab: string;
}

const BodyTrackingContent = (props: Props) => {
    const { selectedCase, userData, urlTab, zIndex, isFamilyView } = props;

    const classes = useStyles();
    const dispatch = useGDispatch();

    const helpers = useGSelector(({ casesState }) => casesState.helpers);
    const trackingSteps = useGSelector(({ tasksState }) => tasksState.trackingSteps);
    const dcTask = useGSelector(
        ({ tasksState }) =>
            tasksState.checklistTasks.find((t) => t.template_type === TaskTemplateType.death_certificate) || null,
    );
    const caseNotes = useGSelector(({ noteState }) => noteState.caseNotes);

    const activeWorkflow = useGSelector(({ workflowState }) => workflowState.activeWorkflow);

    const belongingsCount = useGSelector(({ casesState }) => casesState.belongings?.length || 0);
    const printsCount = useGSelector(({ casesState }) => casesState.activeCaseFingerprints.prints.length);
    const idPhotosCount = useGSelector(({ casesState }) => casesState.caseIdPhotos.length);
    const caseDocsCount = useGSelector(({ caseDocState }) => caseDocState.docs.length);

    const workflowChangeHistory = useGSelector(({ casesState }) => casesState.workflowChangeHistory);

    const [selectedTab, setSelectedTab] = useState<TrackingTabsEnum>(
        isTrackingTab(urlTab) ? urlTab : TrackingTabsEnum.Track,
    );
    const [isBulkEditMode, setIsBulkEditMode] = useState(false);
    const [isCompleteStepDialogOpen, setIsCompleteStepDialogOpen] = useState(false);
    const [isWorkflowSelectorDialogOpen, setIsWorkflowSelectorDialogOpen] = useState(false);
    const [activeStepId, setActiveStepId] = useState<number | null>(null);
    const [isAllStepsDialogOpen, setIsAllStepsDialogOpen] = useState(false);
    const [isSkipStepDialogOpen, setIsSkipStepDialogOpen] = useState(false);
    const [moveLocationDialogOpen, setMoveLocationDialogOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [dateTimeDialogOpen, setDateTimeDialogOpen] = useState(false);

    useWindowEvent('resize', () => setWindowWidth(window.innerWidth));

    useEffect(() => {
        if (isTrackingTab(urlTab)) {
            setSelectedTab(urlTab);
        }
    }, [urlTab]);

    const updateStepDueDate = (date: Moment | null) => {
        if (!activeStep) {
            return;
        }

        dispatch(
            updateTask(activeStep.id, { complete_by_time: date ? date.toDate() : null },
                selectedCase.uuid, activeStep.template_type),
        );
    };

    const vitalsIcon = useMemo(() => {
        const dcStatus = getDeathCertificateStatus(selectedCase);
        const dcPercentComplete = dcStatus && dcStatus.percentComplete;
        const isVitalsTab = selectedTab === TrackingTabsEnum.Vitals;

        return (
            <Grid item className={classes.progressContainer}>
                <GCircularProgress
                    className={classNames(classes.circularProgress, isVitalsTab && classes.colorPrimary)}
                    variant="determinate"
                    color="inherit"
                    value={dcPercentComplete || 0}
                />
                <Typography color="inherit" align="center" className={classes.percentComplete}>
                    {dcPercentComplete === 100 ? (
                        <CheckIcon fontSize="inherit" color="inherit" />
                    ) : (
                        `${dcPercentComplete}%`
                    )}
                </Typography>
            </Grid>
        );
    }, [classes, selectedTab, selectedCase]);

    const durationsInLocationLookup = useMemo(() => {
        const durInLocationLookup: Record<number, { durations: MomentDurations; isCurrent: boolean }> = {};
        for (const step of trackingSteps) {
            if (
                step.type === TaskType.tracking_step &&
                step.tracking_step_type === TrackingStepType.move &&
                step.marked_complete_time !== null
            ) {
                const durations = getDatesDiffDurations({
                    older: step.marked_complete_time,
                    newer: step.exited_location_time ?? moment(),
                });
                durInLocationLookup[step.id] = {
                    durations,
                    isCurrent: selectedCase.last_move_task_id === step.id,
                };
            }
        }
        return durInLocationLookup;
    }, [selectedCase.last_move_task_id, trackingSteps]);

    const trackingItems = useMemo(() => {
        const timezone = tzWithFallback(selectedCase.funeral_home.timezone);

        const trackingItemList: TrackingItem[] = [];
        for (const step of trackingSteps) {
            const isInitialize = step.tracking_step_type === TrackingStepType.initialize;
            const isFinalize = step.tracking_step_type === TrackingStepType.finalize;
            const isMarkedComplete = step.marked_complete_time !== null;
            const isSkipped = step.skipped_time !== null;

            let label: string | JSX.Element = '';
            if (isInitialize) {
                label = <>{KEEP_TRACK_TRADEMARK} Assigned</>;
            } else if (isMarkedComplete) {
                if (isFinalize) {
                    label = <>{KEEP_TRACK_TRADEMARK} Finalized</>;
                } else {
                    label = step.past_tense_title || step.title;
                }
            } else {
                label = step.title;
            }

            let finishedBy: AvatarUser | null = null;
            let finishedTime: moment.Moment | null = null;
            let status: TrackingItemStatus = TrackingItemStatus.pending;
            if (isMarkedComplete) {
                status = TrackingItemStatus.completed;
                finishedBy = step.marked_complete_by;
                finishedTime = step.marked_complete_time ? moment(step.marked_complete_time).tz(timezone) : null;
            } else if (isSkipped) {
                status = TrackingItemStatus.skipped;
                finishedBy = step.skipped_by;
                finishedTime = step.skipped_time ? moment(step.skipped_time).tz(timezone) : null;
            }
            const performedBy = step.performed_by;
            const signatureUrl = step.signature_url;

            let itemType: TrackingItemType = TrackingItemType.normal_step;
            if (step.tracking_step_type === TrackingStepType.initialize) {
                itemType = TrackingItemType.initialize_step;
            } else if (step.tracking_step_type === TrackingStepType.normal) {
                itemType = TrackingItemType.normal_step;
            } else if (step.tracking_step_type === TrackingStepType.move) {
                itemType = TrackingItemType.move_step;
            } else if (step.tracking_step_type === TrackingStepType.finalize) {
                itemType = TrackingItemType.finalize_step;
            } else {
                log.warn('Unknown TrackingStepType', { step });
            }

            let icon: JSX.Element | undefined = undefined;
            if (step.icon) {
                const Icon = TrackingStepIconLookup[step.icon];
                if (!Icon) {
                    log.warn('Unsupported tracking step icon', { step });
                } else {
                    icon = <Icon color="inherit" />;
                }
            }

            let iconImage: JSX.Element | undefined = undefined;
            const isMoveLocationStep = step.tracking_step_type === TrackingStepType.move && step.task_location_id;
            if (isMoveLocationStep) {
                if (step.task_location?.cover_photo) {
                    iconImage = (
                        <SimpleImage
                            width={52}
                            height={52}
                            publicId={step.task_location.cover_photo}
                            cropMode="fill"
                            altText={`Photo of ${step.task_location.name}`}
                        />
                    );
                } else if (step.task_location?.cover_fallback_url) {
                    iconImage = (
                        <img
                            width={52}
                            height={52}
                            src={step.task_location.cover_fallback_url}
                            style={{ objectFit: 'cover' }}
                            alt={`Photo of ${step.task_location.name}`}
                        />
                    );
                }
            }

            const onClick = () =>
                isSkipped
                    ? openSkipStepDialog(step.id)
                    : isMarkedComplete && isMoveLocationStep
                    ? openMoveLocationDialog(step.id)
                    : openCompleteStepDialog(step.id);

            const stepDurationsObj = durationsInLocationLookup[step.id] ?? null;
            const durationsInLocation = stepDurationsObj?.durations;
            const inLocation: boolean | undefined = stepDurationsObj?.isCurrent;
            let notes: string[] = step.components.reduce<string[]>((noteList, component) => {
                if (
                    component.type === TaskComponentType.note &&
                    isNoteConfig(component.configuration) &&
                    component.configuration.answer
                ) {
                    return [...noteList, component.configuration.answer];
                }
                return noteList;
            }, []);
            if (step.note) {
                notes.push(step.note);
            }

            let answers: TaskComponentUX[] = [];
            // Get checklist components and answers
            const completedCheckListComponents = step.components.filter(
                (component) => (component.type === TaskComponentType.checklist_questions
                    && isChecklistQuestionsConfig(component.configuration)
                    && step.marked_complete_time !== null)
            );

            // Get text components and answers
            const completedTextComponents = step.components.filter(
                (component) => (component.type === TaskComponentType.text_questions
                    && isTextQuestionsConfig(component.configuration)
                    && step.marked_complete_time !== null)
            );

            // Get multiple choice components and answers
            const completedMultipleChoiceComponents = step.components.filter(
                (component) => (component.type === TaskComponentType.multiple_choice_question
                    && isMultipleChoiceConfig(component.configuration)
                    && step.marked_complete_time !== null)
            );

            answers = [
                ...completedCheckListComponents,
                ...completedTextComponents,
                ...completedMultipleChoiceComponents
            ];

            trackingItemList.push({
                stepId: step.id,
                key: `step_${step.id}`,
                label,
                secondaryText: notes,
                answers: answers,
                finishedBy,
                finishedTime,
                performedBy,
                durationsInLocation,
                signatureUrl,
                inLocation,
                status,
                itemType,
                icon,
                iconImage,
                onClick,
                isVisibleToFamily: step.visible_to_family,
                completedByTime: step.complete_by_time,
            });
        }

        for (const caseNote of caseNotes) {
            const updatedByName =
                windowWidth < 768 ? caseNote.updated_by_user.fname : joinNameParts(caseNote.updated_by_user);

            trackingItemList.push({
                key: `casenote_${caseNote.id}`,
                label: `Note Added ${caseNote.updated_by_user ? `by ${updatedByName}` : ''}`,
                secondaryText: [caseNote.note],
                finishedBy: caseNote.updated_by_user,
                finishedTime: moment(caseNote.updated_time).tz(timezone),
                performedBy: null,
                durationsInLocation: null,
                signatureUrl: null,
                status: TrackingItemStatus.completed,
                itemType: TrackingItemType.note,
                icon: <FiberManualRecordIcon color="success" />,
                isVisibleToFamily: true,
            });
        }

        for (const workflowChange of workflowChangeHistory) {
            let secondaryText = '';
            const newWorkflowName = workflowChange.newWorkflow?.name;
            const oldWorkflowName = workflowChange.oldWorkflow?.name;
            if (oldWorkflowName) {
                secondaryText = `From "${oldWorkflowName}" to "${newWorkflowName ?? 'Unknown'}"`;
            } else if (newWorkflowName) {
                secondaryText = `To "${newWorkflowName}"`;
            } else {
                log.warn('Unknown workflow change somehow', { workflowChangeHistory });
                secondaryText = 'Unknown Change';
            }

            trackingItemList.push({
                key: `workflowadjusted_${workflowChange.caseLogId}`,
                label: 'Workflow Adjusted',
                secondaryText: [secondaryText],
                finishedBy: workflowChange.changedBy,
                finishedTime: moment(workflowChange.changedTime).tz(timezone),
                performedBy: null,
                durationsInLocation: null,
                signatureUrl: null,
                status: TrackingItemStatus.completed,
                itemType: TrackingItemType.workflow_update,
                icon: <RouteIcon />,
                isVisibleToFamily: true,
            });
        }

        const orderedTrackingItems = orderBy(trackingItemList, (i) => i.finishedTime);

        return orderedTrackingItems;
    }, [
        caseNotes,
        durationsInLocationLookup,
        workflowChangeHistory,
        selectedCase.funeral_home.timezone,
        trackingSteps,
        windowWidth,
    ]);

    const renderTabs = (tabs: TrackingTabs[], tabClass?: string) => {
        return (
            <BodyTrackingTabs
                selectedTab={selectedTab}
                isBulkEditMode={isBulkEditMode}
                tabs={tabs}
                caseName={selectedCase.name}
                funeralHomeKey={selectedCase.funeral_home.key}
                className={tabClass}
            />
        );
    };
    const openMoveLocationDialog = (stepId: number) => {
        setActiveStepId(stepId);
        setMoveLocationDialogOpen(true);
    };

    const closeMoveLocationDialog = () => {
        setActiveStepId(null);
        setMoveLocationDialogOpen(false);
    };

    const openWorkflowSelectorDialog = () => {
        setIsWorkflowSelectorDialogOpen(true);
    };

    const closeWorkflowSelectorDialog = () => {
        setIsWorkflowSelectorDialogOpen(false);
    };

    const openAllStepsDialog = () => {
        setIsAllStepsDialogOpen(true);
    };

    const closeAllStepsDialog = () => {
        setIsAllStepsDialogOpen(false);
    };

    const openSkipStepDialog = (stepId: number) => {
        setActiveStepId(stepId);
        setIsSkipStepDialogOpen(true);
    };

    const closeSkipStepDialog = () => {
        setActiveStepId(null);
        setIsSkipStepDialogOpen(false);
    };

    const TABS: TrackingTabs[] = useMemo(
        () => [
            {
                value: TrackingTabsEnum.Track,
                icon: <QrCode2Icon />,
                footerLabel: `Click here to reorder pending tasks`,
            },
            {
                value: TrackingTabsEnum.Files,
                icon: <FileCopyIcon color="inherit" />,
                count: caseDocsCount,
                footerLabel: `Securely store ${selectedCase.fname}'s case files`,
            },
            {
                value: TrackingTabsEnum.Prints,
                icon: <FingerprintIcon color="inherit" />,
                count: printsCount,
                footerLabel: `Record and preserve ${selectedCase.fname}'s fingerprints`,
            },
            {
                value: TrackingTabsEnum.Items,
                icon: <WatchIcon />,
                count: belongingsCount,
                footerLabel: `Record and Track ${selectedCase.fname}'s personal belongings`,
            },
            {
                value: TrackingTabsEnum.ID,
                icon: <AssignmentIndIcon />,
                count: idPhotosCount,
                footerLabel: `Preserve a secure death photo of ${selectedCase.fname}`,
            },
            {
                value: TrackingTabsEnum.Vitals,
                icon: vitalsIcon,
                footerLabel: `Click here to edit ${selectedCase.fname}'s vitals`,
            },
        ],
        [caseDocsCount, printsCount, belongingsCount, idPhotosCount, selectedCase.fname, vitalsIcon],
    );

    const activeStep: CaseTaskUX | null = useMemo(() => {
        return trackingSteps.find((step) => step.id === activeStepId) ?? null;
    }, [trackingSteps, activeStepId]);

    const openVitalEdit = (value: string | undefined) => {
        return (
            <GLink
                to={RouteBuilder.FamilyPage({
                    caseName: selectedCase.name,
                    funeralHomeKey: selectedCase.funeral_home.key,
                    page: FamilyRoutePage.QUESTIONS,
                })}
                linkClass={classes.colorWhite}
            >
                {value}
            </GLink>
        );
    };

    const toggleBulkEdit = () => {
        setIsBulkEditMode(!isBulkEditMode);
        if (!isBulkEditMode) {
            setSelectedTab(TrackingTabsEnum.Track);
        }
    };

    const openCompleteStepDialog = (stepId: number) => {
        setActiveStepId(stepId);
        setIsCompleteStepDialogOpen(true);
    };

    const closeCompleteStepDialog = () => {
        setActiveStepId(null);
        setIsCompleteStepDialogOpen(false);
    };

    const bulkEditModeMenuOnClick = (_activeStep: CaseTaskUX) => {
        if (
            _activeStep.marked_complete_time !== null &&
            _activeStep.tracking_step_type === TrackingStepType.move &&
            _activeStep.task_location_id
        ) {
            openMoveLocationDialog(_activeStep.id);
        } else {
            openCompleteStepDialog(_activeStep.id);
        }
    };

    const handleAddTaskTemplate = async (taskTemplate: TaskTemplateSummary) => {
        const tasks = await dispatch(createTaskFromTaskTemplate(taskTemplate.id, selectedCase.uuid));
        if (tasks?.length) {
            dispatch(setAppSnackbar('Tracking step added successfully', 'success'));
        }
    };

    const handleDueDateClick = useCallback((stepId: number) => {
        setActiveStepId(stepId);
        setDateTimeDialogOpen(true);
    }, []);

    const editModeTabs = useMemo(() => TABS.filter((e) => e.value === selectedTab), [TABS, selectedTab]);

    // only count completed OR non-skipped steps that are NOT move steps
    const stepsForCount = useMemo(
        () =>
            trackingSteps.filter(
                (step) =>
                    step.tracking_step_type !== TrackingStepType.move &&
                    (step.marked_complete_time || !step.skipped_time),
            ),
        [trackingSteps],
    );
    const completedStepsForCount = useMemo(
        () => stepsForCount.filter((step) => step.marked_complete_time),
        [stepsForCount],
    );
    const completedStepsCount = completedStepsForCount.length;
    const totalStepsCount: number = stepsForCount.length;
    const stepsCompletedPct = Math.round((completedStepsCount / totalStepsCount) * 100);
    const isItemsTabSelected =
        selectedTab === TrackingTabsEnum.Items ||
        selectedTab === TrackingTabsEnum.ID ||
        selectedTab === TrackingTabsEnum.Prints ||
        selectedTab === TrackingTabsEnum.Files;
    const vitalStep = selectedTab === TrackingTabsEnum.Vitals;

    const availableSteps = useMemo(
        () =>
            activeWorkflow?.available_tracking_steps.filter(
                (s) =>
                    s.tracking_step_type !== TrackingStepType.initialize &&
                    s.tracking_step_type !== TrackingStepType.finalize,
            ),
        [activeWorkflow?.available_tracking_steps],
    );

    const isTrackingInitialized = !!selectedCase.keeptrack_assigned_time;

    const renderTabContent = () => {
        const deathCertificate = selectedCase.death_certificate;
        const dc = deathCertificate || generateDeathCertificate();
        populateDeathCertificateFromCase({
            deathCertificate: dc,
            funeralHome: selectedCase.funeral_home,
        });

        switch (selectedTab) {
            case TrackingTabsEnum.Track:
                if (isBulkEditMode) {
                    return (
                        <ReorderTracking
                            trackingSteps={trackingSteps}
                            selectedCase={selectedCase}
                            openSkipStepDialog={openSkipStepDialog}
                            onClick={bulkEditModeMenuOnClick}
                            onDueDateClick={handleDueDateClick}
                        />
                    );
                } else {
                    return (
                        <Tracking
                            userData={userData}
                            trackingItems={trackingItems}
                            selectedCase={selectedCase}
                            isFamilyView={isFamilyView}
                            openWorkflowSelectorDialog={openWorkflowSelectorDialog}
                            toggleBulkEditMode={toggleBulkEdit}
                            openAllStepsDialog={openAllStepsDialog}
                            openSkipStepDialog={openSkipStepDialog}
                            onDueDateClick={handleDueDateClick}
                        />
                    );
                }

            case TrackingTabsEnum.Prints:
                return (
                    <CaseFingerprints
                        caseUuid={selectedCase.uuid}
                        caseFname={selectedCase.fname}
                        zIndex={1320}
                        context={TrackingContentContext.standalone}
                        fingerprintText="Please use an ink pad on clean white paper."
                        intercomTargetProp={`TrackingPage-PrintsTab-UseInkAndWhitePaperText`}
                        intercomTargetPropPrintItem={`TrackingPage-PrintsTab-PrintItem`}
                        intercomTargetPropAddPrint={`TrackingPage-PrintsTab-AddPrint`}
                    />
                );

            case TrackingTabsEnum.Files:
                return (
                    <div>
                        <CaseFiles
                            activeCase={selectedCase}
                            zIndex={1320}
                            compactView={true}
                            intercomTargetProp={`TrackingPage-FilesTab-UploadFileButton`}
                            intercomTargetPropDocItem={`TrackingPage-FilesTab`}
                            intercomTargetPropDocMenu={`TrackingPage-FilesTab-DocMenu`}
                        />
                    </div>
                );

            case TrackingTabsEnum.Items:
                return (
                    <Grid item xs={12}>
                        <CaseBelongingList
                            context={TrackingContentContext.standalone}
                            zIndex={1320}
                            caseFname={selectedCase.fname}
                            caseUuid={selectedCase.uuid}
                            userFullName={getFullNameFromUser(userData)}
                        />
                    </Grid>
                );

            case TrackingTabsEnum.Vitals:
                if (!selectedCase.death_certificate) {
                    return null;
                }

                return (
                    <Grid item xs={12} className={classes.overviewTab}>
                        <OverviewForm
                            helpers={helpers}
                            dc={dc}
                            dcTask={dcTask}
                            user={userData}
                            activeCase={selectedCase}
                            direction="column"
                            hideCaseNumber
                            showEditButton
                            intercomTargetProp={`TrackingPage-VitalsTab-DCOverview`}
                            intercomTargetPropEditDc={`TrackingPage-VitalsTab-EditDCButton`}
                        />
                    </Grid>
                );

            case TrackingTabsEnum.ID:
            default:
                return <Id selectedCase={selectedCase} zIndex={zIndex} isFamilyView={isFamilyView} />;
        }
    };

    return (
        <>
            <Grid item xs={12} className={classes.root}>
                <TaskBar
                    taskOverviewClass={classes.taskOverview}
                    caseFirstName={selectedCase.fname}
                    taskCompletionValue={completedStepsCount}
                    taskName="Tracking Steps"
                    taskcompletedPct={stepsCompletedPct}
                    tasksLength={totalStepsCount}
                    listType={TaskBarListType.steps}
                    onBulkEditClick={() => !vitalStep && toggleBulkEdit()}
                    isBulkEditMode={isBulkEditMode}
                    customText={!isTrackingInitialized ? 'Not Started' : undefined}
                    footerText={
                        <span
                            className={classNames(classes.footerText, !isItemsTabSelected && classes.footerTextPointer)}
                            {...getIntercomTargetProp(`TrackingPage-Footer-${editModeTabs[0].value}`)}
                        >
                            {!isItemsTabSelected && <EditIcon className={classes.EditSize} />}
                            {vitalStep ? openVitalEdit(editModeTabs[0].footerLabel) : editModeTabs[0].footerLabel}
                        </span>
                    }
                    disableFooterTextClick={isItemsTabSelected}
                    intercomTargetProp={`TrackingPage-TrackingStepsContainerHeader`}
                >
                    {!isBulkEditMode && renderTabs(TABS)}
                    {isBulkEditMode && (
                        <Grid item xs={12} className={classes.displayFlex}>
                            {renderTabs(editModeTabs, classes.editModeTabsClass)}

                            <Grid container justifyContent="center" className={classes.actionBtn}>
                                <Button
                                    variant="contained"
                                    className={classes.finishEditingBtn}
                                    size="small"
                                    onClick={openWorkflowSelectorDialog}
                                >
                                    <ClearAllIcon />
                                    &nbsp; Change WorkFlow
                                </Button>

                                <Button
                                    variant="contained"
                                    className={classes.finishEditingBtn}
                                    size="small"
                                    onClick={toggleBulkEdit}
                                >
                                    <UndoIcon />
                                    &nbsp; Click to finish
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        className={classNames(
                            classes.tabsContent,
                            isBulkEditMode && !isTrackingInitialized && classes.flexColumnCentered,
                            !isTrackingInitialized && !isBulkEditMode && classes.minHeight600,
                            (isBulkEditMode || selectedTab === TrackingTabsEnum.Vitals) && classes.margin0,
                        )}
                    >
                        {renderTabContent()}
                    </Grid>
                </TaskBar>

                {selectedTab === TrackingTabsEnum.Track && isBulkEditMode && isTrackingInitialized && (
                    <Grid item xs={11} className={classes.addStepContainer}>
                        <Button
                            variant="outlined"
                            className={classes.addTask}
                            fullWidth
                            size="large"
                            onClick={openAllStepsDialog}
                            {...getIntercomTargetProp(`TrackingPage-TrackTab-BulkEdit-AddActionToWorkFlowButton`)}
                        >
                            + Add an action to {selectedCase.fname}'s workflow
                        </Button>
                    </Grid>
                )}

                <Grid item xs={12} className={classNames(classes.marginTop40, classes.textCenter)}>
                    <FuneralHomeLogo logoSize="large" />
                </Grid>
            </Grid>

            <CompleteStepDialog
                isOpen={isCompleteStepDialogOpen}
                closeDialog={closeCompleteStepDialog}
                activeStepId={activeStepId}
                onChangeActiveStep={setActiveStepId}
                selectedCase={selectedCase}
                zIndex={1320}
            />

            {activeStep?.task_location && (
                <MoveLocationDialog
                    fhName={selectedCase.funeral_home.name}
                    existingStep={activeStep}
                    zIndex={1320}
                    caseUuid={selectedCase.uuid}
                    caseFName={selectedCase.fname}
                    open={moveLocationDialogOpen}
                    onClose={closeMoveLocationDialog}
                    user={userData}
                    currentLocationSummary={null}
                    currentLocation={null}
                    targetLocation={activeStep.task_location}
                />
            )}

            <ChangeWorkflowAndCaseTypeDialog
                gatherCase={selectedCase}
                funeralHome={selectedCase.funeral_home}
                isOpen={isWorkflowSelectorDialogOpen}
                onClose={closeWorkflowSelectorDialog}
                zIndex={1320}
            />
            <SelectActionStepDialog
                zIndex={1320}
                isOpen={isAllStepsDialogOpen}
                closeDialog={closeAllStepsDialog}
                selectedSteps={activeWorkflow?.selected_tracking_steps || []}
                sortSelectedStepsOnEnd
                availableSteps={availableSteps ?? []}
                onAddExisting={handleAddTaskTemplate}
                funeralHomeId={selectedCase.funeral_home_id}
                hideDelete
            />

            {activeStep && (
                <SkipStepDialog
                    isOpen={isSkipStepDialogOpen}
                    user={userData}
                    selectedCase={selectedCase}
                    zIndex={zIndex + 1}
                    activeStep={activeStep}
                    closeDialog={closeSkipStepDialog}
                />
            )}

            {activeStep && (
                <GatherDateTimePicker
                    key={activeStep.id}
                    zIndex={zIndex + 1}
                    isDialogOpen={dateTimeDialogOpen}
                    defaultTimestamp={activeStep.complete_by_time || undefined}
                    timezone={selectedCase.funeral_home.timezone || undefined}
                    onClose={() => setDateTimeDialogOpen(false)}
                    onUpdate={updateStepDueDate}
                    isReadOnly={false}
                />
            )}
        </>
    );
};

export default BodyTrackingContent;
