import { useEffect, useMemo, useRef, useState } from 'react';
import {
    CaseTransactions,
    GatherCaseUX,
    Transaction,
    getDisplayTitleForFHUser,
    PaymentMethod,
    PaymentMode,
} from '../../../../shared/types';
import { formatDateString } from '../../../../services';

interface UsePaymentProps {
    paymentId: number | null;
    transactions: CaseTransactions;
    sentNotification: boolean;
    activeCase: GatherCaseUX;
    transactionType: Transaction['type'];
}

interface PaymentProperties extends Transaction {
    method: PaymentMethod | null;
    mode: PaymentMode | null;
    paymentDate: string;
    createdDate: string;
    amount: string;
    merchFee: string | null;
    totalAmount: string;
    isNonUser: boolean;
    showReceiptSentTo: boolean;
    fhUserTitle: string;
    isLoading: boolean;
    isPrintMode: boolean;
}

interface TransactionDetailsHandlers {
    printReceipt: () => void;
}

const materialIconStyles = `       
        <style>
        @font-face {
            font-family: 'Material Icons';
            font-style: normal;
            font-weight: 400;
            src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
        }

        .material-icons {
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
        }
        </style>`;

const useTransactionDetails = ({
    paymentId,
    transactions,
    sentNotification,
    activeCase,
    transactionType,
}: UsePaymentProps): [PaymentProperties | null, React.RefObject<HTMLDivElement>, TransactionDetailsHandlers] => {
    const [isPrintMode, setIsPrintMode] = useState(false);
    const printRef = useRef<HTMLDivElement>(null);

    const transaction = useMemo(() => {
        return transactions.transactions.find((t) => t.payment_id === paymentId && t.type === transactionType) ?? null;
    }, [paymentId, transactions, transactionType]);

    const payment: PaymentProperties | null = useMemo(() => {
        if (!transaction) {
            return null;
        }

        return {
            ...transaction,
            method: transaction.method ?? null,
            mode: transaction.mode ?? null,
            paymentDate: formatDateString(String(transaction.payment_date)),
            createdDate: formatDateString(String(transaction.date)),
            amount: Transaction.dollarFormatUnsigned(transaction, true) ?? '',
            merchFee: transaction.merch_fee !== null ? Transaction.dollarFormatNumber(transaction.merch_fee) : null,
            totalAmount: transaction ? Transaction.dollarFormatUnsigned(transaction) : '',
            isNonUser: !transaction.payer_user_id,
            showReceiptSentTo: Boolean(sentNotification && (transaction.payer_email ?? transaction.payer_phone)),
            fhUserTitle: getDisplayTitleForFHUser(activeCase?.assignee, activeCase?.funeral_home_id),
            isLoading: transactions.isLoading,
            isPrintMode,
        };
    }, [transaction, transactions, activeCase, sentNotification, isPrintMode]);

    useEffect(() => {
        if (isPrintMode) {
            handlePrint();
        }
    }, [isPrintMode]);

    const handlePrint = () => {
        const printContainer = document.getElementById('print-container');
        if (!printRef.current || !printContainer) {
            return;
        }

        printContainer.innerHTML = printRef.current.innerHTML + materialIconStyles;
        window.print();
        setIsPrintMode(false);
    };

    const handlers = useMemo(
        (): TransactionDetailsHandlers => ({
            printReceipt: () => setIsPrintMode(true),
        }),
        [],
    );

    return [payment, printRef, handlers];
};

export default useTransactionDetails;
