import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import PagesIcon from '@mui/icons-material/Pages';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PublicIcon from '@mui/icons-material/Public';

import Badge from './Badge';

import { ProductUX, ProductContractItemUX, ProductItem, GPLContext, FeatureKey } from '../../../../shared/types';
import { getDisplayPrice } from '../../../../shared/goods_and_services/pricing';
import PriceWidget from '../widgets/PriceWidget';
import { getContractItemName } from '../../../../shared/goods_and_services/utils';
import { Breakpoint, Theme } from '@mui/material/styles';
import { StyleRulesCallback, WithStyles } from '@mui/styles';
import withGStyles from '../../../../styles/WithGStyles';
import { GLOBAL_STYLED_PROPS } from '../../../../styles';
import ProductItemCardBlockBase, { CardBadge } from './ProductItemCardBlockBase';
import useFeatureEnabled from '../../../common/hooks/useFeatureEnabled';
import { Permission } from '../../../../shared/types/permissions';
import { TOOLTIPS } from '../../../../constants';
import { usePermissionEnabled } from '../../../common/hooks/usePermissionEnabled';

interface Props {
    canUserEditContract: boolean;
    productItem: ProductItem;
    caseFirstName: string;
    isShowPrices: boolean;
    width: Breakpoint;
    isBackOffice?: boolean;
    context: GPLContext;
    onAddContractItem?: (product: ProductUX) => void;
    onRemoveContractItem?: (contractItem: ProductContractItemUX) => void;
    onEditClick?: (productId: number) => void;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    heading: {
        margin: 'auto 20px auto 12px',
        textTransform: 'uppercase',
        fontSize: 16,
        fontWeight: 200,
        textAlign: 'center',
        '@media (min-width: 600px)': {
            margin: 'auto auto auto 12px',
            textAlign: 'left',
        },
        '@media (min-width: 960px)': {
            fontSize: 20,
        }
    },
    isIncludedInPackage: {
        background: theme.palette.common.white,
        height: 24,
        padding: 4,
        display: 'inline-block',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
        margin: '8px auto 4px auto',
        borderRadius: 8,
        verticalAlign: 'top',
        lineHeight: '24px',
        '@media (min-width: 600px)': {
            margin: 'auto 8px auto auto',
            textAlign: 'right',
            display: 'flex',
            lineHeight: '1.15em',
            width: 98,
            float: 'right'
        },
        '& $label': {
            width: 72,
            textAlign: 'center',
            justifyContent: 'center',
            verticalAlign: 'top'
        }
    },
    gridContainer: {
        flexDirection: 'column',
        '@media (min-width: 600px)': {
            flexDirection: 'row'
        }
    },
    leftGrid: {},
    rightGrid: {
        margin: '12px auto 0px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '@media (min-width: 600px)': {
            paddingRight: 14,
            height: 36,
            margin: '4px auto 0px',
        }
    },
    label: {},
    priceWidget: {
        '@media (min-width: 600px)': {
            float: 'right',
            marginTop: 4
        }
    }
});

type StyledProps = GLOBAL_STYLED_PROPS &
    WithStyles<'root' | 'heading' | 'rightGrid' | 'isIncludedInPackage'
        | 'label' | 'leftGrid' | 'gridContainer' | 'priceWidget'>;

type CombinedProps = StyledProps & Props;

const ProductItemCardBlock = (props: CombinedProps) => {
    const {
        classes,
        caseFirstName,
        productItem,
        onRemoveContractItem,
        onAddContractItem,
        isShowPrices,
        canUserEditContract,
        context,
        onEditClick,
    } = props;

    const isGPLFeatureEnabled = useFeatureEnabled(FeatureKey.PUBLIC_GPL);

    const isBackOffice = context === GPLContext.BackOffice;

    const { product, contractItem } = productItem;

    const isIncludedInPackage = Boolean(contractItem && contractItem.package_id);
    const displayPrice = getDisplayPrice(product, contractItem);
    const name = getContractItemName(productItem);
    const isXS = window.innerWidth < 600;

    const isAddRemoveItemEnabled = usePermissionEnabled(contractItem ? Permission.REMOVE_ITEMS : Permission.ADD_ITEMS);

    const renderOnClick = () => {
        if (!canUserEditContract) {
            return undefined;
        }

        if (isBackOffice) {
            return onEditClick && product && onEditClick(product.id);
        }

        return contractItem ? onRemoveContractItem && onRemoveContractItem(contractItem)
            : product && onAddContractItem && onAddContractItem(product);
    };

    const renderBadges = (): CardBadge[] | undefined => {
        if (!canUserEditContract) {
            return undefined;
        }

        if (isBackOffice) {
            const backOfficeBadges: CardBadge[] = [{
                position: isXS ? 'bottom' : 'right',
                badge: <Badge
                    color="orange"
                    variant={isXS ? 'extended' : 'round'}
                    icon={{ root: <EditIcon /> }}
                    label={!isXS ? undefined : 'Edit'}
                />,
                tooltipTitle: 'Click to edit product details',
                onClick: renderOnClick,
            }];

            if (isGPLFeatureEnabled && product && product.show_on_website) {
                backOfficeBadges.push({
                    position: 'left',
                    badge: <Badge
                        color={product.show_price_on_website ? 'green' : 'orangeInverted'}
                        variant="round"
                        icon={{ root: <PublicIcon />, size: 36 }}
                    />,
                    tooltipTitle: `An orange icon indicates that this product is synced to your website.
                        Green indicates that it is synced AND that the price is visible on your website.`,
                    tooltipEnterDelay: 200,
                    onClick: renderOnClick,
                });
            }

            return backOfficeBadges;
        }

        const badges: CardBadge[] = [{
            position: isXS ? 'bottom' : 'right',
            badge: <Badge
                color={contractItem ? 'inverted' : 'primary'}
                variant={isXS ? 'extended' : 'round'}
                icon={{ root: contractItem ? <RemoveIcon /> : <AddIcon /> }}
                label={!isXS ? undefined : contractItem ? 'Remove' : 'Add'}
            />,
            tooltipTitle: isAddRemoveItemEnabled
                ? `Click to ${contractItem ? 'remove from' : 'add to'} ${caseFirstName}'s statement`
                : TOOLTIPS.ACCESS_DISABLED,
            onClick: isAddRemoveItemEnabled ? renderOnClick : undefined,
        }];

        return badges;
    };

    const showPrice = isShowPrices || Boolean(context === GPLContext.Public && product?.show_price_on_website);

    return (
        <ProductItemCardBlockBase
            color={contractItem ? 'primary' : isBackOffice ? 'dashed' : 'inverted'}
            badges={renderBadges()}
        >
            <Grid container alignItems="center" className={classes.gridContainer}>
                <Grid
                    item
                    xs={12}
                    sm={isIncludedInPackage && showPrice ? 6 : 9}
                    md={8}
                    className={classes.leftGrid}
                >
                    <Typography
                        color="inherit"
                        className={classes.heading}
                    >
                        {name}
                    </Typography>
                </Grid>

                <Grid
                    item xs={12}
                    sm={isIncludedInPackage && showPrice ? 6 : 3}
                    md={4}
                    className={classes.rightGrid}
                >
                    {showPrice &&
                        <div className={classes.priceWidget}>
                            <PriceWidget
                                priceStr={displayPrice}
                                size={'small'}
                            />
                        </div>
                    }
                    {isIncludedInPackage &&
                        <Typography color="primary" className={classes.isIncludedInPackage}>
                            <PagesIcon />
                            <span className={classes.label}>
                                Included in Package
                                <Hidden smUp> Price</Hidden>
                            </span>
                        </Typography>
                    }
                </Grid>

            </Grid>
        </ProductItemCardBlockBase>
    );
};

export default withGStyles(styles)(ProductItemCardBlock);
