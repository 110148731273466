import { CropperRef, Cropper, CircleStencil, RectangleStencil } from 'react-mobile-cropper';
import 'react-mobile-cropper/dist/style.css';
import {
    DEFAULT_HEIGHT,
    DEFAULT_WIDTH,
    getImageSizeFromDataURI,
    getPhotoUrlWithoutTransformations,
    rotationTypeToString,
    ImageSizeType,
    LANDSCAPE_ASPECT_RATIO,
    PhotoOrientationType,
    PORTRAIT_ASPECT_RATIO,
    SQUARE_ASPECT_RATIO,
    stringToRotationType,
    DESKTOP_COVER_ASPECT_RATIO,
} from '../../services';
import { GatherPhoto } from '../../types';
import makeGStyles from '../../styles/makeGStyles';
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import {
    CloudinaryTransformationsType,
    CoverPhotoEnum,
    CropperStencilEnum,
} from '../../shared/types';
import classNames from 'classnames';

const useStyles = makeGStyles((theme) => ({
    // hide the rotation slider for now...
    hideRotationSlider: {
        '& .rmc-navigation': {
            justifyContent: 'space-between',
        },
        '& .rmc-navigation__rotator': {
            display: 'none',
        },
    }
}));

interface PhotoCropperProps {
    imageURI: string;
    initialTransformations?: CloudinaryTransformationsType;
    stencilType?: CropperStencilEnum;
    orientation?: PhotoOrientationType;
    activePhoto?: GatherPhoto;
    coverPhotoType?: CoverPhotoEnum;
}

interface DefaultTransformations {
    defaultCoordinates: {
        left: number;
        top: number;
        width: number;
        height: number;
    };
    defaultTransforms: {
        rotate: number;
        flip: {
            horizontal: boolean;
            vertical: boolean;
        };
    };
}

export interface BasePhotoCropperRef{
    reset: () => void;
    getCroppedCanvas: () => HTMLCanvasElement | null;
    getCropTransformations: () => Promise<CloudinaryTransformationsType | null>;
}

const BasePhotoCropper = forwardRef<BasePhotoCropperRef, PhotoCropperProps>((props, ref) => {
    const classes = useStyles();
    const {
        imageURI,
        stencilType,
        orientation,
        activePhoto,
        initialTransformations,
    } = props;
    const [image, setImage] = useState<string>(imageURI);
    const [isImageReady, setIsImageReady] = useState(false);
    const [defaultTransformations, setDefaultTransformations] = useState<DefaultTransformations | undefined>();
    const stencil = stencilType === CropperStencilEnum.circle ? CircleStencil : RectangleStencil;
    const cropperRef = useRef<CropperRef>(null);

    useImperativeHandle(ref, () => ({
        reset: () => {
            if (cropperRef.current) {
                cropperRef.current.reset();
            }
        },
        getCroppedCanvas: (): HTMLCanvasElement | null => {
            return cropperRef.current?.getCanvas() ?? null;
        },
        getCropTransformations: async (): Promise<CloudinaryTransformationsType | null> => {
            return await getCropTransformationsInternal();
        }
    }));

    useEffect(() => {
        const getDefaultTransformations = (imageSize: ImageSizeType): DefaultTransformations | undefined => {
            let defaultCoordinates;
            let defaultTransforms;

            if (initialTransformations) {
                const rotations = stringToRotationType(initialTransformations?.angle || '0');
                defaultCoordinates = {
                    left: initialTransformations.x || 0,
                    top: initialTransformations.y || 0,
                    width: initialTransformations.width || imageSize.width,
                    height: initialTransformations.height || imageSize.height,
                };
                defaultTransforms = {
                    rotate: rotations.angle,
                    flip: {
                        horizontal: rotations.horizontal,
                        vertical: rotations.vertical,
                    },
                };
            } else {
                return undefined;
            }
            return { defaultCoordinates, defaultTransforms };
        };

        const loadPhoto = async () => {
            // Reset the image ready state, using a callback to prevent unnecessary re-renders
            setIsImageReady(false);
            let imageSize: ImageSizeType = {
                width: DEFAULT_WIDTH,
                height: DEFAULT_HEIGHT
            };
            if (imageURI !== '') {
                setImage(imageURI);
                imageSize = await getImageSizeFromDataURI(imageURI);
                setIsImageReady(true);
                setDefaultTransformations(getDefaultTransformations(imageSize));
            } else if (activePhoto) {
                const imageUrl =
                    getPhotoUrlWithoutTransformations(activePhoto.photo ? activePhoto.photo.public_id : '');
                setImage(imageUrl);
                imageSize = {
                    width: activePhoto.photo?.width || DEFAULT_WIDTH,
                    height: activePhoto.photo?.height || DEFAULT_HEIGHT
                };
                setDefaultTransformations(getDefaultTransformations(imageSize));
                setIsImageReady(true);
            }
        };
        loadPhoto();
    }, [activePhoto, imageURI, initialTransformations]);

    const getAspectRatio = () => {
        if (orientation === 'square') {
            return SQUARE_ASPECT_RATIO;
        } else if (orientation === 'landscape') {
            return LANDSCAPE_ASPECT_RATIO;
        } else if (orientation === 'desktop_cover') {
            return DESKTOP_COVER_ASPECT_RATIO;
        } else if (orientation === 'portrait') {
            return PORTRAIT_ASPECT_RATIO;
        } else {
            return undefined;
        }
    };

    const getCropTransformationsInternal = async (): Promise<CloudinaryTransformationsType> => {
        let finalCrop: CloudinaryTransformationsType;
        const cropState = cropperRef.current?.getState();
        const imageSizeFromURI = await getImageSizeFromDataURI(image);

        // If the cropper is not in a state where it has been interacted with, we need to set the crop to the center
        if (!cropState || !cropState.coordinates) {
            const imageAspectRatio = imageSizeFromURI.width / imageSizeFromURI.height;
            const cropAspectRatio = getAspectRatio() ?? imageAspectRatio;

            let croppedWidth, croppedHeight;

            if (cropAspectRatio > imageAspectRatio) {
                croppedWidth = imageSizeFromURI.width;
                croppedHeight = croppedWidth / cropAspectRatio;
            } else {
                croppedHeight = imageSizeFromURI.height;
                croppedWidth = croppedHeight * cropAspectRatio;
            }

            const croppedX = (imageSizeFromURI.width - croppedWidth) / 2;
            const croppedY = (imageSizeFromURI.height - croppedHeight) / 2;

            finalCrop = {
                width: Math.round(croppedWidth),
                height: Math.round(croppedHeight),
                x: Math.round(croppedX),
                y: Math.round(croppedY),
                crop: 'crop',
                angle: rotationTypeToString({
                    angle: 0,
                    vertical: false,
                    horizontal: false,
                }),
            };
        } else {
            const cropX = cropState.coordinates ? Math.round(cropState.coordinates.left) : 0;
            const cropY = cropState.coordinates ? Math.round(cropState.coordinates.top) : 0;
            const cropWidth = cropState.coordinates
                ? Math.round(cropState.coordinates.width) : imageSizeFromURI.width;
            const cropHeight = cropState.coordinates
                ? Math.round(cropState.coordinates.height) : imageSizeFromURI.height;
            const rotation = cropState.transforms.rotate % 360;
            const finalRotation = rotationTypeToString({
                angle: rotation,
                vertical: cropState.transforms.flip.vertical,
                horizontal: cropState.transforms.flip.horizontal
            });

            finalCrop = {
                crop: 'crop',
                width: cropWidth,
                height: cropHeight,
                x: cropX,
                y: cropY,
                angle: finalRotation,
            };
        }
        return finalCrop;
    };

    if (isImageReady === false) {
        // TODO - we should probably have an image loading spinner here...
        return null;
    } else {
        return (
            <Cropper
                className={classNames('cropper', classes.hideRotationSlider)}
                ref={cropperRef}
                src={image}
                defaultCoordinates={defaultTransformations?.defaultCoordinates}
                defaultTransforms={defaultTransformations?.defaultTransforms}
                stencilComponent={stencil}
                stencilProps={{
                    grid: true,
                    aspectRatio: getAspectRatio(),
                }}
                resizeImage
            />
        );
    }
});

BasePhotoCropper.displayName = 'BasePhotoCropper';

export default BasePhotoCropper;
