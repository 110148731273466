import * as t from 'io-ts';
import { GatherCaseUX } from './case';
import { getValidator } from './utils';

export enum YesNoUnknownEnum {
    Yes = 'Yes',
    No = 'No',
    Unknown = 'Unknown'
}

const YES_NO_UNKNOWN = t.union([
    t.literal(YesNoUnknownEnum.Yes),
    t.literal(YesNoUnknownEnum.No),
    t.literal(YesNoUnknownEnum.Unknown),
]);

export enum YesNoEnum {
    Yes = 'Yes',
    No = 'No',
}

const YES_NO = t.union([
    t.literal(YesNoEnum.Yes),
    t.literal(YesNoEnum.No)
]);

// ---> DeathCertificate <---
export enum GmapsSearchType {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    shortAddress = 'shortAddress',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    longAddress = 'longAddress',
}

const shortAddress = t.intersection([
    t.type({
        city: t.string,
        state: t.string,
        country: t.string,
        description: t.string,
    }),
    t.partial({
        googlePlaceID: t.string,
        unknown: t.boolean,
    }),
]);

export function isShortAddress(address: unknown): address is ShortAddress {
    return shortAddress.is(address);
}

export const longAddress = t.intersection([
    t.type({
        address1: t.string,
        city: t.string,
        state: t.string,
        postalCode: t.string,
        county: t.string,
        country: t.string,
        description: t.string,
    }),
    t.partial({
        isEstablishment: t.boolean,
        locationName: t.string,
        coords: t.array(t.number),
        address2: t.string,
        googlePlaceID: t.string,
        unknown: t.boolean,
    })
]);

export function isLongAddress(address: unknown): address is LongAddress {
    return longAddress.is(address);
}

export interface ShortAddress extends t.TypeOf<typeof shortAddress> { }
export interface LongAddress extends t.TypeOf<typeof longAddress> { }

export const NullShortAddress: ShortAddress = {
    city: '',
    state: '',
    country: '',
    googlePlaceID: '',
    description: '',
};

export const NullLongAddress: LongAddress = {
    locationName: '',
    address1: '',
    city: '',
    county: '',
    postalCode: '',
    state: '',
    country: '',
    googlePlaceID: '',
    description: '',
};

export interface ManualAddress {
    locationName: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    county: string;
}

export enum GenderEnum {
    Male = 'Male',
    Female = 'Female',
    Other = 'Other',
    Unknown = 'Unknown',
    'Not Yet Determined' = 'Not Yet Determined',
}

export enum PronounsEnum {
    'he/him' = 'he/him',
    'she/her' = 'she/her',
    'they/them' = 'they/them',
    'other' = 'other',
}

export const deathCertificateAboutType = t.partial({
    aka: t.string,
    birthLastName: t.string,
    gender: t.union([
        t.literal(GenderEnum.Male),
        t.literal(GenderEnum.Female),
        t.literal(GenderEnum.Other),
        t.literal(GenderEnum.Unknown),
        t.literal(GenderEnum['Not Yet Determined']),
    ]),
    pronouns: t.union([
        t.literal(PronounsEnum['he/him']),
        t.literal(PronounsEnum['she/her']),
        t.literal(PronounsEnum['they/them']),
        t.literal(PronounsEnum.other),
    ]),
    hasMemorialService: YES_NO,
    ssn: t.string,
    ssn_unknown: t.boolean,
    ministerOfficiating: t.string,
    pickUpAddress: longAddress,
    dropOffAddress: longAddress,
    hasStairs: YES_NO_UNKNOWN,
    numberOfDCs: t.number,
    casket: t.string,
    insuranceDetails: t.string,
    hair: t.string,
    facialHair: t.string,
    nails: t.string,
    lipstickColor: t.string,
    medicalExaminerReleaseNumber: t.string,
    clothingDueBy: t.string,
    referredToUsBy: t.string,
    ssn_none: t.boolean,
    hobbies: t.string,
    lodges: t.string,
    clubs: t.string,
    offices: t.string,
    previousPlacesLived: t.string,
    casketPanel: t.string,
    chapel: t.string,
    insurancePolicyNumber: t.string,
    weight: t.string,
    other: t.string,
    hasChildrenUnder18: YES_NO_UNKNOWN,
});

export interface DeathCertificateAboutType extends t.TypeOf<typeof deathCertificateAboutType> {
    gender?: GenderEnum;
    pronouns?: PronounsEnum;
    hasMemorialService?: YesNoEnum;
    hasStairs?: YesNoUnknownEnum;
    pickUpAddress?: LongAddress;
    dropOffAddress?: LongAddress;
    hasChildrenUnder18?: YesNoUnknownEnum;
}
export function generateDeathCertificateAbout(): DeathCertificateAboutType {
    return {
        birthLastName: '',
        gender: undefined,
        pronouns: undefined,
        hasMemorialService: undefined,
        ssn: '',
        ssn_unknown: false,
        aka: '',
        ministerOfficiating: '',
        pickUpAddress: undefined,
        dropOffAddress: undefined,
        hasStairs: undefined,
        numberOfDCs: undefined,
        casket: '',
        insuranceDetails: '',
        hair: '',
        facialHair: '',
        nails: '',
        lipstickColor: '',
        medicalExaminerReleaseNumber: '',
        clothingDueBy: '',
        referredToUsBy: '',
        ssn_none: false,
        hobbies: '',
        lodges: '',
        clubs: '',
        offices: '',
        previousPlacesLived: '',
        casketPanel: '',
        chapel: '',
        insurancePolicyNumber: '',
        weight: undefined,
        other: undefined,
        hasChildrenUnder18: undefined,
    };
}

const residencePlace = t.intersection([
    longAddress,
    t.partial({
        insideCityLimits: YES_NO_UNKNOWN,
        yearsInCounty: t.number,
        filingState: t.string,
    })
]);

export interface ResidencePlaceType extends t.TypeOf<typeof residencePlace> {
    insideCityLimits?: YesNoUnknownEnum;
}

export enum DeathLocationEnum {
    'As an inpatient at a hospital' = 'As an inpatient at a hospital',
    'In the emergency room' = 'In the emergency room',
    'On the way to the hospital' = 'On the way to the hospital',
    'At a hospice facility' = 'At a hospice facility',
    'At a nursing home or long-term care facility' = 'At a nursing home or long-term care facility',
    'At home' = 'At home',
    'At another location' = 'At another location',
    'Not Classifiable' = 'Not Classifiable'
}

export const deathCertificateLifeType = t.intersection([
    t.type({
        birthPlace: shortAddress,
        physicianName: t.string,
        physicianPhoneNumber: t.string,
        physicianFaxNumber: t.string,
        causeOfDeath: t.string,
        deathPlace: longAddress,
        residencePlace: residencePlace,
    }),
    t.partial({
        physicianAddress: longAddress,
        deathLocation_listOfOptions: t.union([
            t.literal(DeathLocationEnum['As an inpatient at a hospital']),
            t.literal(DeathLocationEnum['In the emergency room']),
            t.literal(DeathLocationEnum['On the way to the hospital']),
            t.literal(DeathLocationEnum['At a hospice facility']),
            t.literal(DeathLocationEnum['At a nursing home or long-term care facility']),
            t.literal(DeathLocationEnum['At home']),
            t.literal(DeathLocationEnum['At another location']),
            t.literal(DeathLocationEnum['Not Classifiable'])
        ]),
        hebrewDateOfBirth: t.string,
        hebrewDateOfDeath: t.string
    })
]);

export interface DeathCertificateLifeType extends t.TypeOf<typeof deathCertificateLifeType> {
    birthPlace: ShortAddress;
    deathPlace: LongAddress;
    residencePlace: ResidencePlaceType;
    deathLocation_listOfOptions?: DeathLocationEnum;
    physicianAddress?: LongAddress;
}
export function generateDeathCertificateLife(): DeathCertificateLifeType {
    return {
        birthPlace: {
            ...NullShortAddress,
        },
        physicianName: '',
        physicianPhoneNumber: '',
        physicianFaxNumber: '',
        physicianAddress: undefined,
        causeOfDeath: '',
        deathLocation_listOfOptions: undefined,
        deathPlace: {
            ...NullLongAddress,
        },
        residencePlace: {
            ...NullLongAddress,
            insideCityLimits: undefined,
            yearsInCounty: undefined,
            filingState: undefined,
        },
    };
}

export enum RestingPlaceOptionsEnum {
    'Burial' = 'Burial',
    'Cremation' = 'Cremation',
    'Cremation with Burial' = 'Cremation with Burial',
    'Cremation with Entombment' = 'Cremation with Entombment',
    'Donation' = 'Donation',
    'Entombment' = 'Entombment',
    'Resomation' = 'Resomation',
    'Other' = 'Other',
    'Unknown' = 'Unknown'
}

const deathCertificateRestingPlaceType = t.intersection([
    t.type({
        dispositionPlace: longAddress,
        dispositionDate: t.string,
        cemeteryPropertyDetails: t.string,
    }),
    t.partial({
        options: t.union([
            t.literal(RestingPlaceOptionsEnum.Burial),
            t.literal(RestingPlaceOptionsEnum.Cremation),
            t.literal(RestingPlaceOptionsEnum['Cremation with Burial']),
            t.literal(RestingPlaceOptionsEnum['Cremation with Entombment']),
            t.literal(RestingPlaceOptionsEnum.Resomation),
            t.literal(RestingPlaceOptionsEnum.Donation),
            t.literal(RestingPlaceOptionsEnum.Entombment),
            t.literal(RestingPlaceOptionsEnum.Other),
            t.literal(RestingPlaceOptionsEnum.Unknown)
        ]),
        specifyOther: t.string,
        ownsCemeteryProperty: YES_NO,
        crematoryName: t.string,
        secondDispositionPlace: longAddress,
    })
]);

export interface DeathCertificateRestingPlaceType extends t.TypeOf<typeof deathCertificateRestingPlaceType> {
    dispositionPlace: LongAddress;
    options?: RestingPlaceOptionsEnum;
    ownsCemeteryProperty?: YesNoEnum;
}
export function generateDeathCertificateRestingPlace(): DeathCertificateRestingPlaceType {
    return {
        options: undefined,
        specifyOther: '',
        dispositionPlace: {
            ...NullLongAddress,
        },
        dispositionDate: '',
        ownsCemeteryProperty: undefined,
        cemeteryPropertyDetails: '',
        crematoryName: '',
        secondDispositionPlace: {
            ...NullLongAddress,
        }
    };
}

const deathCertificateParentsType = t.type({
    fatherBirthPlace: shortAddress,
    motherName: t.intersection([
        t.type({
            unknown: t.boolean,
        }),
        t.partial({
            maidenName: t.string,
        })
    ]),
    motherBirthPlace: shortAddress,
});

export interface DeathCertificateParentsType extends t.TypeOf<typeof deathCertificateParentsType> {
    fatherBirthPlace: ShortAddress;
    motherBirthPlace: ShortAddress;
}
export function generateDeathCertificateParentsType(): DeathCertificateParentsType {
    return {
        fatherBirthPlace: {
            ...NullShortAddress,
        },
        motherName: {
            unknown: false,
            maidenName: ''
        },
        motherBirthPlace: {
            ...NullShortAddress,
        }
    };
}

export enum EducationTypeEnum {
    '1st grade' = '1st grade',
    '2nd grade' = '2nd grade',
    '3rd grade' = '3rd grade',
    '4th grade' = '4th grade',
    '5th grade' = '5th grade',
    '6th grade' = '6th grade',
    '7th grade' = '7th grade',
    '8th grade or less' = '8th grade or less',
    '8th grade' = '8th grade',
    '9th grade' = '9th grade',
    '10th grade' = '10th grade',
    '11th grade' = '11th grade',
    '12th grade' = '12th grade',
    '9th-12th grade; no diploma' = '9th-12th grade; no diploma',
    'High School graduate' = 'High School graduate',
    'GED completed' = 'GED completed',
    'High School graduate or GED completed' = 'High School graduate or GED completed',
    'Some college credit, but no degree' = 'Some college credit, but no degree',
    'Associate degree' = 'Associate degree',
    'Bachelor\'s degree' = 'Bachelor\'s degree',
    'Master\'s degree' = 'Master\'s degree',
    'Doctorate (e.g. PhD, EdD)' = 'Doctorate (e.g. PhD, EdD)',
    'Professional degree (e.g. MD, DDS, DVM, LLB, JD)' = 'Professional degree (e.g. MD, DDS, DVM, LLB, JD)',
    'Doctorate or Professional degree' = 'Doctorate or Professional degree',
    'Degree not listed here' = 'Degree not listed here',
    'Not Available' = 'Not Available',
    'Unknown' = 'Unknown',
    'Refuse to answer' = 'Refuse to answer',
    'None' = 'None'
}

const deathCertificateEducationType = t.partial({
    educationListOfOptions: t.union([
        t.literal(EducationTypeEnum['1st grade']),
        t.literal(EducationTypeEnum['2nd grade']),
        t.literal(EducationTypeEnum['3rd grade']),
        t.literal(EducationTypeEnum['4th grade']),
        t.literal(EducationTypeEnum['5th grade']),
        t.literal(EducationTypeEnum['6th grade']),
        t.literal(EducationTypeEnum['7th grade']),
        t.literal(EducationTypeEnum['8th grade']),
        t.literal(EducationTypeEnum['8th grade or less']),
        t.literal(EducationTypeEnum['9th grade']),
        t.literal(EducationTypeEnum['10th grade']),
        t.literal(EducationTypeEnum['11th grade']),
        t.literal(EducationTypeEnum['12th grade']),
        t.literal(EducationTypeEnum['9th-12th grade; no diploma']),
        t.literal(EducationTypeEnum['High School graduate']),
        t.literal(EducationTypeEnum['GED completed']),
        t.literal(EducationTypeEnum['High School graduate or GED completed']),
        t.literal(EducationTypeEnum['Some college credit, but no degree']),
        t.literal(EducationTypeEnum['Associate degree']),
        t.literal(EducationTypeEnum['Bachelor\'s degree']),
        t.literal(EducationTypeEnum['Master\'s degree']),
        t.literal(EducationTypeEnum['Doctorate (e.g. PhD, EdD)']),
        t.literal(EducationTypeEnum['Professional degree (e.g. MD, DDS, DVM, LLB, JD)']),
        t.literal(EducationTypeEnum['Doctorate or Professional degree']),
        t.literal(EducationTypeEnum['Degree not listed here']),
        t.literal(EducationTypeEnum['Not Available']),
        t.literal(EducationTypeEnum.Unknown),
        t.literal(EducationTypeEnum['Refuse to answer']),
        t.literal(EducationTypeEnum.None)
    ]),
    educationHistory: t.string,
});

export interface DeathCertificateEducationType extends t.TypeOf<typeof deathCertificateEducationType> {
    educationListOfOptions?: EducationTypeEnum;
}

export function generateDeathCertificateEducationType(): DeathCertificateEducationType {
    return {
        educationListOfOptions: undefined,
        educationHistory: undefined,
    };
}

export enum MaritalStatusEnum {
    'Married' = 'Married',
    'Married, but separated' = 'Married, but separated',
    'Married, died at the same time' = 'Married, died at the same time',
    'Widowed' = 'Widowed',
    'Divorced' = 'Divorced',
    'Never Married' = 'Never Married',
    'Unknown' = 'Unknown',
    'Not Obtainable' = 'Not Obtainable'
}

const deathCertificateMarriageType = t.partial({
    marriageDate_unknown: t.boolean,
    marriagePlace: shortAddress,
    maritalStatus: t.union([
        t.literal(MaritalStatusEnum.Married),
        t.literal(MaritalStatusEnum['Married, but separated']),
        t.literal(MaritalStatusEnum['Married, died at the same time']),
        t.literal(MaritalStatusEnum.Widowed),
        t.literal(MaritalStatusEnum.Divorced),
        t.literal(MaritalStatusEnum['Never Married']),
        t.literal(MaritalStatusEnum.Unknown),
        t.literal(MaritalStatusEnum['Not Obtainable'])
    ]),
    spouseMaidenName: t.string,
    marriageDate: t.string,
    spousesDateOfPassing: t.string
});

export interface DeathCertificateMarriageType extends t.TypeOf<typeof deathCertificateMarriageType> {
    marriagePlace: ShortAddress;
    maritalStatus?: MaritalStatusEnum;
}

export function generateDeathCertificateMarriageType(): DeathCertificateMarriageType {
    return {
        maritalStatus: undefined,
        spouseMaidenName: '',
        marriageDate: '',
        marriageDate_unknown: false,
        marriagePlace: {
            ...NullShortAddress,
        }
    };
}

export enum OccupationListOfOptions {
    'Employed' = 'Employed',
    'Not Employed' = 'Not Employed',
    'Never Employed' = 'Never Employed',
    'Retired' = 'Retired',
}

const deathCertificateWorkHistoryType =
    t.partial({
        occupationListOfOptions: t.union([
            t.literal(OccupationListOfOptions.Employed),
            t.literal(OccupationListOfOptions['Not Employed']),
            t.literal(OccupationListOfOptions['Never Employed']),
            t.literal(OccupationListOfOptions.Retired),
        ]),
        normalOccupation: t.string,
        normalOccupation_unknown: t.boolean,
        normalOccupationIndustry: t.string,
        normalOccupationIndustry_unknown: t.boolean,
        currentEmployer: t.string,
        yearsInOccupation: t.string,
        retiredInYear: t.string,
        retiredLastEmployer: t.string,
    });

export interface DeathCertificateWorkHistoryType extends t.TypeOf<typeof deathCertificateWorkHistoryType> {
    occupationListOfOptions?: OccupationListOfOptions;
}

export function generateDeathCertificateWorkHistoryType(): DeathCertificateWorkHistoryType {
    return {
        occupationListOfOptions: undefined,
        normalOccupation: '',
        normalOccupation_unknown: false,
        normalOccupationIndustry: '',
        normalOccupationIndustry_unknown: false,
        currentEmployer: '',
        yearsInOccupation: undefined,
        retiredInYear: undefined,
        retiredLastEmployer: undefined,
    };
}

export enum MilitaryBranchOption {
    'air force' = 'air force',
    'army' = 'army',
    'coast guard' = 'coast guard',
    'marines' = 'marines',
    'navy' = 'navy',
}

const militaryBranchOptionType = t.union([
    t.literal(MilitaryBranchOption['air force']),
    t.literal(MilitaryBranchOption.army),
    t.literal(MilitaryBranchOption['coast guard']),
    t.literal(MilitaryBranchOption.marines),
    t.literal(MilitaryBranchOption.navy),
]);

export const MilitaryBranchOptionDisplayLookup = {
    [MilitaryBranchOption['air force']]: 'Air Force',
    [MilitaryBranchOption.army]: 'Army',
    [MilitaryBranchOption['coast guard']]: 'Coast Guard',
    [MilitaryBranchOption.marines]: 'Marines',
    [MilitaryBranchOption.navy]: 'Navy',
};

const deathCertificateMilitaryType =
    t.partial({
        military: YES_NO_UNKNOWN,
        militaryBranch: t.array(militaryBranchOptionType),
        militaryRank: t.string,
        militaryYears: t.number,
        militaryServiceNumber: t.string,
        militaryHonors: t.array(t.string),
        disabilityContributedToDeath: t.boolean,
        serviceDateFrom: t.string,
        serviceDateTo: t.string
    });

export interface DeathCertificateMilitaryType extends t.TypeOf<typeof deathCertificateMilitaryType> {
    military?: YesNoUnknownEnum;
}

export function generateDeathCertificateMilitaryType(): DeathCertificateMilitaryType {
    return {
        military: undefined,
        militaryBranch: [],
        militaryRank: '',
        militaryYears: undefined,
        militaryServiceNumber: '',
        militaryHonors: [],
        disabilityContributedToDeath: undefined,
        serviceDateFrom: '',
        serviceDateTo: ''
    };
}

export enum AncestryEnum {
    'American' = 'American',
    'African' = 'African',
    'African American' = 'African American',
    'American Indian (Specify Tribe)' = 'American Indian (Specify Tribe)',
    'Arab' = 'Arab',
    'Asian' = 'Asian',
    'Cuban' = 'Cuban',
    'Dutch' = 'Dutch',
    'English' = 'English',
    'French' = 'French',
    'German' = 'German',
    'Mexican' = 'Mexican',
    'Not Applicable' = 'Not Applicable',
    'Other Ancestry' = 'Other Ancestry',
    'Polish' = 'Polish',
}

export enum HispanicOriginEnum {
    'No, not Spanish/Hispanic/Latino' = 'No, not Spanish/Hispanic/Latino',
    'Yes, Mexican' = 'Yes, Mexican',
    'Yes, Mexican American' = 'Yes, Mexican American',
    'Yes, Chicano' = 'Yes, Chicano',
    'Yes, Mexican, Mexican American, Chicano' = 'Yes, Mexican, Mexican American, Chicano',
    'Yes, Puerto Rican' = 'Yes, Puerto Rican',
    'Yes, Cuban' = 'Yes, Cuban',
    'Yes, Other Spanish/Hispanic/Latino' = 'Yes, Other Spanish/Hispanic/Latino',
    'Unknown' = 'Unknown',
    'Not obtainable' = 'Not obtainable',
    'Refuse to answer' = 'Refuse to answer',
}

export enum RaceEnum {
    'White' = 'White',
    'Black' = 'Black',
    'Black or African American' = 'Black or African American',
    'American Indian or Alaska Native' = 'American Indian or Alaska Native',
    'Alaska Native' = 'Alaska Native',
    'American Indian' = 'American Indian',
    'Asian Indian' = 'Asian Indian',
    'Chinese' = 'Chinese',
    'Filipino' = 'Filipino',
    'Japanese' = 'Japanese',
    'Korean' = 'Korean',
    'Vietnamese' = 'Vietnamese',
    'Hispanic/Latino (Specify)' = 'Hispanic/Latino (Specify)',
    'Other Asian' = 'Other Asian',
    'Asian (Specify)' = 'Asian (Specify)',
    'Native Hawaiian' = 'Native Hawaiian',
    'Guamanian or Chamorro' = 'Guamanian or Chamorro',
    'Samoan' = 'Samoan',
    'Other Pacific Islander' = 'Other Pacific Islander',
    'Other' = 'Other',
    'Unknown' = 'Unknown',
    'Refuse to answer' = 'Refuse to answer',
    'Not Applicable' = 'Not Applicable'
}

const deathCertificateRaceType =
    t.partial({
        ancestry: t.array(
            t.union([
                t.literal(AncestryEnum.American),
                t.literal(AncestryEnum.African),
                t.literal(AncestryEnum['African American']),
                t.literal(AncestryEnum['American Indian (Specify Tribe)']),
                t.literal(AncestryEnum.Arab),
                t.literal(AncestryEnum.Asian),
                t.literal(AncestryEnum.Cuban),
                t.literal(AncestryEnum.Dutch),
                t.literal(AncestryEnum.English),
                t.literal(AncestryEnum.French),
                t.literal(AncestryEnum.German),
                t.literal(AncestryEnum.Mexican),
                t.literal(AncestryEnum['Not Applicable']),
                t.literal(AncestryEnum.Polish),
                t.literal(AncestryEnum['Other Ancestry']),
            ])
        ),
        hispanicOrigin: t.array(t.union([
            t.literal(HispanicOriginEnum['No, not Spanish/Hispanic/Latino']),
            t.literal(HispanicOriginEnum['Yes, Mexican']),
            t.literal(HispanicOriginEnum['Yes, Mexican American']),
            t.literal(HispanicOriginEnum['Yes, Chicano']),
            t.literal(HispanicOriginEnum['Yes, Mexican, Mexican American, Chicano']),
            t.literal(HispanicOriginEnum['Yes, Puerto Rican']),
            t.literal(HispanicOriginEnum['Yes, Cuban']),
            t.literal(HispanicOriginEnum['Yes, Other Spanish/Hispanic/Latino']),
            t.literal(HispanicOriginEnum.Unknown),
            t.literal(HispanicOriginEnum['Not obtainable']),
            t.literal(HispanicOriginEnum['Refuse to answer']),
        ])),
        race: t.array(t.union([
            t.literal(RaceEnum.White),
            t.literal(RaceEnum['Black or African American']),
            t.literal(RaceEnum['American Indian or Alaska Native']),
            t.literal(RaceEnum['Asian Indian']),
            t.literal(RaceEnum.Chinese),
            t.literal(RaceEnum.Filipino),
            t.literal(RaceEnum.Japanese),
            t.literal(RaceEnum.Korean),
            t.literal(RaceEnum.Vietnamese),
            t.literal(RaceEnum['Other Asian']),
            t.literal(RaceEnum['Asian (Specify)']),
            t.literal(RaceEnum['Native Hawaiian']),
            t.literal(RaceEnum['Guamanian or Chamorro']),
            t.literal(RaceEnum.Samoan),
            t.literal(RaceEnum['Other Pacific Islander']),
            t.literal(RaceEnum.Other),
            t.literal(RaceEnum.Unknown),
            t.literal(RaceEnum['Refuse to answer']),
            t.literal(RaceEnum['Hispanic/Latino (Specify)']),
            t.literal(RaceEnum.Black),
            t.literal(RaceEnum['American Indian']),
            t.literal(RaceEnum['Alaska Native']),
            t.literal(RaceEnum['Not Applicable'])
        ])),
        americanIndian_specifiy_tribe: t.string,
        hispanicOrigin_specify_other: t.string,
        race_specify_indian_tribe: t.string,
        race_specify_asian: t.string,
        race_specify_islander: t.string,
        race_specify_other: t.string,
        race_specify_other_hispanic: t.string,
        race_specify_other_asian: t.string,
        race_specify_other_ancestry: t.string,
        isUsCitizen: YES_NO_UNKNOWN,
    });

export interface DeathCertificateRaceType extends t.TypeOf<typeof deathCertificateRaceType> {
    hispanicOrigin?: HispanicOriginEnum[];
    race?: RaceEnum[];
    isUsCitizen?: YesNoUnknownEnum;
    ancestry?: AncestryEnum[];
}

export function generateDeathCertificateRaceType(): DeathCertificateRaceType {
    return {
        hispanicOrigin: [],
        hispanicOrigin_specify_other: '',
        race: [],
        americanIndian_specifiy_tribe: '',
        race_specify_indian_tribe: '',
        race_specify_asian: '',
        race_specify_islander: '',
        race_specify_other: '',
        race_specify_other_ancestry: '',
        isUsCitizen: undefined
    };
}

// Death Certificate total
const deathCertificateDefinition = {
    about: deathCertificateAboutType,
    life: deathCertificateLifeType,
    restingPlace: deathCertificateRestingPlaceType,
    parents: deathCertificateParentsType,
    race: deathCertificateRaceType,
    education: deathCertificateEducationType,
    marriage: deathCertificateMarriageType,
    workHistory: deathCertificateWorkHistoryType,
    military: deathCertificateMilitaryType,
};

const deathCertificateType = t.type(deathCertificateDefinition);
export interface DeathCertificateType extends t.TypeOf<typeof deathCertificateType> {
    about: DeathCertificateAboutType;
    life: DeathCertificateLifeType;
    restingPlace: DeathCertificateRestingPlaceType;
    parents: DeathCertificateParentsType;
    race: DeathCertificateRaceType;
    education: DeathCertificateEducationType;
    marriage: DeathCertificateMarriageType;
    workHistory: DeathCertificateWorkHistoryType;
    military: DeathCertificateMilitaryType;
}

export class DeathCertificateType {
    public static isDeathCertificateKey(key: string): key is keyof DeathCertificateType {
        return t.keyof(deathCertificateDefinition).is(key);
    }
}

export function generateDeathCertificate(): DeathCertificateType {
    return {
        about: generateDeathCertificateAbout(),
        life: generateDeathCertificateLife(),
        restingPlace: generateDeathCertificateRestingPlace(),
        parents: generateDeathCertificateParentsType(),
        education: generateDeathCertificateEducationType(),
        marriage: generateDeathCertificateMarriageType(),
        workHistory: generateDeathCertificateWorkHistoryType(),
        military: generateDeathCertificateMilitaryType(),
        race: generateDeathCertificateRaceType(),
    };
}

const vitalDatesTypeDefinitions = {
    death_date_unknown: t.boolean,
    dob_date: t.string,
    dod_start_date: t.string,
    dod_start_time: t.string,
    dod_start_tz: t.string,
    dod_end_date: t.string,
    dod_end_time: t.string,
    dod_end_tz: t.string,
    dop_date: t.string,
    dop_time: t.string,
    dop_tz: t.string,
};

const VitalDatesType = t.type(vitalDatesTypeDefinitions);
type VitalDatesType = t.TypeOf<typeof VitalDatesType>;
export interface VitalDates extends VitalDatesType {
}
export class VitalDates {
    public static fromRequest = getValidator<VitalDates>(VitalDatesType);
}

// ---> DeathCertificateUpdateRequest <---
const deathCertificateUpdateRequestDefinition = {
    death_certificate: t.unknown,
    death_certificate_locked: t.boolean,
    dc_informant: t.union([t.number, t.null]),
    dc_spouse: t.union([t.number, t.null]),
    dc_mother: t.union([t.number, t.null]),
    dc_father: t.union([t.number, t.null]),
};
const DeathCertificateUpdateRequestType = t.partial(deathCertificateUpdateRequestDefinition);

export interface DeathCertificateUpdateRequest extends t.TypeOf<typeof DeathCertificateUpdateRequestType> {
    death_certificate?: DeathCertificateType;
}

export class DeathCertificateUpdateRequest {
    public static fromRequest = getValidator<DeathCertificateUpdateRequest>(DeathCertificateUpdateRequestType);
}

// ---> DeathCertificatePatchRequest <---
const DeathCertificatePatchRequestType = t.partial({
    about: t.partial(deathCertificateAboutType.props),
    life: deathCertificateLifeType, // ideally this could be partial -- but need to remove the t.intersection
    restingPlace: deathCertificateRestingPlaceType, // same as above section
    parents: t.partial(deathCertificateParentsType.props),
    race: t.partial(deathCertificateRaceType.props),
    education: t.partial(deathCertificateEducationType.props),
    marriage: t.partial(deathCertificateMarriageType.props),
    workHistory: t.partial(deathCertificateWorkHistoryType.props),
    military: t.partial(deathCertificateMilitaryType.props),
});

export interface DeathCertificatePatchRequest extends t.TypeOf<typeof DeathCertificatePatchRequestType> {
    about?: Partial<DeathCertificateAboutType>;
    life?: DeathCertificateLifeType;
    restingPlace?: DeathCertificateRestingPlaceType;
    parents?: Partial<DeathCertificateParentsType>;
    race?: Partial<DeathCertificateRaceType>;
    education?: Partial<DeathCertificateEducationType>;
    marriage?: Partial<DeathCertificateMarriageType>;
    workHistory?: Partial<DeathCertificateWorkHistoryType>;
    military?: Partial<DeathCertificateMilitaryType>;
}

export class DeathCertificatePatchRequest {
    public static fromRequest = getValidator<DeathCertificatePatchRequest>(DeathCertificatePatchRequestType);
}

export interface DeathCertificateUpdateInfo {
    id: number;
    name: string;
    updated_time: Date;
}

export interface SaveDeathCertificateResponse {
    case: GatherCaseUX;
    dc_updated?: DeathCertificateUpdateInfo;
}
