import * as Sentry from '@sentry/react';
import { StoreState } from './types';
import { isBlacklisted, monkeyPatchConsole, ConsoleFunction, SentryTags, genericizeUrl } from './shared/errorUtils';
import { each } from 'lodash';
import { GatherAction, getApiBuildTime, getReactBuildTime } from './actions';
import { DateTimeFormat, joinNameParts } from './services';
import moment from 'moment-timezone';

const appLoadMoment = moment();

export function isSentryEnabled() {
    return Boolean(process.env.REACT_APP_SENTRY_DSN);
}

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action: GatherAction) => {
        // not returning any of the payload protect PII
        return { type: action.type };
    },
    stateTransformer: (state: StoreState) => {
        // returning NO state to protect PII
        return null;
    },
    configureScopeWithState: (scope, state: StoreState) => {
        const { userData } = state.userSession;
        if (userData) {
            scope.setUser({
                username: joinNameParts(userData),
                id: userData.id.toString(),
                email: userData.email ?? undefined,
                role: userData.role,
            });
        }
    },
});

export const sentryCapture = (
    error: Error | string,
    metaData?: Record<string, unknown>,
    tags?: SentryTags,
) => {
    if (!isSentryEnabled()) {
        return;
    }

    Sentry.configureScope((scope) => {
        const apiBuildTime = getApiBuildTime();
        const reactBuildTime = getReactBuildTime();

        if (tags) {
            // Tags give us the ability to query in Sentry by tag
            scope.setTags(tags);
        }

        let appAge: string;
        const appAgeInHours = moment().diff(appLoadMoment, 'hours');
        if (appAgeInHours >= 48) {
            appAge = `${Math.floor(appAgeInHours / 24)} days`;
        } else {
            appAge = `${appAgeInHours} hours`;
        }
        const isApp6hrsOld = appLoadMoment.isBefore(moment().subtract(6, 'hours'));
        scope.setTag('app.age.is.6hrs.old', isApp6hrsOld);
        scope.setTag('app.age', appAge);

        const urlPath = window.location.pathname;
        const genericPath = genericizeUrl(urlPath);
        const genericUrl = `${window.location.origin}${genericPath}`;
        scope.setTag('url.generic', genericUrl);
        scope.setTag('url.path', urlPath);
        scope.setTag('url.path.generic', genericPath);

        if (apiBuildTime) {
            scope.setTag(
                'build.time.api',
                moment(apiBuildTime).tz('America/Boise').format(DateTimeFormat.ShortDateWithTimeAndTZ),
            );
        }
        if (reactBuildTime) {
            scope.setTag(
                'build.time.ui',
                reactBuildTime.tz('America/Boise').format(DateTimeFormat.ShortDateWithTimeAndTZ),
            );
        }

        if (metaData) {
            // add meta data to the extra context
            each(metaData, (val, key) => {
                scope.setExtra(key, val);
            });
        }
    });

    Sentry.captureException(error);
};

export let originalConsoleWarn: ConsoleFunction = console.warn;

export function setupSentry() {
    if (!isSentryEnabled()) {
        return;
    }


    const BLACKLISTED_ERRORS = [
        'The YouTube player is not attached to the DOM.',
        'Google Maps JavaScript API error: NotLoadingAPIFromGoogleMapsError',
        '`arrow.element` must be child of its popper element!',
        'FS.getCurrentSessionURL not ready',
        'ResizeObserver loop limit exceeded',
        'NotAllowedError: play()',
        'AbortError: The play() request was interrupted',
        'NotAllowedError: The play method is not allowed',
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        '"Error:" {}',
        '"Prettify fetch failed: " {}',
        'Can\'t find variable: gmo',
    ];

    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: process.env.REACT_APP_SENTRY_RELEASE,
        ignoreErrors: BLACKLISTED_ERRORS,
        beforeSend: (event) => {
            const block: boolean = (event.exception && event.exception.values || []).some((obj) =>
                Boolean(obj.value && isBlacklisted(obj.value, BLACKLISTED_ERRORS))
            );
            if (block) {
                return null;
            }
            return event;
        },
    });

    const originalFuncs = monkeyPatchConsole(sentryCapture, BLACKLISTED_ERRORS);
    originalConsoleWarn = originalFuncs.originalWarn;
}
