import { Grid, Theme, Typography } from "@mui/material";
import { GatherCaseUX, TaskComponentType, TaskComponentUX, UserProfile } from "../../../../shared/types";
import { TrackingItem } from "../BodyTrackingContent";
import makeStyles from '@mui/styles/makeStyles';
import { formatDateString, getAddressFromLongAddress, getFullNameFromUser } from "../../../../services";
import { getCompactKeepTrackDuration } from "./CompletedStep";
import FuneralHomeLogo from "../../../common/FuneralHomeLogo";
import classNames from "classnames";
import ComponentAnswers from "./ComponentAnswers";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    lineBreak: {
        border: "none",
        borderTop: "1px solid #000000",
    },
    hidden: {
        height: 0,
        opacity: 0,
        width: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'none',
    },
    funeralHomeLogo: {
        margin: '0 !important',
        display: 'block !important',
    }, printHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }, headerText: {
        fontSize: '14px',
    }, headerSubText: {
        fontSize: '11px',
        marginBottom: '5px',
        textAlign: 'center',
    }, summaryBody: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    }, itemContainer: {
        display: 'inline-flex',
        flexDirection: 'row',
        marginBottom: '1px',
        fontSize: '10px',
        alignItems: 'flex-start',
    }, itemLabel: {
        margin: '0px 5px 0px 0px',
        fontSize: '10px',
    }, stepsContainer: {
        fontSize: '10px',
        display: 'block',
        pageBreakInside: 'avoid',
    }, stepHeader: {
        fontSize: '11px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
    }, answerItemContainer: {
        flexDirection: 'column',
    }, answerItem: {
        height: 'max-content',
        paddingLeft: 20,
        fontSize: '10px',
        fontStyle: 'normal',
        color: 'black !important',
        '& svg': {
            maxWidth: '15px',
        }
    }, boldText: {
        fontWeight: 'bold',
    }
}));

const TrackingStepHeader = (props: { label: string; value: string; optionalClass?: string }) => {
    const { label, value, optionalClass } = props;
    const classes = useStyles();
    return (
        <Grid item className={classNames(classes.itemContainer, optionalClass)}>
            <Typography className={classNames(classes.itemLabel, classes.boldText)}>
                {(typeof label === 'string') ?
                    `${label}:`
                    : ''}
            </Typography>
            {value}
        </Grid>
    );
};

const TrackingStepListItem = (props: { label: string; value: string; optionalClass?: string }) => {
    const { label, value, optionalClass } = props;
    const classes = useStyles();
    return (
        <Grid className={classNames(classes.itemContainer, optionalClass)}>
            <p className={classNames(classes.itemLabel, classes.boldText)}>
                {label}:
            </p>
            {value}
        </Grid>
    );
};

interface QRStepperPrintViewProps {
    gatherCase: GatherCaseUX;
    trackingItems: TrackingItem[];
    printElementId: string;
    userData: UserProfile;
    printedTimeStamp: Date | null;
    isHidden: boolean;
}

const QRStepperPrintView = (props: QRStepperPrintViewProps) => {
    const {
        isHidden,
        gatherCase,
        trackingItems,
        printElementId,
        printedTimeStamp,
        userData,
    } = props;

    const classes = useStyles();

    const currentLocation = trackingItems.find((item) => item.inLocation);
    const weight = gatherCase.death_certificate?.about.weight;
    const pickUpAddressRaw = gatherCase.death_certificate?.about.pickUpAddress || null;
    const dropOffAddressRaw = gatherCase.death_certificate?.about.dropOffAddress || null;
    const pickUpAddress = getAddressFromLongAddress(pickUpAddressRaw);
    const dropOffAddress = getAddressFromLongAddress(dropOffAddressRaw);

    const getDurationDisplay = (step: TrackingItem) => {
        const displayString = getCompactKeepTrackDuration({
            durations: step.durationsInLocation,
            withEllipses: step.inLocation,
            noJustNow: true
        });
        return displayString || '<1m';
    };

    const dateTimeDisplayOptions: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short',
    };

    const formatDateTime = (date: Date | undefined): string => {
        if (!date) {
            return '';
        }
        const formatter = new Intl.DateTimeFormat('en-us', dateTimeDisplayOptions);
        const formattedDate = formatter.format(date);
        const lastCommaIndex = formattedDate.lastIndexOf(',');
        if (lastCommaIndex === -1) {
            return formattedDate;
        }
        return formattedDate.slice(0, lastCommaIndex) + ' at ' + formattedDate.slice(lastCommaIndex + 1);
    };

    const dob = formatDateString(gatherCase.dob_date ?? '', 'dddd, MMM D, YYYY');
    const dod = formatDateString(gatherCase.dod_start_date ?? '', 'dddd, MMM D, YYYY');


    return (
        <Grid
            container
            className={classNames((isHidden && classes.hidden))}
            id={printElementId}
        >
            <Grid container>
                <Grid
                    item
                    className={classes.printHeader}
                >
                    <FuneralHomeLogo
                        logoSize={'small'}
                        className={classes.funeralHomeLogo}
                        style={{
                            width: 150,
                            maxWidth: 360,
                            maxHeight: 80,
                            objectFit: 'contain',
                            objectPosition: 'center',
                        }}
                        ignoreTranformationsHeight={isHidden}
                    />

                    <Typography className={classNames(classes.headerText, classes.boldText)}>
                        {gatherCase.display_full_name} Chain of Custody
                    </Typography>
                    <Typography className={classes.headerSubText}>
                        {`Generated using KeepTrack™ by Gather on 
                        ${formatDateTime(printedTimeStamp ?? new Date())} by ${getFullNameFromUser(userData)}.`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.root}>
                <Grid className={classes.summaryBody}>
                    <TrackingStepHeader
                        label={'Decedent'}
                        value={gatherCase.display_full_name}
                        optionalClass={classes.boldText}
                    />
                    <TrackingStepHeader label={'Workflow'} value={gatherCase.workflow_name || ''} />
                    <TrackingStepHeader label={'Date of Birth'} value={dob} />
                    <TrackingStepHeader label={'Date of Death'} value={dod} />
                    <TrackingStepHeader
                        label={'Unique Identifier (KeepTrack™ Number)'}
                        value={gatherCase.keeptrack_key || 'N/A'}
                    />
                    <TrackingStepHeader label={'Business Establishment'} value={gatherCase.funeral_home.name} />
                    <TrackingStepHeader
                        label={'Current Location'}
                        value={
                            (currentLocation?.label && typeof currentLocation?.label === 'string')
                                ? currentLocation?.label : ''}
                    />
                    <TrackingStepHeader label={'Weight'} value={weight || ''} />
                    <TrackingStepHeader label={'Pick-Up Address'} value={pickUpAddress || ''} />
                    <TrackingStepHeader label={'Drop-Off Address'} value={dropOffAddress || ''} />
                </Grid>
                <Grid container>
                    {trackingItems.map((trackingItem, index) => {
                        const stepAnswers: TaskComponentUX[] | undefined = trackingItem.answers ?? undefined;
                        return (
                            <Grid
                                container
                                key={index}
                                className={classNames(classes.stepsContainer)}
                            >
                                <hr className={classes.lineBreak} />
                                <Grid item className={classes.stepHeader}>
                                    <Grid item className={classes.boldText}>
                                        {trackingItem.label}
                                    </Grid>
                                </Grid>
                                <Grid item display={'flex'} flexDirection={'column'}>
                                    <TrackingStepListItem
                                        label="Performed By"
                                        value={
                                            `${trackingItem.performedBy?.fname ?? ''}
                                             ${trackingItem.performedBy?.lname ?? ''}`.trim()}
                                    />
                                    <TrackingStepListItem
                                        label="Marked Complete By"
                                        value={
                                            `${trackingItem.finishedBy?.fname ?? ''}
                                             ${trackingItem.finishedBy?.lname ?? ''}`.trim()}
                                    />
                                    {index === 0 ?
                                        (<TrackingStepListItem
                                            label="ID"
                                            value={gatherCase.keeptrack_key ?? 'N/A'}
                                        />) :
                                        (trackingItem.secondaryText && trackingItem.secondaryText.length > 0 &&
                                            <TrackingStepListItem
                                                label="Note"
                                                value={trackingItem.secondaryText?.map((txt) => txt).join(' ')}
                                            />
                                        )
                                    }
                                    {stepAnswers &&
                                        <Grid
                                            container
                                            className={classNames(classes.itemContainer, classes.answerItemContainer)}
                                        >
                                            <Grid item>
                                                <ComponentAnswers
                                                    componentType={TaskComponentType.checklist_questions}
                                                    questions={stepAnswers}
                                                    additionalClass={classNames(classes.itemLabel, classes.answerItem)}
                                                    />
                                            </Grid>
                                            <Grid item>
                                                <ComponentAnswers
                                                    componentType={TaskComponentType.text_questions}
                                                    questions={stepAnswers}
                                                    additionalClass={classNames(classes.itemLabel, classes.answerItem)}
                                                    />
                                            </Grid>
                                            <Grid item>
                                                <ComponentAnswers
                                                    componentType={TaskComponentType.multiple_choice_question}
                                                    questions={stepAnswers}
                                                    additionalClass={classNames(classes.itemLabel, classes.answerItem)}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    <TrackingStepListItem
                                        label="Timestamp"
                                        value={formatDateTime(trackingItem.finishedTime?.toDate())}
                                    />
                                    {trackingItem.key !== 'initialize' &&
                                        <TrackingStepListItem
                                            label="Duration"
                                            value={getDurationDisplay(trackingItem)}
                                        />
                                    }
                                </Grid>
                                {trackingItem.signatureUrl &&
                                    <Grid item display={'flex'} justifyContent={'center'} width={'100%'}>
                                        <img
                                            src={trackingItem.signatureUrl}
                                            style={{ maxWidth: '150px' }}
                                            alt="signature" />
                                    </Grid>
                                }
                            </Grid >
                        );
                    })}
                </Grid>
            </Grid>
        </Grid >
    );
};


export default QRStepperPrintView;
