import {
    ActiveStreamReportRecord,
    AlbumDownloadState,
    AlbumEntryMappedType,
    AlbumTaskInfoMap,
    AlbumWithEntries,
    AuthorizeNetInfo,
    CaseGiftPhotoState,
    CaseNote,
    CaseTransactions,
    CaseType,
    CaseTypeSummary,
    ContractDisclaimerUX,
    ContractOptionsUX,
    DeathCertificateConfigSummary,
    DeathCertificateConfigUX,
    DeepLinkUX,
    DefaultServiceDetail,
    WorkflowSummary,
    WorkflowUX,
    DocPacketReportRecord,
    DocPacketUX,
    DocUXDetail,
    EntityReport,
    EntitySummary,
    FeeConfiguration,
    FlowerSalesOrderDraft,
    FlowerSalesProduct,
    FuneralHomeDemoSettings,
    FuneralHomeForGatherView,
    FuneralHomeNoteView,
    FuneralHomeUX,
    FuneralHomeUXPreview,
    GatherCaseDashboardAssignee,
    GatherCaseDashboardMonthlyRollup,
    GatherCaseDashboardRollup,
    GatherCaseDashboardUx,
    GatherCasePreview,
    GatherCasePublic,
    GatherCaseReportType,
    GatherCaseUX,
    GatherEvent,
    GatherEventPublic,
    GatherEventWithPlayback,
    GoogleEvent,
    LedgerExtractReport,
    LiveStreamBillingRecord,
    LocationUX,
    Obituary,
    PaymentReport,
    Photo,
    PhotoTransformationsType,
    PossibleRecipient,
    ProductCategory,
    ProductContractRecord,
    ProductContractUX,
    ProductContractUXSummary,
    ProductManufacturerRecord,
    ProductPackageUX,
    ProductSummary,
    ProductSupplierUX,
    ProductSupplierUXWithCategory,
    ProductTagRecord,
    ProductTaxRateUX,
    ProductUX,
    PublicTask,
    RolodexSearchResultCategoryFilter,
    RevenueReportUX,
    RolodexEntry,
    RolodexSearchResultDataTypes,
    MemoryUX,
    RolodexTableType,
    ServiceDetail,
    ServiceTemplateUX,
    StripeChargeDetails,
    TaskNote,
    CaseTaskUX,
    ThemeUX,
    UserInviteStatus,
    UserProfile,
    ModerationCategoryCounts,
    ModerationCountsForCase,
    GuestListUser,
    GuestListUserMap,
    FlowerSalesUX,
    DeathCertificateUpdateInfo,
    TaskPreview,
    FuneralHomePublic,
    CaseLabelUX,
    CaseFingerprintUX,
    CaseBelongingUX,
    TaskLocationUX,
    WhiteboardCaseUX,
    CaseIdPhotoUX,
    TaskComponentUX,
    WorkflowChangeSummary,
    GatherCaseEssentials,
    GatherCaseSummary,
    PaginatedResponse,
    CaseBelongingTableUX,
    WebsiteUX,
    WebsiteFuneralHomes,
    HasIdProperty,
    KeepTrackReportTaskItemUX,
    KeepTrackStaticListItems,
    InsurancePolicySummaryUX,
} from '../shared/types';
import { SnackbarOrigin } from '@mui/material/Snackbar';
import {
    PAYMENT_PROCESS_STATE_TYPE,
    PLAID_CHARGE_STATE,
    STRIPE_CHARGE_STATE_TYPE,
    STRIPE_PAYMENT_STATE_TYPE,
    STRIPE_TERMINAL,
    STRIPE_TERMINAL_STATE_TYPE,
} from '../actions/Finance.action';
import { DialogState } from './DialogState';
import { StreamDeviceUX } from '../shared/types/live_stream';
import { ConfettiState } from '../reducers/confetti.reducer';
import { ObituaryLinkUX } from '../shared/types/remember';
import * as ReactRedux from 'react-redux';
import { Theme } from '@mui/material/styles';
import { AppDispatch } from '../store';
import { AppRoute } from '../services';

export interface UsStateType {
    name: string;
    abbreviation: string;
}

export type PaginatedTableData<T> = {
    data: T[];
    hasMoreData: boolean;
    searchText: string;
    sortBy: keyof T;
    sortDirection: 'desc' | 'asc';
    isLoading: boolean;
};

// TODO: JJT (AKT) combine different user states into an enum
export interface UserSession {
    userData: UserProfile | null;
    isUpdatingUser: boolean;
    isLoginPending: boolean;
    isLoginSuccess: boolean;
    isAuthCheckPending: boolean;
    passwordResetPending: boolean;
    passwordResetMessage: string;
    loginError?: string;
    isUserPhotoSaving: boolean;
    hasFeaturesOverride: boolean;
    defaultCase: GatherCaseEssentials | null;
    defaultFuneralHomeKey: string | null;
}

export interface DeepLinkState {
    deepLink: DeepLinkUX | null;
    isLoading: boolean | null;
    isInvitationAccepted: boolean;
}

export interface FuneralHomeState {
    activeFuneralHome: FuneralHomeUX | null;
    publicFuneralHome: FuneralHomePublic | null;
    funeralHomeGatherView: PaginatedTableData<FuneralHomeForGatherView>;
    funeralHomePreviews: PaginatedTableData<FuneralHomeUXPreview>;
    failedRouteKey: string | null;
    isLoading: boolean;
    demoSettings: FuneralHomeDemoSettings | null;
    isSaving: boolean;
    isPhotoSaving: boolean;
    isIconSaving: boolean;
    activeCaseLabels: CaseLabelUX[];
    caseBelongings: PaginatedTableData<CaseBelongingTableUX>;
}

type PhotoStatus = 'uploading' | 'uploaded' | 'failed';
export enum PhotoStatusEnum {
    uploading = 'uploading',
    uploaded = 'uploaded',
    failed = 'failed',
}

interface BasePhoto {
    gatherId: string;
    dataURI?: string;
}

export interface GatherPhoto extends BasePhoto {
    status: PhotoStatus;
    photo: AlbumEntryMappedType | Photo | null;
}

export const isGatherPhoto = (photoObject: object): photoObject is GatherPhoto => {
    return 'photo' in photoObject;
};

export interface AlbumPhoto extends BasePhoto {
    photo: AlbumEntryMappedType | null;
}

export function albumPhotoToGatherPhoto(photo: AlbumPhoto): GatherPhoto {
    return {
        status: PhotoStatusEnum.uploaded,
        ...photo,
    };
}

export interface AlbumPhotoMap {
    [albumId: number]: {
        photos: AlbumPhoto[];
        downloadState: AlbumDownloadState;
    };
}

export const RECENT_DASHBOARD_CASES = 'RECENT_DASHBOARD_CASES';
export type RECENT_DASHBOARD_CASES = typeof RECENT_DASHBOARD_CASES;

export interface RecentDashboardCases {
    type: RECENT_DASHBOARD_CASES;
}

export const RECENT_DASHBOARD_CASES_WITH_ASSIGNEE = 'RECENT_DASHBOARD_CASES_WITH_ASSIGNEE';
export type RECENT_DASHBOARD_CASES_WITH_ASSIGNEE = typeof RECENT_DASHBOARD_CASES_WITH_ASSIGNEE;

export interface RecentDashboardCasesWithAssignee {
    type: RECENT_DASHBOARD_CASES_WITH_ASSIGNEE;
    assignee: number;
}

export const MONTHLY_DASHBOARD_CASES = 'MONTHLY_DASHBOARD_CASES';
export type MONTHLY_DASHBOARD_CASES = typeof MONTHLY_DASHBOARD_CASES;

export interface MontlyDashboardCases {
    type: MONTHLY_DASHBOARD_CASES;
    month: number;
    monthAbbv: string;
    year: number;
}

export const MONTHLY_DASHBOARD_CASES_WITH_ASSIGNEE = 'MONTHLY_DASHBOARD_CASES_WITH_ASSIGNEE';
export type MONTHLY_DASHBOARD_CASES_WITH_ASSIGNEE = typeof MONTHLY_DASHBOARD_CASES_WITH_ASSIGNEE;

export interface MontlyDashboardCasesWithAssignee {
    type: MONTHLY_DASHBOARD_CASES_WITH_ASSIGNEE;
    month: number;
    monthAbbv: string;
    year: number;
    assigneeId: number;
}

export const ALL_CASES = 'ALL_CASES';
export const ALL_HELPERS = 'ALL_HELPERS';
export const ROLODEX = 'ROLODEX';

export type DashboardCasesType =
    | RecentDashboardCases
    | MontlyDashboardCases
    | MontlyDashboardCasesWithAssignee
    | RecentDashboardCasesWithAssignee;

export interface ArrangementConferenceDialogState {
    isDialogOpen: boolean;
    zIndex: number;
}

export enum ActiveCaseStatus {
    loading = 'loading',
    loaded = 'loaded',
    notFound = 'notFound',
    unauthorized = 'unauthorized',
}

export interface ActiveCaseState {
    caseName: string | null;
    status: ActiveCaseStatus | null;
    fhKeyWhereUserCanAccessCase: string | null;
}

// TODO: JJT (AKT) active case stuff should be moved into ActiveCaseState store for easy resetting
export interface GatherCasesState {
    // START DASHBOARD
    // TODO: JJT (AKT) the dashboard stuff should be in a separate store
    dashboardCasesLoading: boolean;
    dashboardCaseRollupLoading: boolean;
    dashboardCasesAssigneesLoading: boolean;
    dashboardCases: GatherCaseDashboardUx[] | null;
    dashboardPolicies: InsurancePolicySummaryUX[] | null;
    dashboardCasesPage: number;
    dashboardCasesType: DashboardCasesType;
    dashboardCaseRollup: GatherCaseDashboardRollup | null;
    dashboardCaseMonthlyRollup: GatherCaseDashboardMonthlyRollup[] | null;
    dashboardCaseTypeTotals: CaseTypeSummary;
    dashboardCasesAssignees: GatherCaseDashboardAssignee[] | null;
    dashboardCasesSelectedAssignee: number | null;
    dashboardAssigneeCasesTotal: number | null;
    dashboardTotalCaseCount: number | null;
    // END DASHBOARD
    casePhotos: GatherPhoto[];
    caseGiftPhotos: CaseGiftPhotoState[];
    caseRememberPreviewAlbum: AlbumWithEntries | null;
    caseObituary: Obituary | null;
    caseObituaryLinks: ObituaryLinkUX[];
    caseServiceDetails: ServiceDetail[];
    caseIdPhotos: CaseIdPhotoUX[];
    caseIdPhotosLoading: boolean;
    obituaryError: string | null;
    isCasePhotosLoading: boolean;
    isCaseGiftPhotosLoading: boolean;
    selectedCase: GatherCaseUX | null;
    isSaving: boolean;
    isCaseProfilePhotoSaving: boolean;
    arrangementConferenceDialog: ArrangementConferenceDialogState;
    selectedCaseTypes: CaseType[];
    isOneOffProductSelectionDialogOpen: boolean;
    publicCase: GatherCasePublic | null;
    helpers: EntitySummary[];
    isHelpersLoading: boolean;
    guestList: GuestListUser[];
    guestListMap: GuestListUserMap;
    deathCertificateOutdated: DeathCertificateUpdateInfo | null;
    activeCaseFingerprints: {
        loading: boolean;
        prints: CaseFingerprintUX[];
    };
    belongings: CaseBelongingUX[] | null;
    workflowChangeHistory: WorkflowChangeSummary[];
    isLoadingTrackingPage: boolean;
}

export interface TasksState {
    publicTasks: PublicTask[];
    checklistTasks: CaseTaskUX[];
    trackingSteps: CaseTaskUX[];
    taskPreviews: TaskPreview[];
    taskPreviewsLoading: boolean;
    isSaving: boolean;
    saveTaskOpen: boolean;
    isLoading: boolean;
    sendingTaskReminder: boolean;
}

export interface ActiveTaskTemplate {
    taskTemplateId: number;
    components: TaskComponentUX[];
}

export interface TaskTemplateState {
    activeTaskTemplate: ActiveTaskTemplate | null;
}

export interface WorkflowState {
    workflows: WorkflowSummary[];
    activeWorkflow: WorkflowUX | null;
    isLoadingWorkflow: boolean;
    isLoadingWorkflowSummaries: boolean;
}

export interface DeathCertificateConfigState {
    deathCertificateConfigs: DeathCertificateConfigSummary[];
    activeDeathCertificateConfig: DeathCertificateConfigUX | null;
    isSavingConfig: boolean;
    isDeletingConfig: boolean;
    isLoadingConfigs: boolean;
    isLoadingConfig: boolean;
}

export interface ModerationState {
    allCasesPendingCounts: ModerationCategoryCounts | null;
    caseCounts: ModerationCountsForCase[];
}
export interface InvitationState {
    status: UserInviteStatus | null;
    similarUsers: UserProfile[];
}

export interface TeamState {
    isInvitationPending: boolean;
    isInvitationSuccess: boolean;
    isTeamLoading: boolean;
    team: EntitySummary[];
}

export interface LocationState {
    locations: LocationUX[];
    isSaving: boolean;
    isLoading: boolean;
}

export interface GatherEventState {
    gatherEvents: GatherEvent[];
    googleEvents: GoogleEvent[];
    isSaving: boolean;
    isLoading: boolean;
    activeEventId: number | null;
}

export interface FuneralHomeTheme {
    primaryColor: string;
}

export interface WhiteLabel {
    logo: string;
    logo_transformations: PhotoTransformationsType | null;
    theme_logo: string;
    theme_logo_transformations: PhotoTransformationsType | null;
    icon: string;
    icon_transformations: PhotoTransformationsType | null;
    muiTheme: Theme;
    funeralHomeTheme: FuneralHomeTheme | null;
    rememberTheme: ThemeUX | null;
}

export interface ServiceDetailState {
    defaultTemplateDetails: DefaultServiceDetail[];
    casketBearerTemplateDetails: DefaultServiceDetail[];
    templates: ServiceTemplateUX[];
    isLoading: boolean;
    activeTemplateKey: string | null;
    activeTemplateFuneralHomes: FuneralHomeForGatherView[];
    activeTabIndex: number | null;
}

export type SnackBarVariant = 'error' | 'warning' | 'info' | 'success';

export interface AppSnackbarState {
    variant: SnackBarVariant;
    message: string | JSX.Element;
    isOpen: boolean;
    secondaryMessage?: string | JSX.Element;
    snackbarOrigin?: SnackbarOrigin;
    autoHideDuration?: number;
}

export interface AppState {
    hasInternet: boolean;
}
export interface FinanceState {
    paymentProcessState: PAYMENT_PROCESS_STATE_TYPE;
    terminalState: STRIPE_TERMINAL_STATE_TYPE;
    terminalPaymentState: STRIPE_PAYMENT_STATE_TYPE;
    terminalDiscoverResults: STRIPE_TERMINAL.DiscoverResult;
    chargeDetails: StripeChargeDetails;
    selectedReader: STRIPE_TERMINAL.Reader | null;
    onlineChargeState: STRIPE_CHARGE_STATE_TYPE;
    plaidChargeState: PLAID_CHARGE_STATE;
    plaidLinkToken: string | null;
    extractReport: LedgerExtractReport;
    transactions: CaseTransactions;
    paymentReport: PaymentReport | null;
    revenueReport: RevenueReportUX[] | null;
    errorMessage?: string;
    feeConfiguration: FeeConfiguration | null;
}

interface GlobalProductState extends PaginatedTableData<ProductSummary> {
    category: ProductCategory;
}

export interface ProductState {
    globalProducts: GlobalProductState;
    funeralHomeProducts: ProductUX[];
    funeralHomeProductSummaries: ProductSummary[];
    funeralHomeCategories: ProductCategory[];
    manufacturers: ProductManufacturerRecord[];
    tags: ProductTagRecord[];
    taxRates: ProductTaxRateUX[];
    packages: ProductPackageUX[];
    activeContract: ProductContractUX | null;
    activeContractSummary: ProductContractUXSummary | null;
    isContractLoading: boolean;
    isFHProductsLoading: boolean;
    contracts: ProductContractRecord[];
    contractOptions: ContractOptionsUX;
    contractDisclaimers: ContractDisclaimerUX[];
}

export interface ProductSupplierState {
    globalSuppliers: ProductSupplierUX[];
    funeralHomeSuppliers: ProductSupplierUXWithCategory[];
}

export type FuneralHomeDocState = {
    docs: DocUXDetail[];
    isLoading: boolean;
};

export type CaseDocState = {
    docs: DocUXDetail[];
    isLoading: boolean;
};

export interface DocPacketState {
    docPackets: DocPacketUX[];
    isLoading: boolean;
    isSaving: boolean;
}

export interface DocPacketTableReduxState extends PaginatedTableData<DocPacketReportRecord> {
    totalDocPacketCount: number;
}

export interface EntityTableReduxState extends PaginatedTableData<EntityReport> {
    totalPersonCount: number;
}

export interface CaseTableReduxState extends PaginatedTableData<GatherCaseReportType> {
    totalCaseCount: number;
}

export interface RolodexTableReduxState extends PaginatedTableData<RolodexTableType> {
    totalEntryCount: number;
}

export enum CASE_PREVIEW_STATES {
    'CASE_PREVIEW_LOADING' = 'CASE_PREVIEW_LOADING',
    'CASE_PREVIEW_LOADED' = 'CASE_PREVIEW_LOADED',
    'CASE_PREVIEW_LOAD_FAILED' = 'CASE_PREVIEW_LOAD_FAILED',
}
export interface CasePreviewReduxState extends PaginatedTableData<GatherCasePreview> { }

export interface AccessRestrictedState {
    isDialogOpen: boolean;
    zIndex: number;
}

export interface LiveStreamState {
    streamableEvents: GatherEventWithPlayback[];
    isEventsLoading: boolean;
    streamDevices: StreamDeviceUX[];
    availableStreamDevices: StreamDeviceUX[];
    isDevicesLoading: boolean;
    activeStreamReport: ActiveStreamReportRecord[];
    liveStreamBillingReport: LiveStreamBillingRecord[];
}

export interface FlowerSalesState {
    isProductsLoading: boolean;
    isOrderPageLoading: boolean;
    isOrderDraftLoading: boolean;
    isDeliveryDatesLoading: boolean;
    isPlacingOrder: boolean;
    isDeliveryZipValid: boolean;
    products: FlowerSalesProduct[];
    informant: PossibleRecipient | null;
    events: GatherEventPublic[];
    deliveryDates: string[];
    totalProducts: number;
    authorizeNetInfo: AuthorizeNetInfo | null;
    orderDraft: FlowerSalesOrderDraft | null;
    productToOrder: FlowerSalesProduct | null;
    ordersForCase: FlowerSalesUX[];
}

interface PaginatedDataForNoteTable extends PaginatedTableData<FuneralHomeNoteView> {
    showHelperNotesOnly: boolean;
}

export interface NoteState {
    isLoading: boolean;
    activeCaseUuid: string | null;
    caseNotes: CaseNote[];
    taskNotes: TaskNote[];
    funeralHomeView: PaginatedDataForNoteTable;
}

export interface PhotoSwipeState {
    isOpen: boolean;
    urls: Array<string>;
    options?: PhotoSwipe.Options;
}

export interface AlbumState {
    isNetworkBusy: boolean;
    albums: AlbumPhotoMap;
    albumTaskInfoMap: AlbumTaskInfoMap;
}

export interface GatherCaseForPhotoDelete {
    id: number;
    uuid: string;
    name: string;
    funeral_home: { id: number };
}

export interface DeleteGlobalState {
    isDialogOpen: boolean;
    zIndex: number;
    gatherCase?: GatherCaseForPhotoDelete;
    albumEntryId?: number;
    references?: string[];
}

export interface RolodexState {
    activeEntry: RolodexEntry | null;
    createEditDialogOpen: boolean;
    listDialogOpen: boolean;
    searchResults: RolodexSearchResultDataTypes[] | null;
    searchResultOrgCategories: RolodexSearchResultCategoryFilter[];
    zIndex: number;
    reportData: RolodexTableReduxState;
    searchIsLoading: boolean;
    inputValue: string | null;
}

export interface MemoryState {
    memoryList: MemoryUX[];
}

export interface TaskLocationState {
    isLoading: boolean;
    taskLocations: TaskLocationUX[];
}

export interface WhiteboardState {
    isLoading: boolean;
    whiteboardCases: WhiteboardCaseUX[];
}

export interface WebsiteState {
    isLoading: boolean;
    websites: WebsiteUX[];
    funeralHomes: WebsiteFuneralHomes[];
}

export interface AssignKeeptrackState {
    cases: PaginatedResponse<GatherCaseSummary>;
    funeralHomes: PaginatedResponse<FuneralHomeUXPreview>;
    isLoading: boolean;
}

export interface KeeptrackReportState {
    isLoading: boolean;
    reportData: KeepTrackReportTaskItemUX[];
    staticListItems: KeepTrackStaticListItems | null;
}

export interface CaseInsuranceState { 
    isLoading: boolean;
}

export interface StoreState {
    userSession: UserSession;
    funeralHomeState: FuneralHomeState;
    invitationState: InvitationState;
    teamState: TeamState;
    casesState: GatherCasesState;
    activeCaseState: ActiveCaseState;
    deepLinkState: DeepLinkState;
    whiteLabel: WhiteLabel;
    appSnackbarState: AppSnackbarState;
    tasksState: TasksState;
    taskTemplateState: TaskTemplateState;
    workflowState: WorkflowState;
    locationState: LocationState;
    eventState: GatherEventState;
    serviceDetailState: ServiceDetailState;
    appState: AppState;
    financeState: FinanceState;
    productState: ProductState;
    productSupplierState: ProductSupplierState;
    funeralHomeDocState: FuneralHomeDocState;
    caseDocState: CaseDocState;
    docPacketState: DocPacketState;
    accessRestricted: AccessRestrictedState;
    dialogState: DialogState;
    entityTableState: EntityTableReduxState;
    caseTableState: CaseTableReduxState;
    casePreviewState: CasePreviewReduxState;
    liveStreamState: LiveStreamState;
    flowerSalesState: FlowerSalesState;
    noteState: NoteState;
    docPacketTableState: DocPacketTableReduxState;
    photoSwipeState: PhotoSwipeState;
    confettiState: ConfettiState;
    albumState: AlbumState;
    deleteGlobalState: DeleteGlobalState;
    rolodexState: RolodexState;
    memoryState: MemoryState;
    deathCertificateConfigState: DeathCertificateConfigState;
    moderationState: ModerationState;
    taskLocationState: TaskLocationState;
    whiteboardState: WhiteboardState;
    assignKeeptrack: AssignKeeptrackState;
    keeptrackReport: KeeptrackReportState;
    websites: WebsiteState;
    caseInsuranceState: CaseInsuranceState;
}

export interface TableColumnType<T extends HasIdProperty> {
    id: keyof T;
    label: string;
    isString: boolean;
    customCellRenderer?: (ele: T) => JSX.Element;
    api?: string;
    enableCallBackAction?: boolean;
    isButton?: boolean;
    isCheckbox?: boolean;
    isImage?: boolean;
    headerTooltip?: string;
    rowTooltipProperty?: string;
    key?: string;
    color?: 'primary';
    cellClass?: string;
    disableSorting?: boolean;
    userAvatarProperty?: string;
    showUserAvatarOverlap?: string;
    userAvatarOverlapClass?: string;
    userAvatarCallBack?: boolean;
    tableRowClass?: string;
    dbcolumn?: keyof T;
    generateLink?: (ele: T) => AppRoute;
    renderValue?: (ele: T) => string | null;
}

export interface TableBodyType<T extends HasIdProperty> {
    data: Array<T>;
    propertyList: TableColumnType<T>[];
}

export enum AllowedAcceptTypes {
    application_json = 'application/json',
    text_csv = 'text/csv',
    application_pdf = 'application/pdf',
}

export const useGSelector = <Selected = unknown>(
    selector: (state: StoreState) => Selected,
): Selected => ReactRedux.useSelector(selector, ReactRedux.shallowEqual);

export const useGDispatch = () => ReactRedux.useDispatch<AppDispatch>();
